import { render, staticRenderFns } from "./BlockType4List.vue?vue&type=template&id=6f704d18&scoped=true"
import script from "./BlockType4List.vue?vue&type=script&lang=ts"
export * from "./BlockType4List.vue?vue&type=script&lang=ts"
import style0 from "./BlockType4List.vue?vue&type=style&index=0&id=6f704d18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f704d18",
  null
  
)

export default component.exports