
import Vue from 'vue';
import ThumbWrapper from '@gallery/components/thumb/ThumbWrapper.vue';
import { ImageItem } from '@gallery/types/Gallery';
import BigPictureWrapper from '@gallery/components/BigPictureWrapper.vue';
import Lightbox from '@gallery/components/lightbox/Lightbox.vue';

interface Props {
  pictures: ImageItem[];
  galleryId: string;
  settings: Record<string, any>;
}

interface Methods {
  setActivePictureIndex: (index: number) => void;
  toggleLightbox: (firstPhotoIndex?: number) => void;
}

interface Data {
  activePictureIndex: number;
  showLightbox: boolean;
  initialIndex: number;
}

interface Computed {
  activePicture: ImageItem;
  activePictureCredits: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ThumbWrapper,
    BigPictureWrapper,
    Lightbox,
  },
  data() {
    return {
      activePictureIndex: 0,
      showLightbox: false,
      initialIndex: 0,
    };
  },
  props: {
    pictures: {
      type: Array,
      required: true,
    },
    galleryId: {
      type: String,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
  },
  computed: {
    activePicture() {
      return this.pictures[this.activePictureIndex];
    },
    activePictureCredits() {
      return this.activePicture?.metadata.credit ? this.activePicture?.metadata.credit : null;
    },
  },
  methods: {
    toggleLightbox(firstPhotoIndex) {
      if (this.showLightbox) {
        this.showLightbox = false;
        this.initialIndex = 0;
        document.body.style.overflow = '';
        history.replaceState(null, '', ' ');
      } else {
        this.initialIndex = firstPhotoIndex || 0;
        this.showLightbox = true;
        document.body.style.overflow = 'hidden';
      }
    },
    setActivePictureIndex(index) {
      if (index >= this.pictures.length) {
        this.activePictureIndex = 0;
      } else if (index < 0) {
        this.activePictureIndex = this.pictures.length - 1;
      } else {
        this.activePictureIndex = index;
      }
    },
  },
  mounted() {
    const hash = window.location.hash;

    if (!hash) {
      return;
    }

    const hashParams = new URLSearchParams(hash.substring(1));
    const galleryId = hashParams.get('gallery-id');
    const imageId = hashParams.get('image-id');

    if (galleryId === this.galleryId) {
      const pictureIndex = this.pictures.findIndex((picture) => picture.id === imageId);

      if (pictureIndex >= 0) {
        this.toggleLightbox(pictureIndex);
      }
    }
  },
});
