import { Plugin, Context } from '@nuxt/types';
import { CookieStats } from '@analytics/trackers';

function pageView(cookieStats: CookieStats, route: Context['route']) {
  const isArticle = route.name === 'article';

  // Send page view of all pages except article
  if (isArticle) {
    return false;
  }

  cookieStats.sendPageEvent();
}

const CookieStatsPlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.cookieTracker?.site) {
    return;
  }

  const cookieStats = new CookieStats();
  await cookieStats.init(analytics.cookieTracker);

  // Initial page view after script loaded
  pageView(cookieStats, route);

  app?.router?.afterEach((to) => {
    pageView(cookieStats, to);
  });

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        const id = Number(store.state.article.activeArticle.id);
        cookieStats.sendPageEvent({ a: id });
      }
    },
    {
      immediate: true,
    }
  );
};

export default CookieStatsPlugin;
