
import Vue from 'vue';
import { HeadlineWithRelatedArticles, Headline, MetaImage } from '@headlines/types/headline';
import ContentImage from '@core/components/UI/ContentImage.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import getItemFromObject from 'lodash/get';
import { CustomCrop } from '@root/common/types/fragment';
import { stripTags } from '@root/common/filters/htmlFormater';

const Swiper = () => import(/* webpackChunkName: "Swiper" */ '@core/components/UI/swiper/Swiper.vue');

interface Srcset {
  w?: number;
  h?: number;
  r?: string;
}

interface Props {
  href: HeadlineWithRelatedArticles['href'];
  headline: Headline;
  fallbackAltText: string;
  // Support Content API v2 style response
  pictures: Record<string, string>[];
  pictureSrcset: { xs: Srcset; sm: Srcset; md: Srcset; lg: Srcset; xl: Srcset } | null;
}

interface Methods {
  getItemFromObject: typeof getItemFromObject;
  getCropperData(index?: number): Record<string, unknown> | null;
  headlineClick: (headline: HeadlineWithRelatedArticles) => void;
  getCustomCrop(ratio: string, index?: number): CustomCrop | undefined;
  getFocuspoint(index?: number): Record<string, unknown>;
  getTitle: (headline: HeadlineWithRelatedArticles, strip: boolean) => string;
}

interface Computed {
  isVideoHeadline: boolean;
  secondaryMetaImages: MetaImage[] | null;
}

interface Data {
  swiperOptions: Record<string, any>;
}

const sizeList: ['xl', 'lg', 'md', 'sm', 'xs'] = ['xl', 'lg', 'md', 'sm', 'xs'];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LinkHandler,
    ContentImage,
    Swiper,
  },
  props: {
    href: {
      type: String,
      required: false,
    },
    headline: {
      type: Object,
      required: false,
    },
    pictures: {
      type: Array,
      required: false,
    },
    pictureSrcset: {
      type: Object,
      required: false,
    },
    fallbackAltText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000,
        },
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          enabled: false,
        },
        pagination: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
      },
    };
  },
  computed: {
    isVideoHeadline() {
      return this.headline.primaryCategory?.channel.url === 'www.delfi.lt/video';
    },
    secondaryMetaImages() {
      if (this.headline.metaImage && this.headline.secondaryMetaImages) {
        return [this.headline.metaImage, ...this.headline.secondaryMetaImages];
      }

      return null;
    },
  },
  methods: {
    getItemFromObject,
    getCustomCrop(ratio, index) {
      const image = index && this.secondaryMetaImages ? this.secondaryMetaImages[index] : this.headline.metaImage;
      if (!image) {
        return undefined;
      }

      const customCrops = image!.attributes?.cropperData?.customCrops || [];

      return customCrops.find((crop) => crop.ratio === ratio);
    },
    getFocuspoint(index) {
      const cropperProps: Record<string, unknown> = {};

      const image = index && this.secondaryMetaImages ? this.secondaryMetaImages[index] : this.headline.metaImage;

      const focuspoint = image!.attributes?.cropperData?.focuspoint;
      if (focuspoint?.x !== undefined && focuspoint?.x !== null) {
        cropperProps.fx = focuspoint.x;
      }

      if (focuspoint?.y !== undefined && focuspoint?.y !== null) {
        cropperProps.fy = focuspoint.y;
      }

      // Override fx, fy values if focuspoint values are not provided
      if (!focuspoint && this.headline.id < 120000000) {
        cropperProps.fx = 0.5;
        cropperProps.fy = 0.25;
      }

      return cropperProps;
    },
    getCropperData(index) {
      if (!this.headline) {
        return null;
      }

      const cropperData: Record<string, unknown> = {};

      for (const size of sizeList) {
        if (this.pictureSrcset?.[size]) {
          const sizeSrcset = this.pictureSrcset[size];

          const customCrop = this.getCustomCrop(sizeSrcset.r || '16:9', index);

          if (customCrop) {
            cropperData[size] = { ...sizeSrcset, customCrop };
          } else {
            cropperData[size] = sizeSrcset;
          }
        }
      }

      const image = index && this.secondaryMetaImages ? this.secondaryMetaImages[index] : this.headline.metaImage;
      if (!image) {
        return null;
      }

      return {
        ...cropperData,
        ...(image!.attributes?.cropperData || {}),
        base: this.getFocuspoint(),
      };
    },
    headlineClick(headline: HeadlineWithRelatedArticles) {
      this.$instanceConnector('headlines') && this.$instanceConnector('headlines').headlineClick({ headline });
    },
    getTitle(headline, strip = false) {
      const title = headline.content.title?.html
        ? headline.content.title?.html.replace(/(<\/?h1>)/gi, '')
        : headline.content.title?.text
        ? headline.content.title?.text
        : '';

      return strip && typeof stripTags === 'function' ? stripTags(title) : title;
    },
  },
});
