
import Vue from 'vue';
import ContentFragment from '@core/components/ContentFragment.vue';
import { ContentFragment as IContentFragment } from '@root/common/types/fragment';

interface Props {
  content: IContentFragment;
}
export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    ContentFragment,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
});
