
import { HeadlineTitle, HeadlineImage, HeadlineLabels, HeadlineIcons, HeadlineLead, HeadlineAuthor } from '../../fragments';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType18';

const defaultSettings = {
  icons: true,
  channel: true,
  category: true,
  lead: true,
  pictureShadowColor: '',
  author: false,
  adsInject: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineLead,
    HeadlineAuthor,
    AdFormBanner,
  },
});
