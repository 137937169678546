
import BlockType2Grid from '../1-10/block-type-2/BlockType2Grid.vue';
import BlockType3Grid from '../1-10/block-type-3/BlockType3Grid.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/111-120/blockType113';
import sampleSize from 'lodash/sampleSize';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: false,
  category: false,
  lead: false,
  publishDate: false,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    BlockType2Grid,
    BlockType3Grid,
  },
  data() {
    return {
      blockData: {
        items: [] as HeadlineWithRelatedArticles[],
      },
      count: 3,
      isPortal: false,
      mounted: false,
    };
  },
  mounted() {
    this.count = this.isMobileView ? 2 : 3;
    this.isPortal = typeof window !== 'undefined' && typeof window.$nuxt !== 'undefined';

    const pinnedArticles = this.data.items.filter((item) => item.pin).slice(0, this.count);
    const defaultArticles = this.data.items.filter((item) => !item.pin);
    let items = [...pinnedArticles];

    if (pinnedArticles.length < this.count) {
      const additionalItems = sampleSize(defaultArticles, this.count - pinnedArticles.length);
      items = [...items, ...additionalItems];
    }

    this.blockData = { ...this.data, items };
  },
});
