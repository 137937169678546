// @ts-nocheck
export default function loadHotjarScript({ id, sv }) {
  ((h, o, t, j, a?: Element, r?: HTMLScriptElement) => {
    h.hj =
      h.hj ||
      ((...args: any) => {
        (h.hj.q = h.hj.q || []).push(args);
      });
    h._hjSettings = { hjid: id, hjsv: sv };
    let el = a;
    let s = r;
    el = o.getElementsByTagName('head')[0];
    s = o.createElement('script');
    s.async = true;
    s.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    el.appendChild(s);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
