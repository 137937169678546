import Vue from 'vue';
import Router from 'vue-router';

import routerBase from './router.base';

Vue.use(Router);

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: routerBase,
    linkExactActiveClass: 'router-link--exact-active',
    linkActiveClass: 'router-link--active',
    scrollBehavior(to, from, savedPosition) {
      // Use native browser position helper
      if (from.name === 'frontpage' && to.name === 'frontpage' && !savedPosition && window.history.scrollRestoration) {
        window.history.scrollRestoration = 'auto';
        return;
      }

      if (to.name === 'article') {
        return;
      }

      if (to.name === 'frontpage' || to.name === 'channel') {
        return new Promise((resolve) => {
          // @ts-ignore
          (this.app as Vue).$store.watch(
            (state) => state.index.pageContentLoaded,
            () => {
              resolve(savedPosition);
            }
          );
        });
      } else {
        return savedPosition || { x: 0, y: 0 };
      }
    },
  });
}
