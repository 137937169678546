
import Vue from 'vue';
import BlockType19Grid from './BlockType19Grid.vue';
import BlockType19List from './BlockType19List.vue';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType19';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface Computed {
  type: string;
}

interface Props {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: BlockTypeSettings;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    BlockType19Grid,
    BlockType19List,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      let type = this.settings.type;
      type = type && (type === 'grid' || type === 'list') ? type : 'list';

      return type;
    },
  },
});
