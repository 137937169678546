
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';
import BaseButton from '@core/components/UI/BaseButton.vue';
import Dropdown from '@core/components/UI/dropdown/Dropdown.vue';
import { stripTags } from '@core/filters/htmlFormater';

import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';

interface Option {
  name: string;
  value: unknown;
}

interface Data {
  selectedName: string | null;
  closeDropdown: { value: boolean; timestamp: string } | null;
}

interface Props {
  value: unknown;
  options: Option[];
  placeholder: string;
  size: string;
}

interface Computed {
  classes: (string | { [x: string]: boolean })[];
}

interface Methods {
  getDefaultSelectName: (value: unknown) => void;
  select: (name: string, value: unknown) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseButton,
    Dropdown,
  },
  filters: {
    stripTags,
  },
  props: {
    /**
     * Selected value. Chooses value from options
     */
    value: {
      type: undefined,
      default: null,
      required: true,
    },
    /**
     * @values [{ name: 'name', value: 'value' }]
     */
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * @values 'auto', 'small', 'default'
     */
    size: {
      validator(value: string) {
        return oneOf(value, ['auto', 'small', 'default']);
      },
      type: String,
      default: () => 'default',
      required: false,
    },
  },
  data() {
    return {
      selectedName: null,
      closeDropdown: null,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.getDefaultSelectName(value);
      },
    },
  },
  computed: {
    classes() {
      return [
        'form-select',
        {
          [`form-select--size-${this.size}`]: this.size !== 'auto',
        },
      ];
    },
  },
  methods: {
    select(name, value) {
      this.selectedName = name;
      this.closeDropdown = {
        value: true,
        timestamp: String(new Date().getTime()),
      };
      this.$emit('input', isPlainObject(value) ? { ...(value as Record<string, unknown>) } : value);
    },
    getDefaultSelectName(value) {
      let optionFound = false;
      for (const option of this.options) {
        if (isEqual(option.value, value)) {
          this.selectedName = option.name;
          optionFound = true;
          break;
        }
      }
      // Choose placeholder
      if (!optionFound) {
        this.selectedName = this.placeholder;
      }
    },
  },
});
