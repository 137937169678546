
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';
/**
 * @requires ./Tab.vue
 */

interface Tab extends Vue {
  active?: boolean;
  isActive?: boolean;
  name: string;
}

interface Data {
  tabs: Tab[];
  tracker: {
    style: {
      transform: string | null;
      width: string | null;
    };
    isActive: boolean;
  };
}

interface Props {
  activeBorderPosition: string;
}

interface Methods {
  changeTab: (selectedTab: Tab) => void;
  classes: (selectedTab: Tab) => void;
  setTrackerStyles: () => void;
  toggleTrackerActive: () => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    /**
     * Active tab header item border position
     * @values bottom, top
     */
    activeBorderPosition: {
      type: String,
      default: 'bottom',
      validator(value: string) {
        return oneOf(value, ['bottom', 'top']);
      },
    },
  },
  data() {
    return {
      tabs: [],
      tracker: {
        style: {
          transform: null,
          width: null,
        },
        isActive: false,
      },
    };
  },
  created() {
    this.tabs = this.$children as Tab[];
  },
  mounted() {
    if (!this.tabs.some((tab) => tab.active)) {
      this.changeTab(this.tabs[0]);
    }
    this.setTrackerStyles();
  },
  methods: {
    setTrackerStyles() {
      this.$nextTick(() => {
        const $tab = (this.$refs.activeTab as HTMLElement[])[0];
        this.tracker.style = {
          transform: `translateX(${$tab.offsetLeft}px)`,
          width: `${$tab.offsetWidth}px`,
        };
      });
    },
    toggleTrackerActive() {
      this.tracker.isActive = true;
      const $tabTracker = this.$refs.tabTracker as HTMLElement;
      const transitionEnd = () => {
        this.tracker.isActive = false;
        $tabTracker.removeEventListener('transitionend', transitionEnd);
      };
      $tabTracker.addEventListener('transitionend', transitionEnd, false);
    },
    changeTab(selectedTab) {
      if (this.tracker.isActive) {
        return;
      }

      this.toggleTrackerActive();
      this.setTrackerStyles();
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
      });
    },
    classes(tab) {
      return tab.isActive ? [{ 'tabs__header-item--active': tab.isActive }, `tabs__header-item--border-${this.activeBorderPosition}`] : '';
    },
  },
});
