import BlockType16Component from '@headlines/components/block/11-20/BlockType16.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType16: AdminBlockType = {
  component: BlockType16Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: true,
    titleComment: true,
    channel: true,
    category: true,
    relatedTitles: true,
    lead: false,
  }),
  articlesCount: 1,
};

export default AdminBlockType16;
