
import Vue from 'vue';
import { Story, StoryItem } from '@headlines/types/Story';
import StoryMedia from './StoryMedia.vue';
import StoryHead from './StoryHead.vue';
import StoryPointers from './StoryPointers.vue';

interface Data {
  activeStoryIndex: number;
  activeItemIndex: number;
  isActiveStoryPaused: boolean;
  isActiveStoryLongPressed: boolean;
  slideHeight: number;
  slideWidth: number;
  activeItemState: 'loaded' | 'loading';
  adsSettings: {
    firstAd: number;
    anotherAds: number;
  };
  stories: Props['storiesData'];
  adTimer: ReturnType<typeof setTimeout> | undefined;
}

interface Methods {
  getStoryViewerClassModifier: (storyIndex: number) => Record<string, boolean>;
  createStoryTouchEvents: () => void;
  findPos: (obj: HTMLElement, offsetY?: number, offsetX?: number) => [number, number];
  translateSlider: (to: number, duration: number) => void;
  moveStoryItem: (direction: boolean) => void;
  moveItem: (direction: boolean) => Promise<void>;
  closeModal: () => void;
  activeMediaLoaded: () => void;
  markSeen: (id: string) => void;
  getCTALink: (url: string) => string;
  clearAdTimer: () => void;
}

interface Computed {
  activeStoriesIndexes: number[];
  activeStory: Story;
  activeItem: StoryItem;
}

interface Props {
  storiesData: Story[];
  startIndex: number;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    storiesData: {
      type: Array as () => Props['storiesData'],
      required: true,
    },
    startIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  components: {
    StoryMedia,
    StoryHead,
    StoryPointers,
  },
  data() {
    return {
      activeItemState: 'loading',
      activeStoryIndex: 0,
      activeItemIndex: 0,
      isActiveStoryPaused: false,
      isActiveStoryLongPressed: false,
      slideHeight: 0,
      slideWidth: 0,
      adsSettings: {
        firstAd: 1,
        anotherAds: 3,
      },
      stories: [],
      adTimer: undefined,
    };
  },
  computed: {
    // When modal is active DOM should contain nearest stories
    // So, if first story is active, then DOM should have next story too
    // If some story in the middle is active, then DOM should have next and prev stories
    activeStoriesIndexes() {
      return [this.activeStoryIndex - 1, this.activeStoryIndex, this.activeStoryIndex + 1];
    },
    activeStory() {
      return this.stories[this.activeStoryIndex];
    },
    activeItem() {
      return this.activeStory?.items[this.activeItemIndex];
    },
  },
  watch: {
    activeItemIndex() {
      this.activeItemState = 'loading';
    },
    activeItem(newItem) {
      if (newItem?.media.isAd) {
        this.adTimer = setTimeout(() => {
          this.clearAdTimer();
        }, 1500);
      }
    },
  },
  methods: {
    activeMediaLoaded() {
      this.activeItemState = 'loaded';
    },
    clearAdTimer() {
      if (this.adTimer) {
        clearTimeout(this.adTimer);
      }

      this.adTimer = undefined;
    },
    async moveItem(direction) {
      const activeStoryItemsLength = this.activeStory.items.length;

      if (direction) {
        if (this.activeItemIndex >= activeStoryItemsLength - 1) {
          this.moveStoryItem(true);
        } else {
          this.activeItemIndex++;
        }
      } else if (this.activeItemIndex <= 0) {
        this.moveStoryItem(false);
      } else {
        this.activeItemIndex--;
      }
    },
    markSeen(id) {
      this.$emit('mark-seen', id);
    },
    moveStoryItem(direction) {
      const itemUpdatedTime = this.activeStory.updatedTime;

      if (itemUpdatedTime) {
        this.markSeen(String(itemUpdatedTime));
      }

      let transform = 0;

      const storiesLength = this.stories.length;

      // if activeStory is last and direction forward
      // or if direction is backward and activeStory is first
      if ((direction && this.activeStoryIndex >= storiesLength - 1) || (!direction && this.activeStoryIndex <= 0)) {
        this.closeModal();
        return;
      }

      const transitionTime = 600;
      if (direction) {
        transform = this.slideWidth * -1;
      } else {
        transform = this.slideWidth;
      }

      this.translateSlider(transform, transitionTime);

      setTimeout(async () => {
        // set page data when transition complete
        this.activeItemIndex = 0;

        direction ? this.activeStoryIndex++ : this.activeStoryIndex--;

        this.translateSlider(0, 0);
      }, transitionTime + 50);
    },
    getStoryViewerClassModifier(storyIndex) {
      return {
        'story-viewer--previous': this.activeStoryIndex - 1 === storyIndex,
        'story-viewer--viewing': this.activeStoryIndex === storyIndex,
        'story-viewer--next': this.activeStoryIndex + 1 === storyIndex,
        'story-viewer--paused': this.isActiveStoryPaused && this.activeStoryIndex === storyIndex,
        'story-viewer--long-press': this.isActiveStoryLongPressed && this.activeStoryIndex === storyIndex,
        'story-viewer--stopped': this.activeStoryIndex !== storyIndex,
      };
    },
    findPos(obj, offsetY, offsetX) {
      let curleft = 0;
      let curtop = 0;

      if (obj) {
        if (obj.offsetParent) {
          do {
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
            // eslint-disable-next-line no-unmodified-loop-condition
          } while ((obj = obj.offsetParent as HTMLElement));
        }

        if (offsetY) {
          curtop = curtop - offsetY;
        }

        if (offsetX) {
          curleft = curleft - offsetX;
        }
      }

      return [curleft, curtop];
    },
    translateSlider(to, duration) {
      const direction = to > 0 ? 1 : -1;
      const modalContainer = this.$refs.modalContainer as HTMLElement;

      if (!modalContainer) {
        return;
      }

      const to3d = (Math.abs(to) / modalContainer.offsetWidth) * 90 * direction;
      const scaling = to3d === 0 ? 'scale(0.95)' : 'scale(0.930,0.930)';

      (this.$refs.modalContent as HTMLElement).style['transform'] = scaling;

      if (to3d < -90 || to3d > 90) {
        return false;
      }

      const transform = `rotateY(${to3d}deg)`;

      const modalSlider = this.$refs.modalSlider as HTMLElement;

      modalSlider.style['transitionDuration'] = `${duration}ms`;
      modalSlider.style['transform'] = transform;
    },
    createStoryTouchEvents() {
      const modalSlider = this.$refs.modalSlider as HTMLElement;

      let position: {
        x: number;
        y: number;
      } | null = null;
      let touchOffset: {
        x: number;
        y: number;
        time: number;
        valid: boolean;
      } | null = null;
      let isScrolling: boolean | undefined = undefined;
      let delta: {
        x: number;
        y: number;
      } | null = null;
      let timer: any = null;
      let nextTimer: any = undefined;

      const touchStart = (event: MouseEvent | TouchEvent) => {
        if (this.adTimer) {
          return;
        }

        const storyViewer = (this.$refs.activeStoryViewer as HTMLElement[])?.[0];

        if (!storyViewer) {
          this.closeModal();
        }

        // @ts-ignore-next-line
        const touches = event.touches ? event.touches[0] : event;
        const pos = this.findPos(storyViewer);

        position = {
          x: pos[0],
          y: pos[1],
        };

        const clientX = touches.clientX;
        const clientY = touches.clientY;

        touchOffset = {
          x: clientX,
          y: clientY,
          time: Date.now(),
          valid: true,
        };

        if (clientY < 80 || clientY > this.slideHeight - 80) {
          touchOffset.valid = false;
        } else {
          event.preventDefault();

          isScrolling = undefined;
          delta = null;

          modalSlider.addEventListener('mousemove', touchMove);
          modalSlider.addEventListener('mouseup', touchEnd);
          modalSlider.addEventListener('mouseleave', touchEnd);
          modalSlider.addEventListener('touchmove', touchMove);
          modalSlider.addEventListener('touchend', touchEnd);

          this.isActiveStoryPaused = true;

          timer = setTimeout(() => {
            this.isActiveStoryLongPressed = true;
          }, 200);

          nextTimer = setTimeout(() => {
            clearInterval(nextTimer);
            nextTimer = undefined;
          }, 250);
        }
      };

      const touchMove = (event: MouseEvent | TouchEvent) => {
        // @ts-ignore-next-line
        const touches = event.touches ? event.touches[0] : event;
        const clientX = touches.clientX;
        const clientY = touches.clientY;

        if (touchOffset && touchOffset.valid) {
          delta = {
            x: clientX - touchOffset.x,
            y: clientY - touchOffset.y,
          };

          if (typeof isScrolling === 'undefined') {
            isScrolling = Math.abs(delta.x) < Math.abs(delta.y);
          }

          if (!isScrolling && touchOffset) {
            event.preventDefault();

            this.translateSlider(position!.x + delta.x, 0);
          }
        }
      };

      const touchEnd = () => {
        const lastTouchOffset = touchOffset;
        const duration = touchOffset ? Date.now() - touchOffset.time : undefined;
        const isValid = (Number(duration) < 300 && Math.abs(delta?.x || 0) > 25) || Math.abs(delta?.x || 0) > this.slideWidth / 3;
        const direction = (delta?.x || 0) < 0;
        let isMoved = false;

        if (touchOffset && !touchOffset.valid) {
          return;
        }

        if (delta) {
          if (!isScrolling) {
            if (isValid) {
              this.moveStoryItem(direction);
              isMoved = true;
            } else {
              this.translateSlider(position!.x, 300);
            }
          }

          touchOffset = null;

          modalSlider.removeEventListener('mousemove', touchMove);
          modalSlider.removeEventListener('mouseup', touchEnd);
          modalSlider.removeEventListener('mouseleave', touchEnd);
          modalSlider.removeEventListener('touchmove', touchMove);
          modalSlider.removeEventListener('touchend', touchEnd);
        }

        if (timer) {
          clearInterval(timer);
        }

        this.isActiveStoryPaused = false;
        this.isActiveStoryLongPressed = false;

        if (nextTimer) {
          clearInterval(nextTimer);
          nextTimer = undefined;

          if (!isMoved) {
            if (lastTouchOffset!.x > window.screen.availWidth / 3) {
              this.moveItem(true);
            } else {
              this.moveItem(false);
            }
          }
        }
      };

      modalSlider.addEventListener('touchstart', touchStart);
      modalSlider.addEventListener('mousedown', touchStart);
    },
    closeModal() {
      this.$emit('close');
    },
    getCTALink(url) {
      let parsedUrl: URL | undefined;

      try {
        parsedUrl = new URL(url);
      } catch (e) {
        console.log('[Stories] Failed to parse link', e);
      }

      if (!parsedUrl) {
        return url;
      }

      const isEmail = parsedUrl.protocol === 'mailto:';

      if (!isEmail) {
        parsedUrl.searchParams.set('utm_source', 'Delfi');
        parsedUrl.searchParams.set('utm_medium', 'Story');
        parsedUrl.searchParams.set('utm_campaign', 'Story_promo');
      }

      return parsedUrl.href;
    },
  },
  async mounted() {
    let stories = null;

    if (!(this.storiesData && this.storiesData.length > 0)) {
      return;
    }

    const fakeAdStory = {
      photoSrc: '',
      name: '',
      items: [
        {
          topic: 'Reklaam',
          media: {
            isAd: true,
          },
          length: 5,
        },
      ],
    };

    stories = [...this.storiesData];

    let reducer = 0;

    for (let i = 1; i <= this.storiesData.length; i = i + 2) {
      stories.splice(i + reducer, 0, fakeAdStory);
      reducer++;
    }

    let startIndex = this.startIndex;

    for (let i = 1; i <= this.startIndex; i = i + 2) {
      startIndex += 1;
    }

    this.activeStoryIndex = startIndex;
    this.stories = stories;

    await this.$nextTick();

    const storyViewer = (this.$refs.activeStoryViewer as HTMLElement[])?.[0];

    if (!storyViewer) {
      this.closeModal();
    }

    this.slideWidth = storyViewer.offsetWidth;
    this.slideHeight = storyViewer.offsetHeight;

    this.createStoryTouchEvents();
  },
});
