
import Vue from 'vue';
import { getRootConfig } from '@core/utils/helpers';

interface Props {
  content: Record<string, any>;
}

interface Computed {
  headlineText: string;
  headlineClass: string;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineClass() {
      const headlineText = this.$props.content.html;
      const elemClass = headlineText
        .replace(/<[^>]*>?/gm, '')
        .replace(/\s/g, '')
        .toLowerCase();

      return elemClass;
    },
    headlineText() {
      let headlineText = this.$props.content.html;
      const config = getRootConfig('settings');

      if (config && config.lang === 'ru') {
        headlineText = headlineText.replace('Teiginiai', 'Тезисы');
        headlineText = headlineText.replace('Verdiktas', 'Вердикт');
        headlineText = headlineText.replace('Melo detektoriaus komentaras', 'Комментарий Детектор лжи');
      }

      return headlineText;
    },
  },
});
