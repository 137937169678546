
import Vue from 'vue';
import getItemFromObject from 'lodash/get';
import { gridConfig } from '@core/config/grid.config';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { getRootConfig } from '@core/utils/helpers';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import { stripTags } from '@root/common/filters/htmlFormater';

interface Props {
  headlineList: HeadlineWithRelatedArticles[];
  listType: string;
  showComments: boolean;
  showDate: boolean;
  mobileHeadlineListLimit: number;
}

interface Methods {
  commentCount: (Headline: HeadlineWithRelatedArticles) => string;
  dateFormat: (Headline: HeadlineWithRelatedArticles) => string;
  isCommentsDisabled: (headline: HeadlineWithRelatedArticles['comments']) => boolean;
  headlineClick: (headline: HeadlineWithRelatedArticles) => void;
  getTitle: (headline: HeadlineWithRelatedArticles, strip: boolean) => string;
}

interface Data {
  today: string;
  isMobileView: boolean | null;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    LinkHandler,
  },
  data() {
    return {
      today: new Date().toJSON().split('T')[0],
      isMobileView: null,
    };
  },
  props: {
    headlineList: {
      type: Array,
      required: true,
    },
    listType: {
      type: String,
      default: 'none',
      required: false,
    },
    showComments: {
      type: Boolean,
      default: true,
      required: false,
    },
    showDate: {
      type: Boolean,
      default: true,
      required: false,
    },
    mobileHeadlineListLimit: {
      type: Number,
      default: 5,
      required: false,
    },
  },
  methods: {
    commentCount(headline) {
      const commentsNoBrackets = getRootConfig('settings')?.commentsNoBrackets;
      const commentNumber = getItemFromObject(headline, 'statistics.comments');
      const commentCount = commentsNoBrackets ? commentNumber.toString() : `(${commentNumber})`;

      return commentCount;
    },
    dateFormat(headline) {
      const newDate = new Date(headline.publishAt).toJSON();
      const headlineTime = newDate?.split('T')[0];
      return this.today === headlineTime ? 'HH:mm' : 'DD.MM';
    },
    isCommentsDisabled(comments) {
      return comments.find((comment) => comment.key === 'disableComments')?.value ?? false;
    },
    headlineClick(headline: HeadlineWithRelatedArticles) {
      this.$instanceConnector('headlines') && this.$instanceConnector('headlines').headlineClick({ headline });
    },
    getTitle(headline, strip = false) {
      const title = headline.content.title?.html
        ? headline.content.title?.html.replace(/(<\/?h1>)/gi, '')
        : headline.content.title?.text
        ? headline.content.title?.text
        : '';

      return strip && typeof stripTags === 'function' ? stripTags(title) : title;
    },
  },
  mounted() {
    const screenWidth = window.screen.width;
    const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;
    this.isMobileView = screenWidth <= gridConfig.breakpoints.lg || isIpad;
  },
});
