import { uuid } from 'uuidv4';
import { AdsWindow } from '@ads/types/window';
import ibbmap from '@ads/config/ibbmap.config';
import ibbTag from '@ads/utils/ibb';
import customerReduceAdsMap from '@ads/config/customerReduceAdsMap.config';

declare const window: AdsWindow;

// Define desc
export function getAdId() {
  if (!window) {
    return null;
  }

  let adid = decodeURIComponent(window.document.cookie.replace(/(?:(?:^|.*;)\s*adxadid\s*=\s*([^;]*).*$)|^.*$/gi, '$1')) || null;
  if (adid) {
    return adid;
  } else {
    adid = uuid();

    const cookie = encodeURIComponent(adid);
    const subdomain = location.hostname.split('.').reverse()[1];
    const domain = location.hostname.split('.').reverse()[0];
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365).toUTCString();
    window.document.cookie = 'adxadid=' + cookie + ';domain=.' + subdomain + '.' + domain + ';path=/;expires=' + expires + ';secure;samesite=lax';
  }

  return adid;
}

/*
 * Initialize global ADX properties
 */
export function initAdx({ isAdFree, isCustomer }: { isAdFree: boolean; isCustomer: boolean }) {
  // Disable video ads for all subscribers
  window.delfi_video_noads = isCustomer || isAdFree;

  // Check if not already initialized
  if (window.adxDone) {
    return;
  }

  // Slow down TNS/CMeter scripts so they don't load up the page too much
  // Legacy?
  window.addonCMeter = {
    config: {
      ads: {
        scan_frequency: 3600,
      },
    },
  };

  // Load GeoJS script
  const geoScript = document.createElement('script');
  geoScript.async = true;
  geoScript.src = '//g.delfi.lt/g.js';
  window.document.head.appendChild(geoScript);

  // Live preview
  if (/livepreview/i.test(window.location.hash)) {
    const lpScript = document.createElement('script');
    lpScript.async = true;
    lpScript.defer = true;
    lpScript.src = `//s1.adform.net/Banners/Elements/Files/${
      isMobileOrTablet() ? '151830/2315842/alp-template.js' : '74292/2797397/alp-template-desktop-v1.js'
    }?v=${Math.round(new Date().getTime() / 10000)}`;
    window.document.head.appendChild(lpScript);
  }

  /**
   * Comment ads injection
   */
  if (/^(.+-[\d]{3,}\/(diskusija|komentarii))$/i.test(location.pathname) && !isAdFree) {
    window.addEventListener('requestCommentEmbed', function (event: any) {
      const d = document.getElementById(event.detail.id) as HTMLElement;
      const did = d.id + 'i';
      const i = event.detail.iteration;
      const slot = isMobileOrTablet() ? 'DLT_Mobile_300x250_' : 'DLT_Comments_300x250_';

      function pad(i: number) {
        return i > 9 ? String(i) : `0${i}`;
      }

      function dom(i?: string, twin?: boolean) {
        const id = i || did;

        if (twin) {
          d.innerHTML =
            '<div id="' +
            id +
            '--left" style="float:left;width:300px;margin-left:60px;margin-bottom:20px;overflow:hidden"></div><div id="' +
            id +
            '--right" style="float:right;width:300px;margin-right:60px;margin-bottom:20px;overflow:hidden"></div>';
        } else {
          d.innerHTML = '<div id="' + id + '" style="width:300px;margin: 0 auto 20px auto;overflow:hidden"></div>';
        }
      }

      switch (i) {
        case 1:
          dom();
          adxTag(isMobileOrTablet() ? '457213' : '522315', { mark: 1, dom: did });
          break;
        case 2:
          dom();
          adxTag(isMobileOrTablet() ? '457214' : '525394', { mark: 1, dom: did });
          break;
        default:
          if (isMobileOrTablet()) {
            dom(slot + i);
            ibbTag(slot + i, { mark: 1 });
          } else {
            dom(slot + pad(i), true);
            ibbTag(slot + pad(i) + '--left', { mark: 1 });
            ibbTag(slot + pad(i) + '--right', { mark: 1 });
          }
          break;
      }
    });
  }

  // Set window.adxDone to avoid multiple initialization
  window.adxDone = true;
}

/**
 * Lazyload observer for ADX
 */
export function initLazy() {
  if (window.adxLazyObserver) {
    return true;
  }

  if ('IntersectionObserver' in window) {
    window.adxLazyObserver = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          const target = entry.target as HTMLElement;
          if (entry.isIntersecting && target.dataset.lazymid) {
            const element = document.createElement('script');
            element.setAttribute('data-adfscript', 'adx.adform.net/adx/?mid=' + target.dataset.lazymid);
            target.appendChild(element);

            if (window.adxLazyObserver) {
              window.adxLazyObserver.unobserve(entry.target);
            }

            delete target.dataset.lazymid;
          }
        });
      },
      { rootMargin: `300px` }
    );
  } else {
    return false;
  }

  return true;
}

// append adx dom child
export function insertElement(placement: string, options: Record<string, any>) {
  const dom = document.getElementById(options.dom || 'adx-' + placement);
  if (dom) {
    if (options.lazy && initLazy() && window.adxLazyObserver) {
      dom.dataset.lazymid = placement;
      window.adxLazyObserver.observe(dom);
    } else {
      const elem = document.createElement('script');
      elem.setAttribute('data-adfscript', 'adx.adform.net/adx/?mid=' + placement);
      dom.appendChild(elem);
    }

    if (options.mark) {
      markElement(options.dom || 'adx-' + placement);
    }

    if (options.sticky) {
      makeSticky(options.dom || 'adx-' + placement);
    }
  }
}

export function isMobileOrTablet() {
  return (typeof window !== 'undefined' && window.screen.width <= 1024) || false;
}

export function markElement(id: string) {
  const mutationObserver = window.mutationObserver || window.WebKitMutationObserver || window.mozMutationObserver;
  if (!mutationObserver) {
    return;
  }

  const element = document.getElementById(id);
  if (!element) {
    return;
  }

  // check if it is already big enough, i.e. iframe
  if (element.clientHeight > 49) {
    markFunction(element);
    return;
  }

  // mutation observer
  const observer = new MutationObserver(function (mutations) {
    mutations.some(function () {
      if (element.clientHeight < 50) {
        return false;
      }

      observer.disconnect();

      markFunction(element);
      return true;
    });
  });

  observer.observe(element, { childList: true, subtree: true, attributes: true });
}

export function markFunction(element: HTMLElement) {
  if (element.classList && !element.classList.contains('ad-marker')) {
    element.className += ' ad-marker';
  }
}

export function makeSticky(id: string) {
  window.addEventListener(
    'load',
    function () {
      setTimeout(function () {
        const element = document.getElementById(id);
        if (!element || typeof element.closest !== 'function' || !element.getBoundingClientRect) {
          return;
        }

        // find parent .container element
        const parent = element.closest('.container');
        if (!parent) {
          return;
        }

        // container and ad offsets
        const top = element.getBoundingClientRect().top + document.documentElement.scrollTop;
        const parentTop = parent.getBoundingClientRect().top + document.documentElement.scrollTop;
        const parentHeight = parent.getBoundingClientRect().height - (top - parentTop);

        // if remaining height is more then ad height - stick it
        if (parentHeight > element.getBoundingClientRect().height + 50) {
          element.style.position = window.safari ? '-webkit-sticky' : 'sticky';
          element.style.top = '60px';
          if (element.parentElement) {
            element.parentElement.style.height = parentHeight + 'px';
          }
        }
      }, 3000);
    },
    false
  );
}

export function preload(placements: string[]) {
  if (!placements.length) {
    return;
  }

  const preload: string[] = [];
  placements.forEach((placement: string) => {
    preload.push(`adx.adform.net/adx/?mid=${placement}`);
  });

  window.adformtag.push({ preload });
}

export function removeLoadingClass(selector: string) {
  const dom = document.getElementById(selector);
  if (dom && dom.parentElement?.classList.contains('banner--loading')) {
    dom.parentElement?.classList.remove('banner--loading');
  }
}

export default function adxTag(placement: string, options: Record<string, any>) {
  // Failsafe check for SSR execution
  if (!window) {
    return null;
  }

  // Reduce ads for customers if placement found in map
  if (options.isCustomer && customerReduceAdsMap.includes(placement)) {
    return;
  }

  // DiMatter first look position replacement
  if (
    placement === '457214' &&
    document.cookie &&
    document.cookie.indexOf('adntfl1=') === -1 &&
    /^\/(lietuvoje|uzsienyje|sportas|krepsinis|auto|veidai)\//.test(location.pathname)
  ) {
    const dom = document.getElementById(options.dom ? options.dom : `adx-${placement}`);
    if (dom) {
      const idr = '--dr' + Math.random().toString(16).substring(2);

      document.cookie =
        'adntfl1=1;domain=.' +
        location.hostname.split('.').reverse()[1] +
        '.' +
        location.hostname.split('.').reverse()[0] +
        ';path=/;expires=' +
        new Date(Date.now() + 1000 * 60 * 60 * 24).toUTCString() +
        ';secure;samesite=lax';

      dom.innerHTML = '';
      dom.id = `DLT_Mobile_300x250_Firstlook${idr}`;

      if (options.dom) {
        options.dom = dom.id;
      }

      ibbTag(dom.id, options);

      return;
    }
  }

  // Initiate placement grouping
  if (!isMobileOrTablet() && options.group && options.group.length) {
    const group = [placement].concat(options.group);
    window.adformtag.push(function () {
      window.adformtag.addStrictGroup(group);
    });
  }

  // Set local keywords only if needed (no global keywords set, or local ones provided)
  if (options.keys || options.kv) {
    let mkw = window.adx_mkw || [];
    let mkv = window.adx_mkv || [];

    // nativeapp key
    if (options.isApp) {
      mkw.push('nativeapp');
    }

    // option keys
    mkw = Array.from(new Set(mkw.concat(options.keys || [])));

    // option kv (for portal-root sites, to be able to change key:value without reloading doc)
    if (options.kv) {
      mkv = options.kv;
    }

    // add keywords
    if (mkw.length || mkv.length) {
      window.adformtag.push(function () {
        for (let i = 0; i < mkw.length; i++) {
          window.adformtag.setTargeting(placement, mkw[i]);
        }

        for (let i = 0; i < mkv.length; i++) {
          const v = mkv[i].split(':');
          window.adformtag.setTargeting(placement, v[0], v[1]);
        }
      });
    }
  }

  window.adformtag.push(function () {
    window.adformtag.onLoaded(placement, function (e: { isEmpty: any }) {
      // Remove loading class
      removeLoadingClass(options.dom ? options.dom : 'adx-' + placement);

      if (e.isEmpty) {
        // path exceptions
        if (document.location.pathname.indexOf('/kriminalai/') !== -1) {
          placement += '-kriminalai';
        }

        if (document.location.pathname.indexOf('/kablys/') !== -1) {
          placement += '-kablys';
        }

        if (ibbmap[placement]) {
          const dom = document.getElementById(options.dom ? options.dom : 'adx-' + placement);
          if (dom) {
            dom.innerHTML = '';
            dom.id = ibbmap[placement];
            if (options.dom) {
              options.dom = dom.id;
            }

            options.fallbackId = placement;

            ibbTag(ibbmap[placement], options);
          }
        } else {
          const dom = document.getElementById(options.dom ? options.dom : 'adx-' + placement);
          if (dom) {
            const emptyParent = dom.parentNode as HTMLElement;
            if (
              emptyParent.classList.contains('delfi-ads-block') ||
              (emptyParent.classList.contains('banner') &&
                !emptyParent.classList.contains('banner--mobileinterstitial') &&
                !emptyParent.classList.contains('banner--scroller'))
            ) {
              emptyParent.style.display = 'none';
            }
          }
        }
      }
    });
  });

  insertElement(placement, options);
}
