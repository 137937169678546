export const loadScripts = (script: string, attr?: Record<string, boolean>) => {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement('script');
    scriptEl.src = script;
    scriptEl.type = 'text/javascript';
    if (attr) {
      Object.keys(attr).forEach((val) => {
        (scriptEl as any)[val] = attr[val];
      });
    }

    // Attach script to head
    document.getElementsByTagName('head')[0].appendChild(scriptEl);

    let scriptLoaded: any = null;
    let scriptErrored: any = null;

    scriptErrored = () => {
      reject(new Error(`${scriptEl.src} failed to load.`));
      scriptEl.removeEventListener('load', scriptLoaded);
      scriptEl.removeEventListener('error', scriptErrored);
    };

    scriptLoaded = () => {
      resolve(true);
      scriptEl.removeEventListener('load', scriptLoaded);
      scriptEl.removeEventListener('error', scriptErrored);
    };

    // Resolve the promise once the script is loaded
    scriptEl.addEventListener('load', scriptLoaded);

    // Catch any errors while loading the script
    scriptEl.addEventListener('error', scriptErrored);
  });
};

export const executeScript = (string: string) => {
  // remove script tags from string (this has been declared globally)
  const script = string.replace(/<\/?script>/g, '');
  eval(script);
};

export const getScriptFromString = (string: string) => {
  const regex = /<script\b[^>]*>(.*?)<\/script>/gm;
  const scripts = regex.exec(string);
  return scripts;
};
