export default {
  type: {
    html: 'FragmentHtml',
    sidebar: 'FragmentSidebar',
    pullout: 'FragmentPullout',
    media: {
      image: 'FragmentPicture',
      gallery: 'FragmentGallery',
      audio: 'FragmentAudio',
      video: 'FragmentVideo',
      stream: 'FragmentVideo',
    },
    embed: {
      default: 'FragmentEmbed',
      custom: 'FragmentEmbed',
      quiz: 'FragmentQuiz',
      rembi: 'FragmentJsmk',
      liveblog: 'FragmentJsmk',
      dstream: 'FragmentDstream',
    },
  } as Record<string, any>,
};
