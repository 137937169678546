import { Touches } from '@gallery/utils/Touches';

/*
 * This is based on https://github.com/drozhzhin-n-e/vue-pinch-zoom
 * but modified to fit our needs
 *  */

export interface emittedPayload {
  scale: number;
  moveX: number;
  moveY: number;
}

export interface ClientPosition {
  clientX: number;
  clientY: number;
}

export interface PinchZoomProperties {
  element: any;
  key?: string;
  doubleTap?: true;
  doubleTapScale?: number;
  zoomControlScale?: number;
  transitionDuration?: number;
  autoZoomOut?: boolean;
  limitZoom?: number;
  disablePan?: boolean;
  limitPan?: boolean;
  minScale?: number;
  onScaleChange?: any;
  listeners?: 'auto' | 'mouse and touch';
  wheel?: boolean;
  autoHeight?: boolean;
  draggableImage?: boolean;
}

export const PinchZoomDefaultProperties = {
  doubleTap: true,
  doubleTapScale: 3,
  transitionDuration: 200,
  limitZoom: 'original image size',
  minScale: 0,
  wheel: true,
  draggableImage: true,
  listeners: 'auto',
  zoomControlScale: 2,
};

export class PinchZoom {
  properties: PinchZoomProperties;
  touches: any;
  element: any;
  elementTarget: any;
  parentElement: any;
  public scale = 1;
  initialScale = 1;
  elementPosition: any;
  eventType: any;
  startX = 0;
  startY = 0;
  moveX = 0;
  moveY = 0;
  initialMoveX = 0;
  initialMoveY = 0;
  moveXC = 0;
  moveYC = 0;
  draggingMode = false;
  distance = 0;
  initialDistance = 0;
  events: any = {};
  maxScale = 3;
  disableTransformElement = false;

  constructor(properties: PinchZoomProperties) {
    this.element = properties.element;
    this.elementTarget = this.element.querySelector('*').tagName;
    this.parentElement = this.element.parentElement;
    this.properties = Object.assign({}, PinchZoomDefaultProperties, properties);
    this.pollLimitZoom();

    /* Init */

    this.touches = new Touches({
      element: properties.element,
      listeners: this.properties.listeners,
      resize: this.properties.autoHeight,
    });

    this.setBasicStyles();

    this.touches.on('touchstart', this.handleTouchstart);
    this.touches.on('touchend', this.handleTouchend);
    this.touches.on('mousedown', this.handleTouchstart);
    this.touches.on('mouseup', this.handleTouchend);
    this.touches.on('pan', this.handlePan);
    this.touches.on('mousemove', this.handlePan);
    this.touches.on('pinch', this.handlePinch);

    if (this.properties.doubleTap) {
      this.touches.on('double-tap', this.handleDoubleTap);
    }

    if (this.properties.autoHeight) {
      this.touches.on('resize', this.handleResize);
    }
  }

  private emitEvent(payload: emittedPayload) {
    if (this.properties.onScaleChange) {
      this.properties.onScaleChange(payload);
    }
  }

  private handleTouchstart = (event: MouseEvent | TouchEvent) => {
    this.getElementPosition();

    if (this.eventType === undefined) {
      this.getTouchstartPosition(event);
    }
  };

  private handleTouchend = (event: MouseEvent | TouchEvent) => {
    if (event.type === 'touchend') {
      this.draggingMode = false;

      // @ts-ignore-next-line
      const touches = event.touches;

      if (this.scale < 1) {
        this.scale = 1;
      }

      if (this.properties.autoZoomOut && this.eventType === 'pinch') {
        this.scale = 1;
      }

      if (this.eventType === 'pinch' || this.eventType === 'pan') {
        this.alignImage();
      }

      // Update initial values
      if (this.eventType === 'pinch' || this.eventType === 'pan' || this.eventType === 'horizontal-swipe' || this.eventType === 'vertical-swipe') {
        this.updateInitialValues();
      }

      this.eventType = 'touchend';

      if (touches && touches.length === 0) {
        this.eventType = undefined;
      }
    }

    if (event.type === 'mouseup') {
      this.draggingMode = false;
      this.updateInitialValues();
      this.eventType = undefined;
    }
  };

  private handlePan = (event: MouseEvent | TouchEvent) => {
    if (this.scale <= 1 || this.properties.disablePan) {
      return;
    }

    event.preventDefault();
    const { clientX, clientY } = this.getClientPosition(event, 0);

    if (!this.eventType) {
      this.startX = clientX - this.elementPosition.left;
      this.startY = clientY - this.elementPosition.top;
    }

    this.eventType = 'pan';
    this.moveX = this.initialMoveX + (this.moveLeft(event, 0) - this.startX);
    this.moveY = this.initialMoveY + (this.moveTop(event, 0) - this.startY);

    if (this.properties.limitPan) {
      this.limitPanY();
      this.limitPanX();
    }

    if (event.type === 'mousemove') {
      this.centeringImage();
    }

    this.transformElement(0);
  };

  private handleDoubleTap = (event: MouseEvent | TouchEvent) => {
    this.toggleScale(event);
  };

  private handlePinch = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();

    if (this.eventType === undefined || this.eventType === 'pinch') {
      // @ts-ignore-next-line
      const touches = event.touches ? event.touches : event;

      if (!this.eventType) {
        this.initialDistance = this.getDistance(touches);

        const moveLeft0 = this.moveLeft(event, 0);
        const moveLeft1 = this.moveLeft(event, 1);
        const moveTop0 = this.moveTop(event, 0);
        const moveTop1 = this.moveTop(event, 1);

        this.moveXC = (moveLeft0 + moveLeft1) / 2 - this.initialMoveX;
        this.moveYC = (moveTop0 + moveTop1) / 2 - this.initialMoveY;
      }

      this.eventType = 'pinch';

      this.distance = this.getDistance(touches);

      this.scale = this.initialScale * (this.distance / this.initialDistance);
      this.moveX = this.initialMoveX - ((this.distance / this.initialDistance) * this.moveXC - this.moveXC);
      this.moveY = this.initialMoveY - ((this.distance / this.initialDistance) * this.moveYC - this.moveYC);

      this.handleLimitZoom();

      if (this.properties.limitPan) {
        this.limitPanY();
        this.limitPanX();
      }

      if (this.scale <= 1) {
        this.scale = 1;
        this.moveX = 1;
        this.moveY = 1;
      }
      this.transformElement(0);
    }
  };

  private handleResize = () => {
    this.setAutoHeight();
  };

  private handleLimitZoom() {
    const limitZoom = this.maxScale;
    const minScale = this.properties.minScale || 0;

    if (this.scale > limitZoom || this.scale <= minScale) {
      const imageWidth = this.getImageWidth();
      const imageHeight = this.getImageHeight();
      const enlargedImageWidth = imageWidth * this.scale;
      const enlargedImageHeight = imageHeight * this.scale;
      const moveXRatio = this.moveX / (enlargedImageWidth - imageWidth);
      const moveYRatio = this.moveY / (enlargedImageHeight - imageHeight);

      if (this.scale > limitZoom) {
        this.scale = limitZoom;
      }

      if (this.scale <= minScale) {
        this.scale = minScale;
      }

      const newImageWidth = imageWidth * this.scale;
      const newImageHeight = imageHeight * this.scale;

      this.moveX = -Math.abs(moveXRatio * (newImageWidth - imageWidth));
      this.moveY = -Math.abs(-moveYRatio * (newImageHeight - imageHeight));
    }
  }

  private getLimitZoom() {
    this.maxScale = this.properties.limitZoom || 0;

    return this.maxScale;
  }

  private moveLeft(event: MouseEvent | TouchEvent, index: number): number {
    const clientX = this.getClientPosition(event, index).clientX;
    return clientX - this.elementPosition.left;
  }

  private moveTop(event: MouseEvent | TouchEvent, index: number) {
    const clientY = this.getClientPosition(event, index).clientY;
    return clientY - this.elementPosition.top;
  }

  public centeringImage() {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    const initialMoveX = this.moveX;
    const initialMoveY = this.moveY;

    if (this.moveY > 0) {
      this.moveY = 0;
    }
    if (this.moveX > 0) {
      this.moveX = 0;
    }

    if (img) {
      this.limitPanY();
      this.limitPanX();
    }
    if (img && this.scale < 1) {
      if (this.moveX < this.element.offsetWidth * (1 - this.scale)) {
        this.moveX = this.element.offsetWidth * (1 - this.scale);
      }
    }

    return initialMoveX !== this.moveX || initialMoveY !== this.moveY;
  }

  private limitPanY() {
    const imgHeight = this.getImageHeight();
    const scaledImgHeight = imgHeight * this.scale;
    const parentHeight = this.parentElement.offsetHeight;
    const elementHeight = this.element.offsetHeight;

    if (scaledImgHeight < parentHeight) {
      this.moveY = (parentHeight - elementHeight * this.scale) / 2;
    } else {
      const imgOffsetTop = ((imgHeight - elementHeight) * this.scale) / 2;

      if (this.moveY > imgOffsetTop) {
        this.moveY = imgOffsetTop;
      } else if (scaledImgHeight + Math.abs(imgOffsetTop) - parentHeight + this.moveY < 0) {
        this.moveY = -(scaledImgHeight + Math.abs(imgOffsetTop) - parentHeight);
      }
    }
  }

  private limitPanX() {
    const imgWidth = this.getImageWidth();
    const scaledImgWidth = imgWidth * this.scale;
    const parentWidth = this.parentElement.offsetWidth;
    const elementWidth = this.element.offsetWidth;

    if (scaledImgWidth < parentWidth) {
      this.moveX = (parentWidth - elementWidth * this.scale) / 2;
    } else {
      const imgOffsetLeft = ((imgWidth - elementWidth) * this.scale) / 2;

      if (this.moveX > imgOffsetLeft) {
        this.moveX = imgOffsetLeft;
      } else if (scaledImgWidth + Math.abs(imgOffsetLeft) - parentWidth + this.moveX < 0) {
        this.moveX = -(imgWidth * this.scale + Math.abs(imgOffsetLeft) - parentWidth);
      }
    }
  }

  private setBasicStyles() {
    this.element.style.display = 'flex';
    this.element.style.alignItems = 'center';
    this.element.style.justifyContent = 'center';
    this.element.style.transformOrigin = '0 0';
    this.setImageSize();
    this.setDraggableImage();
  }

  private removeBasicStyles() {
    this.element.style.display = '';
    this.element.style.alignItems = '';
    this.element.style.justifyContent = '';
    this.element.style.transformOrigin = '';
    this.removeImageSize();
    this.removeDraggableImage();
  }

  private setDraggableImage() {
    const imgElement = this.getImageElement();

    if (imgElement) {
      imgElement.draggable = this.properties.draggableImage!;
    }
  }

  private removeDraggableImage() {
    const imgElement = this.getImageElement();

    if (imgElement) {
      imgElement.draggable = true;
    }
  }

  private setImageSize() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      imgElement[0].style.maxWidth = '100%';
      imgElement[0].style.maxHeight = '100%';

      this.setAutoHeight();
    }
  }

  private setAutoHeight() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (!this.properties.autoHeight || !imgElement.length) {
      return;
    }

    const imgNaturalWidth = imgElement[0].getAttribute('width');
    const imgNaturalHeight = imgElement[0].getAttribute('height');
    const sizeRatio = imgNaturalWidth / imgNaturalHeight;
    const parentWidth = this.parentElement.offsetWidth;

    imgElement[0].style.maxHeight = parentWidth / sizeRatio + 'px';
  }

  private removeImageSize() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      imgElement[0].style.maxWidth = '';
      imgElement[0].style.maxHeight = '';
    }
  }

  private getElementPosition() {
    this.elementPosition = this.element.parentElement.getBoundingClientRect();
  }

  private getTouchstartPosition(event: MouseEvent | TouchEvent) {
    const { clientX, clientY } = this.getClientPosition(event, 0);

    this.startX = clientX - this.elementPosition.left;
    this.startY = clientY - this.elementPosition.top;
  }

  private getClientPosition(event: MouseEvent | TouchEvent, index: number): ClientPosition {
    let clientX;
    let clientY;

    if (event.type === 'touchstart' || event.type === 'touchmove') {
      // @ts-ignore-next-line
      const touches = event.touches ? event.touches : event;
      clientX = touches[index].clientX;
      clientY = touches[index].clientY;
    }
    if (event.type === 'mousedown' || event.type === 'mousemove') {
      // @ts-ignore-next-line
      clientX = event.clientX;
      // @ts-ignore-next-line
      clientY = event.clientY;
    }

    return {
      clientX,
      clientY,
    };
  }

  public resetScale() {
    this.scale = 1;
    this.moveX = 0;
    this.moveY = 0;
    this.updateInitialValues();
    this.transformElement(this.properties.transitionDuration);
  }

  private updateInitialValues(): void {
    this.initialScale = this.scale;
    this.initialMoveX = this.moveX;
    this.initialMoveY = this.moveY;
  }

  private getDistance(touches: any): number {
    return Math.sqrt(Math.pow(touches[0].pageX - touches[1].pageX, 2) + Math.pow(touches[0].pageY - touches[1].pageY, 2));
  }

  private getImageHeight(): number {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    return img.offsetHeight;
  }

  private getImageWidth(): number {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    return img.offsetWidth;
  }

  private transformElement(duration = 0): void {
    if (this.disableTransformElement) return;

    this.element.style.transition = `all ${duration}ms`;
    this.element.style.transform = `matrix(${Number(this.scale)}, 0, 0,${Number(this.scale)},${Number(this.moveX)},${Number(this.moveY)})`;

    this.emitEvent({ scale: this.scale, moveX: this.moveX, moveY: this.moveY });
  }

  private pollLimitZoom() {
    const poll = setInterval(() => {
      if (this.getLimitZoom()) {
        clearInterval(poll);
      }
    }, 10);
  }

  private getImageElement(): HTMLElement | null {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      return imgElement[0];
    }
    return null;
  }

  public changeScale(newScale: number): void {
    if (newScale <= 1) {
      this.resetScale();
    } else if (newScale > 1) {
      this.scale = newScale;

      const visibleAreaWidth = this.element.offsetWidth;
      const visibleAreaHeight = this.element.offsetHeight;
      const scalingPercent = (visibleAreaWidth * this.scale) / (visibleAreaWidth * this.initialScale);
      const xCenter = visibleAreaWidth / 2 - this.initialMoveX;
      const yCenter = visibleAreaHeight / 2 - this.initialMoveY;

      this.moveX = this.initialMoveX - (scalingPercent * xCenter - xCenter);
      this.moveY = this.initialMoveY - (scalingPercent * yCenter - yCenter);

      this.centeringImage();
      this.updateInitialValues();
      this.transformElement(this.properties.transitionDuration);
    }
  }

  public toggleScale(event?: any): void {
    if (this.initialScale === 1) {
      const zoomControlScale = this.properties.zoomControlScale || 0;
      const doubleTapScale = this.properties.doubleTapScale || 3;

      // @ts-ignore-next-line
      if (event && event.changedTouches) {
        if (this.properties.doubleTapScale === undefined) {
          return;
        }

        // @ts-ignore-next-line
        const changedTouches = event.changedTouches;
        this.scale = this.initialScale * doubleTapScale;
        this.moveX = this.initialMoveX - (changedTouches[0].clientX - this.elementPosition.left) * (doubleTapScale - 1);
        this.moveY = this.initialMoveY - (changedTouches[0].clientY - this.elementPosition.top) * (doubleTapScale - 1);
      } else {
        // Desktop
        // eslint-disable-next-line no-lonely-if
        if (event) {
          // click event in desktop
          this.scale = this.initialScale * (zoomControlScale + 1);
          // @ts-ignore-next-line
          this.moveX = this.initialMoveX - (event.clientX - this.elementPosition.left) * (doubleTapScale - 1);
          // @ts-ignore-next-line
          this.moveY = this.initialMoveY - (event.clientY - this.elementPosition.top) * (doubleTapScale - 1);
        } else {
          // zoom to center
          this.scale = this.initialScale * (zoomControlScale + 1);
          this.moveX = this.initialMoveX - (this.element.offsetWidth * (this.scale - 1)) / 2;
          this.moveY = this.initialMoveY - (this.element.offsetHeight * (this.scale - 1)) / 2;
        }
      }

      this.centeringImage();
      this.updateInitialValues();
      this.transformElement(this.properties.transitionDuration);
    } else {
      this.resetScale();
    }
  }

  private alignImage() {
    const isMoveChanged = this.centeringImage();

    if (isMoveChanged) {
      this.updateInitialValues();
      this.transformElement(this.properties.transitionDuration);
    }
  }

  public destroy() {
    this.removeBasicStyles();
    this.touches.destroy();
  }
}
