import { GetterTree, MutationTree } from 'vuex';
export const state = () => ({
  storage: {} as Record<string, unknown>,
  pageContentLoaded: null as string | null,
  alerts: [] as { type: string; title: string; text: string }[],
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, Record<string, unknown>> = {
  getStorage: (state) => (type: string) => {
    return state.storage[type];
  },
};

export const mutations: MutationTree<RootState> = {
  setStorage(state, value: Record<string, unknown>) {
    state.storage = {
      ...state.storage,
      ...value,
    };
  },
  resetStorage(state, keys: string[]) {
    for (const key of keys) {
      if (key in state.storage) {
        delete state.storage[key];
      }
    }
  },
  setPageContentLoadTimeStamp(state, timestamp: string) {
    state.pageContentLoaded = timestamp;
  },
  alert(state, alertData: { type: string; title: string; text: string }) {
    state.alerts.push(alertData);
  },
};
