import BlockType13Component from '@headlines/components/block/11-20/block-type-13/BlockType13.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType13: AdminBlockType = {
  component: BlockType13Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    lead: false,
    initialTab: 1,
    excludeContentMarketing: false,
    excludeIssueOnly: false,
    excludeSpecialProject: false,
    includeChannels: [],
    excludeCategories: [],
  }),
  articlesCount: 3,
};

export default AdminBlockType13;
