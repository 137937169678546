// @ts-nocheck
/* tslint:disable */
/* eslint:disable */
/* eslint-disable prefer-spread */
export default function loadGoogleAnalyticsScript() {
  (function (
    f,
    b,
    e: 'script',
    v,
    n?: any,
    t?: HTMLScriptElement,
    s?: Element,
  ) {
    if (f.fbq) return;
    n = f.fbq = function () {
      // eslint-disable-next-line prefer-rest-params
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    if (s.parentNode !== null) {
      s.parentNode.appendChild(t);
    }
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  );
}
