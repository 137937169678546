import { GetterTree, MutationTree } from 'vuex';
import { State } from '../types/categories';

export const state: () => State = () => ({
  category: null,
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getCategoryData: (state) => state.category,
};

export const mutations: MutationTree<State> = {
  setCategoryData(state, value: State['category']) {
    state.category = value || null;
  },
};
