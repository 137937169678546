import { Plugin } from '@nuxt/types';
import apiConfig from '@root/config/api.config';

const publicPathPlugin: Plugin = (context) => {
  const { $config } = context;
  if ($config.publicPath) {
    __webpack_public_path__ = apiConfig.CDN_PUBLIC_PATH ? `${apiConfig.CDN_PUBLIC_PATH}/` : `${$config.publicPath}/`;
  }
};

export default publicPathPlugin;
