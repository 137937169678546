
import Vue from 'vue';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { CustomerData } from '@root/modules/customer/types/customer';

interface Data {
  bookmarks: CustomerData[];
  customerDataService: CustomerDataService | null;
}

interface Computed {
  isBookmarked: boolean;
  activeArticleId: string;
  customerToken: string;
  bookmarkedArticle: CustomerData | undefined;
}

interface Methods {
  toggleBookmark: () => Promise<void>;
  getBookmark: () => Promise<void>;
}

interface Props {
  articleId: number | null;
}

interface Customer extends Window {
  showLoginEmbed: any;
}

declare const window: Customer;

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    articleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      bookmarks: [],
      customerDataService: null,
    };
  },
  computed: {
    isBookmarked() {
      return !!this.bookmarkedArticle;
    },
    bookmarkedArticle() {
      return this.bookmarks.find((bookmark) => bookmark.value === this.activeArticleId);
    },
    activeArticleId() {
      return String(this.articleId || this.$store.getters['article/getActiveArticle']?.id || '');
    },
    customerToken() {
      return this.$store.state.piano.token;
    },
  },
  watch: {
    async activeArticleId() {
      await this.getBookmark();
    },
    async customerToken() {
      this.customerDataService?.setCustomerToken(this.customerToken);

      if (!this.customerToken) {
        this.bookmarks = [];
      } else {
        await this.getBookmark();
      }
    },
  },
  async beforeMount() {
    this.customerDataService = new CustomerDataService();

    if (this.customerToken) {
      this.customerDataService.setCustomerToken(this.customerToken);
      await this.getBookmark();
    }
  },
  methods: {
    async toggleBookmark() {
      // If not logged in, show login modal, retry toggleBookmark() after login success
      if (!this.customerToken) {
        const { locale } = this.$channelConfig('settings');
        window.showLoginEmbed('login-modal', {
          modal: true,
          loginAnchorId: '_login',
          locale: locale.split('_')[0] || 'lt',
          successCallback: () => {
            const modal = window.document.getElementsByClassName('login-modal-container')[0];
            modal.classList.remove('active');
            setTimeout(() => {
              this.toggleBookmark();
            }, 500);
          },
        });
      }

      if (this.isBookmarked) {
        const bookmarkedArticleIndex = this.bookmarks.indexOf(this.bookmarkedArticle!);

        try {
          this.customerDataService?.delete({ id: this.bookmarkedArticle!.id });
          this.bookmarks.splice(bookmarkedArticleIndex, 1);
        } catch (e) {
          this.$sentry.captureException(e, {
            contexts: { vue: { componentName: 'BookmarkButton', method: 'toggleBookmark delete' } },
            tags: { 'process.server': process.server ? 'server' : 'client' },
          });
        }
      } else {
        if (!this.activeArticleId) {
          return;
        }

        let response;

        try {
          response = await this.customerDataService?.create({ key: 'bookmark', value: this.activeArticleId });
        } catch (e) {
          this.$sentry.captureException(e, {
            contexts: { vue: { componentName: 'BookmarkButton', method: 'toggleBookmark create' } },
            tags: { 'process.server': process.server ? 'server' : 'client' },
          });
        }

        if (response?.data?.customerData) {
          this.bookmarks.push(response.data.customerData);
        }
      }
    },
    async getBookmark() {
      let response;

      try {
        response = await this.customerDataService?.get({ key: ['bookmark'], value: this.activeArticleId, limit: 1, offset: 0 });
      } catch (e) {
        this.$sentry.captureException(e, {
          contexts: { vue: { componentName: 'BookmarkButton', method: 'getBookmark' } },
          tags: { 'process.server': process.server ? 'server' : 'client' },
        });
      }

      if (response?.data?.items?.length) {
        this.bookmarks = [...new Set([...this.bookmarks, ...response.data.items])];
      }
    },
  },
});
