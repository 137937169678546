interface ExDocument extends Document {
  __defineGetter__: (arg0: string, arg1: () => void) => void;
}
interface ExWindow extends Window {
  document: ExDocument;
}

declare const window: ExWindow;

const documentReferrer = (path: string) => {
  window.document.__defineGetter__('referrer', () => path);
};

export default documentReferrer;
