
import Vue from 'vue';

interface Data {
  visible: boolean;
}

interface Methods {
  handleVisible: () => void;
  scrollTop: () => void;
}

export default Vue.extend<Data, Methods, unknown, unknown>({
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    // Disable listeners in Internet Explorer
    if (this.$ua.browser() !== 'Internet Explorer') {
      this.handleVisible();
      window.addEventListener('scroll', this.handleVisible);
      window.addEventListener('resize', this.handleVisible);
    }
  },
  destroyed() {
    if (this.$ua.browser() !== 'Internet Explorer') {
      window.removeEventListener('scroll', this.handleVisible);
      window.removeEventListener('resize', this.handleVisible);
    }
  },
  methods: {
    handleVisible() {
      this.visible = window.pageYOffset > window.innerHeight;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
});
