
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType103';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';

import Grid1 from './Grid1.vue';
import Grid2 from './Grid2.vue';
import Grid3 from './Grid3.vue';
import Grid4 from './Grid4.vue';
import BlockType8 from '../../1-10/BlockType8.vue';

import { Headline } from '@headlines/types/headline';

interface DataWithSettings {
  data: {
    items: Headline[];
  };
  settings: BlockTypeSettings;
}

interface Computed {
  blocks: {
    name: string;
    data: {
      items: Headline[];
    };
    settings: BlockTypeSettings;
  }[];
}

interface Methods {
  getBlockData: (items: Headline[], amount: number) => DataWithSettings;
}

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: false,
  channel: false,
  category: true,
  firstBlockHalf: false,
  blockAds: null,
  rowsAds: null,
  layout: null,
  additionalHtml: null,
  blockTitle: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, Computed, unknown>({
  components: {
    Grid1,
    Grid2,
    Grid3,
    Grid4,
    AdFormBanner,
    BlockType8,
  },
  computed: {
    blocks() {
      const { getBlockData, data } = this;
      const items = [...data.items];
      const layout = this.settings.layout;
      const rowsAds = this.settings.rowsAds;
      const blocks = [];

      if (layout !== null) {
        layout.forEach((element, index) => {
          const headlinesCount = rowsAds && rowsAds[index] !== '' ? element - 1 : element;

          blocks.push({ name: 'Grid' + element, ...getBlockData(items, headlinesCount) });
        });
      } else {
        blocks.push({ name: 'Grid3', ...getBlockData(items, 3) });
      }

      return blocks;
    },
  },
  methods: {
    getBlockData(items, amount) {
      return {
        data: { items: items.splice(0, amount) },
        settings: this.getSettings,
      };
    },
  },
});
