type Settings = { id: string; appId?: string };

export const getUid = (params: { defaultSettings?: Settings; pageSettings?: Settings; isNativeApp?: boolean }) => {
  const { defaultSettings, pageSettings, isNativeApp } = params;

  if (!defaultSettings) {
    return '';
  }

  if (Boolean(isNativeApp) && defaultSettings.appId) {
    return defaultSettings.appId;
  }

  if (pageSettings && pageSettings.id) {
    return pageSettings.id;
  }

  return defaultSettings.id;
};
