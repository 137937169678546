
import Vue from 'vue';
import { formatCustomDate, isDateBetween } from '@core/filters/dateFormater';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import ContentImage from '@core/components/UI/ContentImage.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { Stream } from './types/blockType23';

interface Computed {
  isClickable: boolean;
}

interface Data {
  activeStream: number | null;
  showStream: boolean;
}

interface Props {
  texts: {
    read_more: string;
    today: string;
    view_stream: string;
    watch_stream: string;
    on_air: string;
  };
  stream: Stream;
  getDate: string;
  getTime: string;
  isLive: boolean;
  hasChannelSubscription: boolean;
}

interface Methods {
  openArticleOrSetOpenedStream(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseButton,
    BaseIcon,
    LinkHandler,
    ContentImage,
  },
  filters: {
    formatCustomDate,
    isDateBetween,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    texts: {
      type: Object,
      required: true,
    },
    getDate: {
      type: String,
      required: true,
    },
    getTime: {
      type: String,
      required: true,
    },
    isLive: {
      type: Boolean,
      required: true,
    },
    hasChannelSubscription: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeStream: null,
      showStream: false,
    };
  },
  methods: {
    openArticleOrSetOpenedStream() {
      if (this.stream.link && (!this.isLive || (this.isLive && this.stream.subscribers_only && !this.hasChannelSubscription))) {
        window.open(this.stream.link);
      } else if (this.isLive && ((this.stream.subscribers_only && this.hasChannelSubscription) || !this.stream.subscribers_only)) {
        this.$emit('set-opened-stream');
      }
    },
  },
  computed: {
    isClickable() {
      return !!this.isLive || !!this.stream.link;
    },
  },
});
