
import Vue from 'vue';

interface Props {
  content: Record<string, any>;
}

interface Computed {
  file: () => string | null;
}

interface Methods {
  showAllContacts: () => void;
}

interface Process {
  client: boolean;
}

declare let process: Process;

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    file() {
      if (this.content.attrs.file) {
        return this.content.attrs.file;
      }

      return null;
    },
  },
  methods: {
    showAllContacts() {
      if (!process.client) {
        return;
      }

      const allContacts = window.document.getElementById('all-contacts');
      if (allContacts) {
        const allContactsStyle = allContacts.style.display;

        allContacts.style.display = allContactsStyle === 'none' ? 'table' : 'none';
      }
    },
  },
});
