
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons } from '../../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType103';

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: false,
  category: false,
  firstBlockHalf: false,
  blockAds: null,
  rowsAds: null,
  layout: null,
  additionalHtml: null,
  blockTitle: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
  },
});
