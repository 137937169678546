
import Vue from 'vue';

interface Props {
  siteLogo: {
    src: {
      desktop: string | null;
      mobile: string | null;
    };
    url: string | null;
    alt: string;
    logoIsAnimated: boolean;
    logoWidth: number | null;
    logoWidthMobile: number | null;
    animatedLogoWidth: number | null;
  };
}
export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    siteLogo: {
      type: Object,
      required: true,
    },
  },
});
