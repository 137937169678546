
import Vue from 'vue';

interface Computed {
  sharingLinks: {
    facebook: {
      enabled: boolean;
      href: string;
    };
    twitter: {
      enabled: boolean;
      href: string;
    };
    instagram: {
      enabled: boolean;
      href: string;
    };
    vk: {
      enabled: boolean;
      href: string;
    };
  };
}

export default Vue.extend<unknown, unknown, Computed, unknown>({
  computed: {
    sharingLinks() {
      return this.$channelConfig('socialLinks');
    },
  },
});
