import { Route } from 'vue-router';

export default function isTildaArticle(route: Route, customCategorySettings: any) {
  if (route && route.params.category) {
    const categorySlug = route.params.subcategory || route.params.category;
    if (customCategorySettings && Array.isArray(customCategorySettings)) {
      const categoryAdditionalSettings = customCategorySettings.find((item: { categories: string | string[] }) => item.categories.includes(categorySlug));
      const categoryAdditionalData = categoryAdditionalSettings?.additionalData || {};
      return categoryAdditionalData.isTildaArticle;
    }
  }

  return false;
}
