
import Vue from 'vue';
import Player from '@media/components/base/Player.vue';
import config from '@media/config/media.config';
import { getAudio } from '@media/services/getAudio';
import { AudioItem } from '@media/types/getAudio';
import { PortalPlayer } from '@media/services/player';
import jwplayer from '@media/types/JwPlayer';
import { popupWindow, copyLink } from '@media/utils/sharing';
import { playerAds } from '@media/utils/playerAds';

import AudioPlayerPlaylist from '@media/components/audio/AudioPlayerPlaylist.vue';

type OptionsType = 'rate' | 'sharing';

type ShareData = {
  title: string;
  text: string;
  url: string;
};

interface ShNavigator extends Navigator {
  share: (data?: ShareData) => Promise<void>;
}

interface ShWindow extends Window {
  [index: string]: any;
  navigator: ShNavigator;
}

declare let window: ShWindow;

interface Props {
  data: {
    attrs: AudioItem;
  };
  type: string;
  ads: Record<string, unknown>;
}

interface AudioMeta {
  title: string | null;
  credit: string;
  pic: string | null;
}

interface Data {
  doLoadMore: boolean;
  activeEpisodeId: string;
  trackChanged: boolean;
  player: jwplayer.JWPlayer | null;
  containerId: string;
  isPLayed: boolean;
  audioData: AudioItem | null;
  audioMeta: AudioMeta;
  playlist: AudioItem[];
  showModal: boolean;
  playBackRate: number;
  elapsedTime: number | string;
  optionsType: OptionsType;
  sharing: {
    facebook: string;
    twitter: string;
  };
}

interface Computed {
  toggleIcon: string;
  toggleClass: string;
}

interface Methods {
  handleTrackChange: (id: string) => void;
  mountPlayer: (settings: Record<string, unknown>, mediaData: AudioItem, startPlaying?: boolean) => void;
  setPlayerSettings: (mediaData: AudioItem) => Record<string, unknown>;
  getAudioMeta: (mediaData: AudioItem) => AudioMeta;
  rewind: () => void;
  fastForward: () => void;
  toggle: () => void;
  playerLayout: () => void;
  toggleOptions: (type: OptionsType) => void;
  setPlayBackRate: (rate: number) => void;
  shareUrl: (type: string) => void;
  nativeShare: () => void;
  initializePlayer: (id: string) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AudioPlayerPlaylist,
    Player,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    ads: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      doLoadMore: false,
      activeEpisodeId: '',
      containerId: `media-widget-${this.type}-${this.data.attrs.id}`,
      isPLayed: false,
      audioData: null,
      trackChanged: false,
      audioMeta: {} as AudioMeta,
      playlist: [],
      player: null,
      showModal: false,
      playBackRate: 1,
      playbackRates: config.player.playbackRates,
      optionsType: 'rate',
      elapsedTime: '00:00',
      sharing: {
        facebook: 'https://www.facebook.com/sharer/sharer.php',
        twitter: 'https://twitter.com/share',
      },
    };
  },
  computed: {
    toggleIcon() {
      return this.isPLayed ? 'toggle-pause' : 'toggle-play';
    },
    toggleClass() {
      return this.isPLayed ? 'audio-player__control--pause' : 'audio-player__control--play';
    },
  },
  methods: {
    handleTrackChange(id) {
      this.trackChanged = true;
      this.isPLayed = false;
      let index;
      this.playlist.forEach((item, i) => {
        if (item.id === id) {
          index = i;
        }
      });
      if (typeof index === 'number' && index >= 0) {
        const item = this.playlist[index];
        if (item) {
          this.activeEpisodeId = item.id;
          this.audioMeta.title = item?.metadata.title || '';

          const settings = this.setPlayerSettings(item);
          this.mountPlayer(settings, item, true);
          // this.player?.playlistItem(index);
          this.isPLayed = true;
        }
      }
    },
    getAudioMeta(mediaData) {
      let credit = this.data?.attrs.metadata?.credit || mediaData?.metadata.credit || '';
      const title = this.data?.attrs.metadata?.title || mediaData?.metadata.title || '';
      const pic = mediaData.metadata.audioPreviewImage?.id ? `${config.api.mediaApiUrl}/${mediaData.metadata.audioPreviewImage?.id}.jpg?w=172&h=172` : null;
      if (this.playlist.length > 0) {
        credit = mediaData?.podcasts?.items[0]?.metadata.title || credit;
      }

      return { credit, title, pic };
    },
    setPlayerSettings(mediaData) {
      const metadata = mediaData?.metadata;
      const file = mediaData.audioData?.audioPlaylistLocation;
      const advertising = playerAds('audio', metadata, this.ads);

      return {
        preload: 'auto',
        width: '100%',
        height: 1,
        volume: 50,
        mute: false,
        title: this.audioMeta.title,
        file,
        playbackRateControls: true,
        advertising,
        /*  playlist: this.playlist.map(item => {
          return {
            title: item.metadata.title,
            file: item.audioData?.audioPlaylistLocation,
          };
        }), */
        intl: {
          en: {
            advertising: {
              cuetext: '',
            },
          },
        },
      };
    },
    mountPlayer(settings, mediaData, startPlaying?: boolean) {
      const player = new PortalPlayer(this.containerId, settings, mediaData);
      if (player instanceof PortalPlayer) {
        player.on('player-ready', (jwplayer) => {
          player.setLayout(this.data.attrs.type);
          this.player = jwplayer;
          this.playerLayout();

          if (this.player) {
            this.playBackRate = this.player.getPlaybackRate();
            this.player.on('playbackRateChanged', (data) => {
              this.playBackRate = data.playbackRate;
            });
          }
        });
        if (startPlaying) {
          this.player?.play();
        }
      }
    },
    playerLayout() {
      const playerContainer = this.player?.getContainer() as HTMLElement;
      const buttonContainer = playerContainer?.querySelector('.jw-controlbar');
      const elapsedTime = buttonContainer?.querySelector('.jw-text-elapsed') as HTMLElement;
      elapsedTime.innerText = '00:00';
      const durationTime = buttonContainer?.querySelector('.jw-text-duration') as HTMLElement;
      durationTime.innerText = '00:001';
      const sliderBar = this.$refs.controlBar as HTMLElement;

      if (this.trackChanged) {
        const currentDuration = sliderBar.querySelector('.jw-text-duration:last-child');
        if (currentDuration) {
          currentDuration.remove();
        }
        const currentElapsedTime = sliderBar.querySelector('.jw-text-elapsed:first-child');
        if (currentElapsedTime) {
          currentElapsedTime.remove();
        }
        const currentSlider = sliderBar.querySelector('.jw-slider-time:first-child');
        if (currentSlider) {
          currentSlider.remove();
        }
      }

      sliderBar.appendChild(durationTime);

      playerContainer.style.height = '0px';
      this.elapsedTime = elapsedTime.innerHTML;

      this.player?.on('seeked', () => {
        this.player?.play();
        this.isPLayed = true;
      });
      this.player?.on('adPlay', (data) => {
        if (data.oldstate === 'buffering') {
          const slider = buttonContainer?.querySelector('.jw-slider-time') as HTMLElement;
          slider.classList.add('jw-slider-time--ads-slider');
          sliderBar.prepend(slider);
        }
        this.isPLayed = true;
      });
      this.player?.on('firstFrame', () => {
        const slider = buttonContainer?.querySelector('.jw-slider-time') as HTMLElement;
        slider.classList.remove('jw-slider-time--ads-slider');
        sliderBar.prepend(slider);
      });
      this.player?.on('adTime', () => {
        this.elapsedTime = elapsedTime.innerHTML;
      });
      this.player?.on('time', () => {
        this.elapsedTime = elapsedTime.innerHTML;
      });
      this.player?.on('adComplete', () => {
        this.elapsedTime = elapsedTime.innerHTML;
      });
      this.player?.on('complete', () => {
        this.isPLayed = false;
      });
      /*
      this.player?.on('playlistItem', item => {
        if (this.trackChanged) {
          this.activeEpisodeId = this.playlist[item.index].id;
        } else {
          this.trackChanged = true;
        }
        if (item.index >= 3) {
          this.doLoadMore = true;
        }
      });
      */
    },
    rewind() {
      const position = this.player?.getPosition();
      if (position) {
        const endPosition = position - 15 >= 0 ? position - 15 : 0;
        this.player?.seek(endPosition);
      }
    },
    fastForward() {
      this.player?.seek(this.player.getPosition() + 15);
    },
    toggle() {
      this.isPLayed ? this.player?.pause() : this.player?.play();
      this.isPLayed = !this.isPLayed;
    },
    nativeShare() {
      const url = window.location.href;
      const title = window.document.title;
      const text = window.document.querySelector('meta[name="description"]')?.getAttribute('content') || '';
      if (window.navigator.share) {
        window.navigator.share({
          title,
          text,
          url,
        });
      } else if (window.AndroidWebAppMobileAPI && window.AndroidWebAppMobileAPI.sharePage) {
        window.AndroidWebAppMobileAPI.sharePage(url, title, text);
      } else {
        this.showModal = !this.showModal;
      }
    },
    toggleOptions(type) {
      this.optionsType = type;
      if (type === 'sharing') {
        this.nativeShare();
      } else {
        this.showModal = !this.showModal;
      }
    },
    setPlayBackRate(rate) {
      this.player!.setPlaybackRate(rate);
      this.showModal = false;
    },
    shareUrl(type) {
      switch (type) {
        case 'link':
          copyLink(window.location.href);
          break;
        case 'facebook':
          // eslint-disable-next-line no-case-declarations
          const urlFb = `${this.sharing.facebook}?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`;
          popupWindow(urlFb);
          break;
        case 'twitter':
          // eslint-disable-next-line no-case-declarations
          const urlTw = `${this.sharing.twitter}?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`;
          popupWindow(urlTw);
          break;
      }
    },
    async initializePlayer(id: string) {
      const res = await getAudio({ id });

      if (!res?.audio.items[0]) {
        return;
      }

      this.audioData = res.audio.items[0];
      const podcastEpisodes = this.audioData.podcasts?.items[0]?.episodes.items || [];

      // If podcasts exist and more than one episode
      if (podcastEpisodes.length > 0) {
        this.activeEpisodeId = this.audioData.id;
        this.playlist = podcastEpisodes;
      }

      this.audioMeta = this.getAudioMeta(this.audioData);
      const settings = this.setPlayerSettings(this.audioData);
      this.mountPlayer(settings, this.audioData);
    },
  },
  watch: {
    playBackRate: function (val, oldVal) {
      if (val !== oldVal) {
        this.setPlayBackRate(val);
      }
    },
  },
  async mounted() {
    if (!this.data.attrs.id) {
      return false;
    } else {
      await this.initializePlayer(this.data.attrs.id);
    }
  },
});
