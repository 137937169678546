
import { PropType } from 'vue';

import { Fragment } from './Fragment';

import { FragmentContent } from '@core/types/fragment';

import { getPortalCoreSettings } from '@core/utils/initSettings';

interface PulloutContent extends FragmentContent {
  attrs: FragmentContent['attrs'] & {
    author: string;
    text: {
      html: string;
    };
  };
}

const fragment = new Fragment();
fragment.setRequiredAttrs(['alignment']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentPullout',
  props: {
    content: {
      type: Object as PropType<PulloutContent>,
      required: true,
    },
  },
  data() {
    return {
      author: this.content.attrs?.author || null,
      text: this.content.attrs?.text?.html || null,
    };
  },
  computed: {
    classModifier(): Record<string, boolean> {
      const { pullout } = getPortalCoreSettings('component');

      return {
        [`fragment-pullout--image-position-${pullout.icon.position}`]: pullout.icon.enabled,
        [`fragment-pullout--primary-color`]: pullout.primaryColor && !this.author,
        [`fragment-pullout--quote`]: !!this.author,
      };
    },
    iconSettings() {
      return getPortalCoreSettings('component').pullout.icon;
    },
  },
});
