
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType27';
import Stories from './Stories.vue';
import StoryModal from './StoryModal.vue';
import { ProcessedStory } from '@headlines/types/Story';

const defaultSettings = {
  data: {
    stories: [],
  },
};

interface Data {
  isModalActive: boolean;
  modalSettings: {
    startPosition: number;
    offsetX: number;
    offsetY: number;
  };
  processedStories: ProcessedStory[] | null;
  localStorageKey: string;
  upodateKey: number;
}

interface Methods {
  toggleModal: (toggle: boolean, modalSettings?: Data['modalSettings']) => void;
  processStories: () => Data['processedStories'];
  markSeen: (id: string) => void;
  isSeen: (id: string) => boolean;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, Methods, unknown, unknown>({
  components: {
    Stories,
    StoryModal,
  },
  data() {
    return {
      isModalActive: false,
      modalSettings: {
        startPosition: 0,
        offsetX: 0,
        offsetY: 0,
      },
      processedStories: null,
      localStorageKey: 'story-seen-items',
      upodateKey: Date.now(),
    };
  },
  methods: {
    toggleModal(toggle, modalSettings) {
      if (modalSettings) {
        this.modalSettings = modalSettings;
      }

      if (!toggle) {
        this.processedStories = this.processStories();
        this.upodateKey++;
      }

      this.isModalActive = toggle;
    },
    isSeen(id) {
      return localStorage.getItem(this.localStorageKey)?.includes(id) ?? false;
    },
    processStories() {
      // @ts-ignore-next-line
      const stories = this.data.stories as ProcessedStory[];

      if (!stories) {
        return null;
      }

      for (const story of stories) {
        story.isSeen = this.isSeen(String(story.updatedTime));
      }

      stories.sort((a, b) => Number(a.isSeen) - Number(b.isSeen));

      return stories;
    },
    markSeen(id) {
      if (this.isSeen(id)) {
        return;
      }

      localStorage.setItem(this.localStorageKey, `${localStorage.getItem(this.localStorageKey) || ''}${id},`);
    },
  },
  mounted() {
    this.processedStories = this.processStories();
  },
});
