
import Vue from 'vue';
import VideoPlayer from '@media/components/video/VideoPlayer.vue';

type widgetType = 'video' | 'stream';
interface Props {
  content: {
    attrs: any;
  };
  type: widgetType;
}

interface Computed {
  getKeywords: string[];
  adFree: boolean;
  ads: {
    adFree: boolean;
    keywords: string[];
  };
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    VideoPlayer,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    type: {
      type: String as () => widgetType,
      required: false,
      default: 'video',
    },
  },
  computed: {
    getKeywords() {
      return this.$store.getters['adForm/getAdsKeywords'];
    },
    adFree() {
      return this.$store.state.piano.access.adFree || false;
    },
    ads() {
      return {
        adFree: this.adFree,
        keywords: this.getKeywords,
      };
    },
  },
});
