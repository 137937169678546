import { Script, Style, Link, Settings, cxenseMeta, zlickMeta, ArticleMeta, ImageData } from '@root/common/types/meta';

// Set default meta tags based on channel settings
class Base {
  protected _siteName: string;
  protected _frontpage: string;
  protected _title: string;
  protected _ogTitle = '';
  protected _description: string;
  protected _ogDescription = '';
  protected _keywords: string;
  protected _baseUrl: string;
  protected _url: string;
  protected _imageData: ImageData;
  protected _style: Style[];
  protected _link: Link[];
  protected _articleMeta: (cxenseMeta | zlickMeta)[];
  protected _path: string;
  protected _script: Script[];

  constructor(settings: Settings, path = '') {
    const { name, frontpage, title, description, baseUrl, imageData, keywords } = settings;

    this._siteName = name;
    this._frontpage = frontpage;
    this._title = title;
    this._ogTitle = title;
    this._description = description;
    this._ogDescription = description;
    this._keywords = keywords;
    this._baseUrl = baseUrl;
    this._url = baseUrl + path;
    this._imageData = imageData;
    this._style = [];
    this._link = [];
    this._articleMeta = [];
    this._path = path;
    this._script = [];
  }
}

// Set OG meta tags - used for sharing
class Og extends Base {
  protected _type: 'website' | 'article' = 'website';

  get imageData() {
    return this._imageData;
  }

  set imageData({ src, width, height }: ImageData) {
    this._imageData = {
      src,
      width,
      height,
    };
  }

  get type() {
    return this._type;
  }

  set type(type: 'website' | 'article') {
    this._type = type;
  }

  get OgMeta() {
    return [
      { hid: 'og_type', property: 'og:type', content: this._type },
      { hid: 'og_url', property: 'og:url', content: this._url },
      { hid: 'og_site_name', property: 'og:site_name', content: this._siteName },
      {
        hid: 'og_title',
        property: 'og:title',
        content: this._ogTitle,
      },
      { hid: 'og_description', property: 'og:description', content: this._ogDescription },
      { hid: 'og_image', property: 'og:image', content: this._imageData.src },
      { hid: 'og_image_width', property: 'og:image:width', content: String(this._imageData.width) },
      { hid: 'og_image_height', property: 'og:image:height', content: String(this._imageData.height) },
    ];
  }
}

// Set Twitter meta tags
class Twitter extends Og {
  get TwitterMeta() {
    return [
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:title', name: 'twitter:title', content: this._ogTitle },
      { hid: 'twitter:description', name: 'twitter:description', content: this._ogDescription },
      { hid: 'twitter:image', name: 'twitter:image', content: this._imageData.src },
    ];
  }
}

// Set cXense meta tags - used for collection data
class Cxense extends Twitter {
  public createArticleMeta({ id, publishAt, primaryCategory, lang, paywallEnabled, authors }: ArticleMeta) {
    const { parentCategory } = primaryCategory;
    const articleSection = [this._siteName];
    parentCategory.name && articleSection.push(parentCategory.name);
    primaryCategory.name && articleSection.push(primaryCategory.name);

    const author = authors && authors.items.length ? authors.items.map((author) => author.name).join(',') : this._siteName;

    const zlickMeta: zlickMeta[] = [{ hid: 'article:section', name: 'article:section', content: articleSection.join(', ') }];
    const cxenseMeta: cxenseMeta[] = [
      { hid: 'cXenseParse:pageclass', name: 'cXenseParse:pageclass', content: 'article' },
      { hid: 'cXenseParse:recs:articleid', name: 'cXenseParse:recs:articleid', content: String(id) },
      { hid: 'cXenseParse:recs:publishtime', name: 'cXenseParse:recs:publishtime', content: String(publishAt) },
      { hid: 'cXenseParse:recs:category', name: 'cXenseParse:recs:category', content: primaryCategory.name || '' },
      { hid: 'cXenseParse:eks-pagetype', name: 'cXenseParse:eks-pagetype', content: 'news' },
      { hid: 'cXenseParse:recs:eks-site', name: 'cXenseParse:recs:eks-site', content: this._siteName },
      {
        hid: 'cXenseParse:eks-article-section',
        name: 'cXenseParse:eks-article-section',
        content: articleSection.join(', '),
      },
      { hid: 'cXenseParse:eks-article-source', name: 'cXenseParse:eks-article-source', content: this._siteName },
      { hid: 'cXenseParse:eks-article-section-id', name: 'cXenseParse:eks-article-section-id', content: String(primaryCategory.id) },
      { hid: 'cXenseParse:eks-content-language', name: 'cXenseParse:eks-content-language', content: lang },
      { hid: 'cXenseParse:author', name: 'cXenseParse:author', content: author },
    ];
    this._articleMeta = [...zlickMeta, ...cxenseMeta];

    if (paywallEnabled) {
      this._articleMeta.push({ hid: 'robots-2', name: 'robots', content: 'noarchive' });
    }
  }
}
// Create meta tags for nuxt
export default class Meta extends Cxense {
  setTitle({ title, setOgTitle = true }: { title: string; setOgTitle?: boolean }) {
    this.title = title;

    if (setOgTitle) {
      this.ogTitle = title;
    }
  }

  get title(): string {
    return this._title;
  }

  set title(pageTitle: string) {
    this._title = this._path === this._frontpage ? pageTitle : `${pageTitle} - ${this._siteName}`;
  }

  get ogTitle(): string {
    return this._ogTitle;
  }

  set ogTitle(ogTitle: string) {
    this._ogTitle = ogTitle;
  }

  get url(): string {
    return this._url;
  }

  set url(path: string) {
    this._url = this._baseUrl + path;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get ogDescription(): string {
    return this._ogDescription;
  }

  set ogDescription(ogDescription: string) {
    this._ogDescription = ogDescription;
  }

  get keywords(): string {
    return this._keywords;
  }

  set keywords(keywords: string) {
    this._keywords = keywords;
  }

  get style(): Style[] {
    return this._style;
  }

  set style(styles: Style[]) {
    styles.forEach(({ cssText, hid }) => {
      this._style.push({
        cssText,
        type: 'text/css',
        hid,
      });
    });
  }

  get link(): Link[] {
    this.link = [{ rel: 'canonical', hid: 'canonical', href: `${this._url}` }];
    return this._link;
  }

  set link(links: Link[]) {
    links.forEach(({ rel, hid, href }) => {
      this._link.push({ rel, href, hid });
    });
  }

  get script(): Script[] {
    this.script = [{ type: 'text/javascript', json: null }];
    return this._script;
  }

  set script(scripts: Script[]) {
    scripts.forEach((script) => {
      if (script.src || script.json) {
        this._script.push(script);
      }
    });
  }

  get data() {
    return [
      { hid: 'description', name: 'description', content: this._description },
      { hid: 'keywords', name: 'keywords', content: this._keywords },
      { hid: 'robots', name: 'robots', content: 'max-snippet:160, max-image-preview:large, max-video-preview:60' },
      ...this.OgMeta,
      ...this.TwitterMeta,
      ...this._articleMeta,
    ];
  }
}
