
import Vue from 'vue';
import { ImageItem } from '@gallery/types/Gallery';
import buildPictureUrl from '@gallery/utils/buildPictureUrl';

interface Props {
  picture: ImageItem;
  isActive: boolean;
  isLightboxed: boolean;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    picture: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    isLightboxed: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    buildPictureUrl,
  },
});
