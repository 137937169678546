export default {
  play: 'Groti',
  pause: 'Pauzė',
  nextUp: 'Kitas video',
  related: 'Susiję video',
  errors: {
    cantLoadPlayer: 'Atsiprašome, nepavyko įkelti vaizdo įrašų grotuvo, atnaujinkite puslapį arba bandykite dar kartą vėliau.',
    liveStreamDown: 'Tiesioginė transliacija šiuo metu nepasiekiama',
    cantPlayInBrowser: 'Jūsų naršyklė nepalaiko mūsų vaizdo įrašo atkūrimo. <br>Jei įmanoma, išbandykite kitą žiniatinklio naršyklę.',
  },
  settings: 'Nustatymai',
  fullscreen: 'Per visą ekraną',
  exitFullscreen: 'Išeikite iš viso ekrano',
  sharing: {
    heading: 'Dalintis',
  },
  advertising: {
    cuetext: '',
    displayHeading: 'Video algab pärast reklaami',
    loadingAd: 'Įkeliama reklama',
    skipmessage: 'Praleisti reklamą xx',
    skiptext: 'Praleisti reklamą',
    admessage: 'Reklama baigsis po xx sekundžių...',
    podmessage: 'Reklama __AD_POD_CURRENT__/__AD_POD_LENGTH__',
  },
};
