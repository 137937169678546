
import BaseButton from '@core/components/UI/BaseButton.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType11';

const defaultSettings = {
  icons: true,
  titleComment: false,
  backgroundColor: 'var(--color-grey-3)',
  accentColor: 'var(--color-color-1)',
  type: 'list',
};

interface Computed {
  cssVars: Record<string, string>;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, unknown, Computed, unknown>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    BaseButton,
  },
  computed: {
    cssVars() {
      return {
        '--block-type-11-accent-color': this.getSettings.accentColor,
        '--block-type-11-background-color': this.getSettings.backgroundColor,
      };
    },
  },
});
