
import Vue from 'vue';

type HTMLIngredients = {
  count: number | null;
  group: string | null;
  name: string | null;
  unit: string | null;
};

type DataIngredients = {
  groupName: string | null;
  items: HTMLIngredients[];
};

interface Props {
  content: {
    html: {
      ingredients: HTMLIngredients[];
    };
  };
}

interface Data {
  ingredients: DataIngredients[];
  info: any;
  icons: Record<string, string>;
}

interface Methods {
  getIngredients: () => DataIngredients[];
  getInfo: () => any;
}

interface Computed {
  IsEmpty: boolean;
}

const icons = {
  meal_time: 'meal-time',
  preparation_time: 'preparation-time',
  portions: 'portions',
  categories: 'categories',
};

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ingredients: [],
      info: {},
      icons,
    };
  },
  computed: {
    IsEmpty() {
      return Object.keys(this.info).length === 0 && this.info.constructor === Object;
    },
  },
  created() {
    this.ingredients = this.getIngredients();
    this.info = this.getInfo();
  },
  methods: {
    getIngredients() {
      const ingredients: DataIngredients[] = [];
      const { html } = this.content;
      // Create ingredients array with items in correct group.
      html.ingredients.forEach((ingredient) => {
        const index = ingredients.findIndex((obj) => obj.groupName === ingredient.group);

        // If no group is found, then create a new one.
        if (index === -1) {
          ingredients.push({ groupName: ingredient.group, items: [ingredient] });
          return;
        }
        // Push item to correct group.
        ingredients[index].items.push(ingredient);
      });

      return ingredients;
    },
    getInfo() {
      const html = this.content.html as Record<string, any>;
      let info = {};
      Object.keys(html).forEach((key) => {
        // Filter out empty arrays.
        // Filter out 'ingredients' array.
        // @ts-ignore
        if (!html[key] || (typeof html[key] === 'object' && !html[key].length > 0) || key === 'ingredients' || key === '__typename') {
          return;
        }
        // Add right type unit if it's missing.
        switch (key) {
          case 'preparation_time':
            info = { ...info, ...{ [key]: `${html[key]} min` } };
            break;
          case 'portions':
            info = { ...info, ...{ [key]: `${html[key]} portsjonit` } };
            break;
          default:
            info = { ...info, ...{ [key]: html[key] } };
            break;
        }
      });

      return info;
    },
  },
});
