import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import BlockType19 from '@headlines/components/block/11-20/block-type-19/BlockType19.vue';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType19: AdminBlockType = {
  component: BlockType19,
  attributes: AdminBlockTypeAttributes({
    type: 'list',
  }),
  articlesCount: 0,
};

export default AdminBlockType19;
