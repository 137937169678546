
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { LinkData } from '@root/modules/navigation/types/navigation';

interface Props {
  link: LinkData;
  fakeLink: boolean;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  inject: {
    components: {
      default: {
        LinkHandler,
      },
    },
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    fakeLink: {
      type: Boolean,
      default: false,
    },
  },
});
