
import Vue from 'vue';
import { FragmentContent } from '@core/types/fragment';
import FragmentCookingProcess from '@core/components/fragments/FragmentCookingProcess.vue';
import FragmentIngredients from '@core/components/fragments/FragmentIngredients.vue';

type Content = {
  html: unknown;
  type: string;
  renderTag: string;
};

interface Props {
  content: {
    type: string;
    content: {
      body: {
        content: FragmentContent[];
      };
    };
    recipe: {
      value: {
        ingredients: Record<string, unknown>;
      };
    }[];
  };
  section: string;
}

interface Methods {
  getContent: () => Content | null;
}

interface Data {
  fragmentContent: Content | null;
}

export default Vue.extend<Data, unknown, Methods, Props>({
  components: {
    FragmentCookingProcess,
    FragmentIngredients,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fragmentContent: null,
    };
  },
  created() {
    this.fragmentContent = this.getContent();
  },
  methods: {
    getContent() {
      const article = this.content;
      const section = this.section;

      let cookingProcess: FragmentContent | null = null;

      article.content.body.content.forEach((obj) => {
        // TODO:(content-api-v3) fix types
        // @ts-ignore
        if (obj.type === 'sidebar' && obj.attrs.body.type === 'ordered_list') {
          // @ts-ignore
          cookingProcess = obj.attrs.body.html;
        }
      });

      const recipe = article.recipe && article.recipe.length > 0 && article.recipe[0].value;
      const ingredients = recipe && recipe.ingredients;

      let content: Content | null = null;

      // Do not include component if data is empty.
      // @ts-ignore
      if ((this.section === 'cookingProcess' && !cookingProcess) || (this.section === 'ingredients' && !(ingredients.length > 0))) {
        return content;
      }

      // Create component.
      switch (section) {
        case 'cookingProcess':
          content = {
            html: cookingProcess,
            type: 'recipe_cooking_process',
            renderTag: 'FragmentCookingProcess',
          };
          break;
        case 'ingredients':
          content = {
            html: { ingredients },
            type: 'recipe_ingredients',
            renderTag: 'FragmentIngredients',
          };
          break;
        default:
          break;
      }
      return content;
    },
  },
});
