import BlockType3Component from '@headlines/components/block/1-10/block-type-3/BlockType3.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType3: AdminBlockType = {
  component: BlockType3Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    lead: false,
    type: 'list',
  }),
  articlesCount: 3,
};

export default AdminBlockType3;
