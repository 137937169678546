
import Vue from 'vue';
import Alert from './Alert.vue';
import { AlertComponentProps } from './alert';

interface ProcessedAlert extends AlertComponentProps {
  isActive: boolean;
  id: number;
}

interface Data {
  processedAlerts: ProcessedAlert[];
}

interface Methods {
  closeAlert(id: number): void;
}

interface Computed {
  activeAlerts: ProcessedAlert[];
}

interface Props {
  alerts: AlertComponentProps[];
}

// It is necessary to create proper keys for template loop
// proper keys are important for transition in alert component
let alertUniqueId = 0;
export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Alert,
  },
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  watch: {
    alerts: {
      handler(alertList: AlertComponentProps[]) {
        const processedAlerts = alertList.map((alert) => ({ ...alert, isActive: true, id: ++alertUniqueId }));

        if (Array.isArray(processedAlerts) && processedAlerts.length > 0) {
          const newItemsInArray = Math.max(alertList.length - this.processedAlerts.length, 1);
          const arrayToPush = processedAlerts.slice(-newItemsInArray);
          this.processedAlerts.push(...arrayToPush);
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeAlert(id) {
      this.processedAlerts = this.processedAlerts.map((alert) => {
        if (alert.id === id) {
          alert.isActive = false;
        }
        return alert;
      });
    },
  },
  computed: {
    activeAlerts() {
      return this.processedAlerts.filter((alert) => alert.isActive);
    },
  },
  data() {
    return {
      processedAlerts: [],
    };
  },
});
