const navigationCustomCss = (css: string, uniqueSelector: string) => {
  let styles = '';
  let find = /.header-menu /g;
  styles = css.replace(new RegExp(find), `${uniqueSelector}.header-menu `);

  find = /.header-menu__/g;
  styles = css.replace(new RegExp(find), `${uniqueSelector} .header-menu__`);

  return styles;
};

export default navigationCustomCss;
