export interface Settings {
  script: string;
  attr: Record<string, unknown>;
  parentElement?: HTMLElement;
}

export function appendChild(element: HTMLElement | null, child: HTMLScriptElement) {
  if (!element || !element.appendChild) {
    return;
  }
  element.appendChild(child);
}

export const loadScript = (settings: Settings) => {
  return new Promise<void>((resolve) => {
    const attr = settings.attr;
    const parentElement = settings.parentElement || document.getElementsByTagName('head')[0];

    const scriptEl = document.createElement('script');
    scriptEl.src = settings.script;
    scriptEl.type = 'text/javascript';
    if (attr) {
      Object.keys(attr).forEach((val) => {
        (scriptEl as any)[val] = attr[val];
      });
    }

    // Attach script to head
    appendChild(parentElement, scriptEl);
    // Wait for tag to load before promise is resolved
    scriptEl.addEventListener('load', () => {
      resolve();
    });
  });
};
