
import Vue from 'vue';
import { isMobileOrTablet } from '@ads/utils/adx';

interface Data {
  isDisabled: boolean;
  divId: string;
  cookieName: string;
}

interface Methods {
  close: () => void;
  getDivId: () => string;
  goToUrl: (url: string) => void;
}

interface Props {
  settings: Array<Record<string, any>>;
}

interface Computed {
  banner: Record<string, any>;
  isMobileOrTablet: boolean;
}

interface AdsWindow extends Window {
  crypto: any;
}

interface Process {
  client: boolean;
}

declare const window: AdsWindow;
declare let process: Process;

const getAdBlockComponent = () => {
  return Vue.component('AdBlockComponent', {
    name: 'AdBlockComponent',
    props: {
      tag: String,
    },
    functional: true,
    render(h, context) {
      Vue.config.ignoredElements.push(context.props.tag);
      return h(context.props.tag, context.data, context.children);
    },
  });
};

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    adBlockComponent: getAdBlockComponent(),
  },
  props: {
    settings: {
      type: Array,
      default: () => [{}],
      required: true,
    },
  },
  data() {
    return {
      isDisabled: false,
      divId: 'ad',
      cookieName: '',
    };
  },
  computed: {
    banner() {
      const rnd = Math.floor(Math.random() * 2);
      return this.settings.length > 1 ? this.settings[rnd] : this.settings[0];
    },
    isMobileOrTablet() {
      return isMobileOrTablet();
    },
  },
  created() {
    this.divId = this.getDivId();

    if (this.banner.type === 'float') {
      this.cookieName = this.banner.cookieName;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (process.client) {
        // Float ads are cookie limited
        if (this.banner.type === 'float') {
          if (window.document.cookie.indexOf(this.cookieName) === -1) {
            const expires = new Date(new Date().getTime() + 12 * 60 * 60 * 1000).toUTCString();
            window.document.cookie = `${this.cookieName}=1;expires=${expires};path=/`;
          } else {
            this.isDisabled = true;
          }
        }

        // Do not show desktop ads in mobile and vice versa
        if (this.isMobileOrTablet && !this.banner.mobile) {
          this.isDisabled = true;
        } else if (!this.isMobileOrTablet && this.banner.mobile) {
          this.isDisabled = true;
        }
      }
    });
  },
  methods: {
    close() {
      if (this.divId) {
        const div = window.document.getElementsByTagName(this.divId);
        div![0].remove();
      }
    },
    getDivId() {
      const str = window.crypto.randomUUID();
      return str.replace(/[0-9-]+/g, '').substring(0, 5);
    },
    goToUrl(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
  },
});
