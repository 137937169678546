
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import SocialLinks from '@root/common/components/base/SocialLinks.vue';
import { Footer } from '@root/modules/navigation/types/navigation';
import rootConfig from '@root/config/root.config';

interface Props {
  navigation: Footer;
}

interface Data {
  rootConfig: Record<string, unknown>;
}

export default Vue.extend<Data, unknown, unknown, Props>({
  components: {
    LinkHandler,
    SocialLinks,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rootConfig,
    };
  },
});
