
import Vue from 'vue';

export default Vue.extend({
  props: {
    alt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animate: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animate = false;
    }, 4000);
  },
});
