
import BaseButton from '@core/components/UI/BaseButton.vue';
import { HeadlineTitle, HeadlineLabels, HeadlineAuthor, HeadlineImage, HeadlineIcons, HeadlineRelatedTitle } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType12';

const defaultSettings = {
  icons: true,
  category: true,
  relatedTitles: true,
  author: false,
  isBorderEnabled: false,
  textColor: 'var(--color-black)',
  accentColor: 'var(--color-color-1)',
  backgroundColor: 'var(--color-grey-3)',
};

interface Computed {
  cssVars: Record<string, string>;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, unknown, Computed, unknown>({
  components: {
    HeadlineImage,
    HeadlineIcons,
    HeadlineTitle,
    HeadlineRelatedTitle,
    HeadlineLabels,
    HeadlineAuthor,
    BaseButton,
  },
  computed: {
    cssVars() {
      return {
        '--block-type-12-text-color': this.getSettings.textColor,
        '--block-type-12-accent-color': this.getSettings.accentColor,
        '--block-type-12-background-color': this.getSettings.backgroundColor,
      };
    },
  },
});
