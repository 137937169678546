import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import { FacebookOptions } from './types/Facebook';

import logger from '@analytics/util/logger';
import loadFacebookScript from './utils/loadFacebookScript';

declare let window: Awindow;

export default class Fb implements Analytics {
  private fbq: Awindow['fbq'];
  private options!: FacebookOptions;

  /**
   * Load script and initialize tracker.
   * Track PageView automatically
   */
  public init(options: FacebookOptions) {
    this.options = options;

    if (!this.options?.pixelId) {
      logger.error('facebook pixel id not provided');
      return;
    }

    this.loadScript();

    if (!window.fbq) {
      logger.error('facebook pixel script does not exists on page');
      return;
    }

    this.fbq = window.fbq;

    if (typeof this.options.consent === 'boolean' && !this.options.consent) {
      this.disableTracking();
    }

    this.fbq('init', this.options.pixelId);

    this.sendCustomEvent('track', 'PageView');
  }

  public enableTracking() {
    this.sendCustomEvent('consent', 'grant');
  }

  public disableTracking() {
    this.sendCustomEvent('consent', 'revoke');
  }

  public sendCustomEvent(
    eventType: string,
    eventName: string,
    parameters?: Record<string, unknown> | null,
  ) {
    if (!this.fbq) {
      logger.error('facebook pixel script does not exists on page');
      return;
    }

    if (!parameters) {
      this.fbq(eventType, eventName);
    } else {
      this.fbq(eventType, eventName, parameters);
    }
  }

  private loadScript() {
    if (!window.fbq) {
      loadFacebookScript();
    }
  }
}
