import { Plugin } from '@nuxt/types';
import { Gtag } from '@analytics/trackers';

const GtagPlugin: Plugin = async (ctx) => {
  const { app } = ctx;
  const analytics = app.$channelConfig('analytics');

  // Check if tracker can be enabled
  if (!analytics?.gtag?.containerId) {
    return;
  }

  // Setup tracker
  const gtag = new Gtag();
  gtag.init({ containerId: analytics.gtag.containerId });
};

export default GtagPlugin;
