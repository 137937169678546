import { Plugin } from '@nuxt/types';

const ArticleViewStatisticsPlugin: Plugin = async (ctx) => {
  const { app, store, $sentry } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.articleStatistics?.url) {
    return;
  }

  const { articleStatistics } = analytics;

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        if (store.state.article.activeArticle.id) {
          new Image().src = `${articleStatistics.url}/${store.state.article.activeArticle.id}`;
        } else {
          console.log('Missing article data in ArticleViewStatistics plugin', { activeArticlePath, activeArticle: store.state.article.activeArticle });
          $sentry.captureException(new Error('Missing article data in ArticleViewStatistics plugin'), {
            contexts: { data: { activeArticlePath, activeArticle: store.state.article.activeArticle } },
            tags: { 'process.type': process.server ? 'server' : 'client' },
          });
        }
      }
    },
    {
      immediate: true,
    }
  );
};

export default ArticleViewStatisticsPlugin;
