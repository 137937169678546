export default {
  PAYWALL_OPEN_DAY: {
    types: {
      view: {
        eventType: 'block_view',
        customParams: '{"BlockName": "Mock Paywall", "ArticleId": "${app.store.state.article.activeArticle.id}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "216d6eeb6eb9d470a55513cbf46429a600d2b6b4"}',
      },
      click: {
        eventType: 'block_click',
        customParams: '{"BlockName": "Mock Paywall", "ArticleId": "${app.store.state.article.activeArticle.id}", "LinkTitle": "${data.linkTitle}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "216d6eeb6eb9d470a55513cbf46429a600d2b6b4"}',
      },
    },
  },
  QUESTIONNAIRE_SUBMIT: {
    types: {
      submit: {
        eventType: 'questionnaire_submit',
        customParams: '{"GscWidgetId": "${data.widgetId}", "GscWidgetAnswer": "${data.rating}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "2f43d4536ca56638c0eebb7a48b0b99e6f5f0d68"}',
      },
    },
  },
  ONSESIGNAL_NOTIFICATION: {
    types: {
      click: {
        eventType: 'pushnotif_opened',
        customParams:
          '{"OneSignalUserId": "${data.oneSignalUserId}", "ArticleId": "${app.store.state.article.activeArticle.id}", "Channel: "${data.channelId}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "216d6eeb6eb9d470a55513cbf46429a600d2b6b4"}',
      },
    },
  },
  PAYWALL_MODAL: {
    types: {
      click: {
        eventType: 'link_click',
        customParams:
          '{"BlockName": "Article Paywall", "BlockType": "Paywall", "ArticleId": "${app.store.state.article.activeArticle.id}", "LinkTitle": "${data.linkTitle}", "ProductPrice": "${data.productPrice}", "SiteName": "${app.$channelConfig("settings").name}", "PageType": "Article"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      view: {
        eventType: 'purchase_completed',
        customParams:
          '{"BlockName": "Article Paywall", "PurchaseType": "${data.purchaseType}", "ArticleId": "${app.store.state.article.activeArticle.id}", "PageType": "Article", "SiteName": "${app.$channelConfig("settings").name}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
};
