
import { HeadlineTitle } from '../../../fragments';
import BaseButton from '@core/components/UI/BaseButton.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { formatCustomDate, isDateBetween, isDateSameOrBefore } from '@core/filters/dateFormater';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType23';
import BlockType23SmallRow from './BlockType23SmallRow.vue';
import BlockType23BigRow from './BlockType23BigRow.vue';
import { Stream } from './types/blockType23';

const defaultSettings = {};

interface Data {
  openedStream: number | null;
  activateStream: boolean;
}

interface Props {
  data: {
    ads: {
      component?: string;
      settings: {
        mid: number;
      };
    };
    texts: {
      read_more: string;
      today: string;
      view_stream: string;
      watch_stream: string;
      on_air: string;
    };
    streams: Stream[];
  };
}

interface Methods {
  setOpenedStream(index: number): void;
  setOpenedAndActiveStream(index: number): void;
  isLive(stream: Stream): boolean;
  getDate(stream: Stream): string;
  getTime(stream: Stream): string;
  isStreamActual(stream: Stream): boolean;
  openFirstLiveStream(): void;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, Methods, unknown, Props>({
  components: {
    HeadlineTitle,
    BaseButton,
    BaseIcon,
    BlockType23SmallRow,
    BlockType23BigRow,
  },
  filters: {
    formatCustomDate,
    isDateBetween,
    isDateSameOrBefore,
  },
  data() {
    return {
      openedStream: null,
      activateStream: false,
    };
  },
  watch: {
    hasChannelSubscription() {
      this.openFirstLiveStream();
    },
  },
  methods: {
    setOpenedStream(index) {
      this.openedStream = index;
    },
    setOpenedAndActiveStream(index) {
      this.activateStream = true;
      this.setOpenedStream(index);
    },
    isLive(stream) {
      return isDateBetween(new Date().toString(), stream.date.start, stream.date.end);
    },
    getDate(stream) {
      if (this.isLive(stream)) {
        return '';
      }

      const startDate = stream.date.start;
      const filter = 'DD.MM';
      const startDayAndMonth = formatCustomDate(startDate, filter);
      const actualDayAndMonth = formatCustomDate(new Date().toDateString(), filter);

      return startDayAndMonth === actualDayAndMonth ? this.data.texts.today : startDayAndMonth;
    },
    getTime(stream) {
      if (this.isLive(stream)) {
        return this.data.texts.on_air;
      }

      const startDate = stream.date.start;
      const filter = 'HH:mm';
      const startTime = formatCustomDate(startDate, filter);

      return startTime;
    },
    isStreamActual(stream) {
      return isDateSameOrBefore(new Date().toString(), stream.date.end);
    },
    openFirstLiveStream() {
      const streams = this.data.streams;

      if (!(streams && streams.length > 0)) {
        return;
      }

      const liveStreams = streams.filter((stream) => this.isLive(stream));

      const firstHighPriorityStreamIndex = streams.findIndex((stream) => stream.show_first);
      const firstHighPriorityStream = streams[firstHighPriorityStreamIndex];

      if (firstHighPriorityStreamIndex > 0 && this.isLive(firstHighPriorityStream)) {
        if ((firstHighPriorityStream.subscribers_only && this.hasChannelSubscription) || !firstHighPriorityStream.subscribers_only) {
          this.setOpenedStream(firstHighPriorityStreamIndex);
        }
      } else if (liveStreams.length > 0) {
        if ((liveStreams[0].subscribers_only && this.hasChannelSubscription) || !liveStreams[0].subscribers_only) {
          this.setOpenedStream(streams.findIndex((stream) => stream === liveStreams[0]));
        }
      }
    },
  },
  mounted() {
    this.openFirstLiveStream();
  },
});
