import BlockType29 from '@headlines/components/block/21-30/BlockType29.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType29: AdminBlockType = {
  component: BlockType29,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: true,
    titleComment: true,
    channel: true,
    category: true,
    lead: false,
    iframe: false,
    embedName: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType29;
