export default {
  width: 'w',
  height: 'h',
  ratio: 'r',
  w: 'w',
  h: 'h',
  r: 'r',
  fx: 'fx',
  fy: 'fy',
  cx: 'cx',
  cy: 'cy',
  cw: 'cw',
  ch: 'ch',
  noup: 'noup',
  rotate: 'rotate',
  flip: 'flip',
};
