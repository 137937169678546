export const thunder = [
  'heavyrainandthunder',
  'heavyrainshowersandthunder',
  'heavysleetandthunder',
  'heavysleetshowersandthunder',
  'heavysnowandthunder',
  'heavysnowshowersandthunder',
  'lightrainandthunder',
  'lightrainshowersandthunder',
  'lightsleetandthunder',
  'lightsnowandthunder',
  'lightssleetshowersandthunder',
  'lightssnowshowersandthunder',
  'rainandthunder',
  'rainshowersandthunder',
  'sleetandthunder',
  'sleetshowersandthunder',
  'snowandthunder',
  'snowshowersandthunder',
];

export const rain = ['heavyrain', 'heavyrainshowers', 'heavyrainshowers', 'rainshowers', 'lightrain', 'lightrainshowers'];

export const snow = [
  'snow',
  'heavysnow',
  'lightsnow',
  'snowshowers',
  'heavysnowshowers',
  'lightsleet',
  'lightsleetshowers',
  'lightsnowshowers',
  'sleet',
  'sleetshowers',
  'heavysleet',
  'heavysleetshowers',
];

export const sun = ['fair_day', 'clearsky_day'];

export const sunCloudy = ['partlycloudy_day'];

export const partlyCloudyNight = ['fair_night'];
