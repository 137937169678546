
import Vue from 'vue';
import { HeadlineLabel } from '@headlines/types/headline';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';

interface Props {
  labels: HeadlineLabel[];
  paid: boolean;
  pill: boolean;
}

// Define dummy filter if process is not defined
if (typeof process === 'undefined') {
  Vue.filter('navigationUrl', (url: string, fakeDomainEnabled: boolean) => {
    if (fakeDomainEnabled) {
      return url;
    }

    return url;
  });
}

interface $nuxtWindow extends Window {
  $nuxt: any;
}

interface Process {
  client: boolean;
  env: any;
}

declare let window: $nuxtWindow;
declare let process: Process;

export default Vue.extend<unknown, unknown, unknown, Props>({
  name: 'HeadlineLabels',
  components: {
    LinkHandler,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    paid: {
      type: Boolean,
      default: false,
      required: false,
    },
    pill: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    fakeDomainEnabled() {
      return typeof process !== 'undefined'
        ? process && process.client
          ? window.$nuxt && window.$nuxt.$config.FAKE_DOMAIN_ENABLED
          : process.env.FAKE_DOMAIN_ENABLED
        : false;
    },
  },
});
