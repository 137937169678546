
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType30';
import BaseButton from '@core/components/UI/BaseButton.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';

const defaultSettings = {};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    LinkHandler,
    BaseButton,
    BaseIcon,
  },
  data() {
    return {
      showSocials: false,
    };
  },
  methods: {
    toggleSocials() {
      this.showSocials = !this.showSocials;
    },
  },
});
