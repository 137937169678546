
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { Headline } from '@headlines/types/headline';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType25';
import chunkArray from '@headlines/utils/ChunkArray';

const defaultSettings = {
  icons: true,
  category: true,
};

interface Computed {
  chunkedItems: Headline[][];
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, unknown, Computed, unknown>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    LinkHandler,
  },
  computed: {
    chunkedItems() {
      return chunkArray(this.data.items, 4);
    },
  },
});
