export default [
  {
    name: 'Delfi',
    external_id: '1',
    domain: 'www.delfi.lt',
    path: 'news',
    url: 'www.delfi.lt',
    locale: 'lt_LT',
    tag_channe: true,
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Vasara',
    external_id: '104',
    domain: 'www.delfi.lt',
    path: 'vasara',
    url: 'www.delfi.lt/vasara',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Video',
    external_id: '107',
    domain: 'www.delfi.lt',
    path: 'video',
    url: 'www.delfi.lt/video',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Orai',
    external_id: '109',
    domain: 'www.delfi.lt',
    path: 'orai',
    url: 'www.delfi.lt/orai',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'En',
    external_id: '111',
    domain: 'www.delfi.lt',
    path: 'en',
    url: 'www.delfi.lt/en',
    locale: 'en_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Sveikata',
    external_id: '112',
    domain: 'www.delfi.lt',
    path: 'sveikata',
    url: 'www.delfi.lt/sveikata',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Letena',
    external_id: '116',
    domain: 'www.delfi.lt',
    path: 'letena',
    url: 'www.delfi.lt/letena',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Krepsinis',
    external_id: '117',
    domain: 'www.delfi.lt',
    path: 'krepsinis',
    url: 'www.delfi.lt/krepsinis',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Fit',
    external_id: '119',
    domain: 'www.delfi.lt',
    path: 'fit',
    url: 'www.delfi.lt/fit',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'M360',
    external_id: '120',
    domain: 'www.delfi.lt',
    path: 'm360',
    url: 'www.delfi.lt/m360',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Multimedija',
    external_id: '121',
    domain: 'www.delfi.lt',
    path: 'multimedija',
    url: 'www.delfi.lt/multimedija',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Stilius',
    external_id: '124',
    domain: 'www.delfi.lt',
    path: 'stilius',
    url: 'www.delfi.lt/stilius',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Užsakomasis turinys',
    external_id: '125',
    domain: 'www.delfi.lt',
    path: 'uzsakomasis-turinys',
    url: 'www.delfi.lt/uzsakomasis-turinys',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Kablys',
    external_id: '126',
    domain: 'www.delfi.lt',
    path: 'kablys',
    url: 'www.delfi.lt/kablys',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Maistas',
    external_id: '130',
    domain: 'www.delfi.lt',
    path: 'maistas',
    url: 'www.delfi.lt/maistas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Keliones',
    external_id: '131',
    domain: 'www.delfi.lt',
    path: 'keliones',
    url: 'www.delfi.lt/keliones',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Būstas',
    external_id: '132',
    domain: 'www.delfi.lt',
    path: 'bustas',
    url: 'www.delfi.lt/bustas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Agronaujienos',
    external_id: '133',
    domain: 'www.delfi.lt',
    path: 'agro',
    url: 'www.delfi.lt/agro',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Moterys',
    external_id: '134',
    domain: 'www.delfi.lt',
    path: 'moterys',
    url: 'www.delfi.lt/moterys',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Šeima',
    external_id: '135',
    domain: 'www.delfi.lt',
    path: 'seima',
    url: 'www.delfi.lt/seima',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Karjera',
    external_id: '137',
    domain: 'www.delfi.lt',
    url: 'www.delfi.lt/darbas',
    path: 'darbas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Miestai',
    external_id: '138',
    domain: 'www.delfi.lt',
    path: 'miestai',
    url: 'www.delfi.lt/miestai',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Kultūra',
    external_id: '140',
    domain: 'www.delfi.lt',
    path: 'kultura',
    url: 'www.delfi.lt/kultura',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Plius',
    external_id: '141',
    domain: 'www.delfi.lt',
    path: 'plius',
    url: 'www.delfi.lt/plius',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Verslo požiūris',
    external_id: '142',
    domain: 'www.delfi.lt',
    path: 'verslo-poziuris',
    url: 'www.delfi.lt/verslo-poziuris',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Tvari Lietuva',
    external_id: '143',
    domain: 'www.delfi.lt',
    path: 'tvarilietuva',
    url: 'www.delfi.lt/tvarilietuva',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Login',
    external_id: '144',
    domain: 'www.delfi.lt',
    path: 'login',
    url: 'www.delfi.lt/login',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Verslas plius',
    external_id: '145',
    domain: 'www.delfi.lt',
    path: 'verslasplius',
    url: 'www.delfi.lt/verslasplius',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: '(Ne)matomi',
    external_id: '147',
    domain: 'www.delfi.lt',
    path: 'nematomi',
    url: 'www.delfi.lt/nematomi',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Saugu',
    external_id: '148',
    domain: 'www.delfi.lt',
    path: 'saugu',
    url: 'www.delfi.lt/saugu',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'RU.Delfi',
    external_id: '300',
    domain: 'www.delfi.lt',
    url: 'www.delfi.lt/ru',
    path: 'ru',
    locale: 'ru_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Gyvenimas',
    external_id: '400',
    domain: 'www.delfi.lt',
    path: 'gyvenimas',
    url: 'www.delfi.lt/gyvenimas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Auto',
    external_id: '600',
    domain: 'www.delfi.lt',
    path: 'auto',
    url: 'www.delfi.lt/auto',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Projektai',
    external_id: '800',
    domain: 'www.delfi.lt',
    path: 'projektai',
    url: 'www.delfi.lt/projektai',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Sportas',
    external_id: '903',
    domain: 'www.delfi.lt',
    path: 'sportas',
    url: 'www.delfi.lt/sportas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Delfi kartu',
    external_id: '904',
    domain: 'www.delfi.lt',
    path: 'kartu',
    url: 'www.delfi.lt/kartu',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Grynas',
    external_id: '905',
    domain: 'www.delfi.lt',
    path: 'grynas',
    url: 'www.delfi.lt/grynas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Veidai',
    external_id: '906',
    domain: 'www.delfi.lt',
    path: 'veidai',
    url: 'www.delfi.lt/veidai',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Verslas',
    external_id: '907',
    domain: 'www.delfi.lt',
    path: 'verslas',
    url: 'www.delfi.lt/verslas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Mokslas',
    external_id: '908',
    domain: 'www.delfi.lt',
    path: 'mokslas',
    url: 'www.delfi.lt/mokslas',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'PL.Delfi',
    external_id: '912',
    domain: 'www.delfi.lt',
    path: 'pl',
    url: 'www.delfi.lt/pl',
    locale: 'pl_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Visi keliai',
    external_id: '913',
    domain: 'www.delfi.lt',
    path: 'visikeliai',
    url: 'www.delfi.lt/visikeliai',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Aktualijos',
    external_id: '1000',
    domain: 'www.delfi.lt',
    path: 'aktualijos',
    url: 'www.delfi.lt/aktualijos',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Laisvalaikis',
    external_id: '1001',
    domain: 'www.delfi.lt',
    path: 'laisvalaikis',
    url: 'www.delfi.lt/laisvalaikis',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
  {
    name: 'Kalėdos',
    external_id: '120000005',
    domain: 'www.delfi.lt',
    path: 'kaledos',
    url: 'www.delfi.lt/kaledos',
    locale: 'lt_LT',
    old_type_redirects: false,
    old_type_redirects_dev: false,
  },
];
