export default {
  ANALYTICS_PORTAL_PAYWALL: {
    tracker: {
      uid: 'UA-48822571-1',
      name: 'pcc',
    },
    types: {
      initial: {
        category: 'portal-paywall',
        action: 'dialogOpenCreated',
        label: 'article: ${app.store.state.article.activeArticle.id}; channel: ${app.$channelConfig("settings").externalId}',
      },
      form: {
        category: 'TasuliseArtikliLigipääs',
        action: 'ArtikliOst',
        label: '${window.location.href}',
      },
      selfService: {
        category: 'TasuliseArtikliLigipääs',
        action: 'Püsitellimus',
        label: '${window.location.href}',
      },
      payment: {
        category: 'TasuliseArtikliLigipääs',
        action: 'TutvuMaksevõimalustega',
        label: '${app.$channelConfig("settings").name}',
      },
      login: {
        category: 'TasuliseArtikliLigipääs',
        action: 'LogiSisse',
        label: '${app.$channelConfig("settings").name}',
      },
      verify: {
        category: 'ZlickOrder',
        action: 'PaymentSecondStepConfirmed',
      },
      changePhone: {
        category: 'ZlickOrder',
        action: 'changePhoneNumber',
      },
      confirm: {
        category: 'ZlickOrder',
        action: 'ClickedPay',
        label: 'article: ${app.store.state.article.activeArticle.id}; channel: ${app.$channelConfig("settings").externalId}',
      },
      paidZlick: {
        category: 'ZlickOrder',
        action: 'content purchased',
        label: 'article: ${app.store.state.article.activeArticle.id}; channel: ${app.$channelConfig("settings").externalId}',
      },
      paidSelfService: {
        category: 'Subscription',
        action: 'content purchased',
        label: 'article: ${app.store.state.article.activeArticle.id}; channel: ${app.$channelConfig("settings").externalId}',
      },
    },
  },
  ANALYTICS_SUBSCRIBE_NEWSLETTER_CLICK: {
    types: {
      submit: {
        category: 'Interaction',
        action: 'Subscribe',
        label: 'Newsletter',
      },
    },
  },
  ANALYTICS_CONFIRM_ADULT: {
    types: {
      confirm: {
        category: 'User Confirmation',
        action: '18+ Yes',
        label: '${window.location.href}',
      },
    },
  },
  ANALYTICS_OPEN_PLAYER_CLICK: {
    types: {
      open: {
        category: 'Interaction',
        action: 'Listen News',
        label: '${data.articleId}',
      },
    },
  },
  ANALYTICS_SHARE_CLICK: {
    types: {
      share: {
        category: 'Interaction',
        action: 'Share Content',
        label: '${data}',
      },
    },
  },
  ANALYTICS_COMMENTS_CLICK: {
    types: {
      read: {
        category: 'comment',
        action: 'Comment Read',
        label: '${data.articleId}/${data.view}',
      },
      post: {
        category: 'comment',
        action: 'Comment Post',
        label: '${data.articleId}/${data.view}',
      },
      login: {
        category: 'comment',
        action: 'Login',
        label: '${data.articleId}/${data.view}',
      },
    },
  },
  MOBILE_HEADER_LINK_CLICK: {
    types: {
      open: {
        category: 'Interaction',
        action: 'Navigation Click',
        label: 'MobileBottomNav | ${data.name}',
      },
    },
  },
};
