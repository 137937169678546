import { AdFormConfig } from '@ads/types/AdForm';

/*
 * N.B. Please keep config arrays alphabetically sorted!
 */
export default {
  // All available adForm types.
  types: [
    'bottom',
    'content',
    'contentfirst',
    'floating',
    'gallerytower',
    'header',
    'mobile',
    'mobileinterstitial',
    'mobilesiteheader',
    'scroller',
    'siteheader',
    'square',
    'tower',
    'corner',
  ],
  // These ads are disabled for subscribers
  disableAds: ['floating', 'mobileinterstitial', 'mobilesiteheader', 'scroller', 'siteheader'],
  // Mobile-only ads
  mobileAds: ['galleryscroller', 'mobile', 'mobileinterstitial', 'mobilesiteheader', 'scroller'],
  // Replaces desktop ads with mobile ads
  mobileFallbackAds: {
    content: 'mobile',
    floating: 'scroller',
    siteheader: 'mobilesiteheader',
    square: 'mobile',
    corner: 'mobile',
    tower: 'mobile',
  },
} as AdFormConfig;
