/* eslint-disable @typescript-eslint/no-unused-vars */
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';
import Analytics from './Analytics';
import Ga from './Ga';
import GoogleAnalyticsBase, {
  FieldsObject,
  AnalyticsFieldsObject,
  GoogleAnalyticsLibra,
} from './types/GoogleAnalyticsBase';

declare let window: Awindow;

export default class GoogleAnalytics extends GoogleAnalyticsBase {
  constructor(libra?: GoogleAnalyticsLibra) {
    super();

    if (libra) {
      switch (libra) {
        case GoogleAnalyticsLibra.Analytics:
          return new Analytics();
        case GoogleAnalyticsLibra.Ga:
          return new Ga();
      }
    }

    if (window.ga) {
      return new Analytics();
    }
    if (window._gaq && window._gaq.push) {
      return new Ga();
    }
    logger.error(
      'No Google Analytics library found on page ( no statistics will be tracked )',
    );
  }

  public init() {
    // noop
  }

  public setName(name: string) {
    // noop
  }

  public create(
    trackingId: string,
    name?: string | null,
    extraFields?: FieldsObject,
  ) {
    // noop
  }

  public pageView(url?: string, title?: string, extraFields?: FieldsObject) {
    // noop
  }

  public event(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    extraFields?: FieldsObject,
  ) {
    // noop
  }

  public set(data: AnalyticsFieldsObject | FieldsObject) {
    // noop
  }

  public setVariable(
    key: keyof AnalyticsFieldsObject | keyof FieldsObject,
    value: string | number,
  ) {
    // noop
  }
}
