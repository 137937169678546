
import Vue from 'vue';
import { formatCustomDate } from '@core/filters/dateFormater';
import { Story, StoryItem } from '@headlines/types/Story';

interface Methods {
  timeDifference: (previous: number) => string;
  closeModal: () => void;
}

interface Props {
  activeItem: StoryItem;
  activeStory: Story;
  isLoading: boolean;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    activeItem: {
      type: Object,
      required: true,
    },
    activeStory: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    timeDifference(previous) {
      // PHP to JS timestamps conversion
      const timestamp = previous * 1000;

      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;
      const msPerDay = msPerHour * 24;

      const elapsed = Date.now() - timestamp;

      if (elapsed < msPerMinute) {
        return `${Math.round(elapsed / 1000)} s`;
      } else if (elapsed < msPerHour) {
        return `${Math.round(elapsed / msPerMinute)} min`;
      } else if (elapsed < msPerDay) {
        return `${Math.round(elapsed / msPerHour)} t`;
      } else {
        return formatCustomDate(timestamp, 'DD.MM');
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
});
