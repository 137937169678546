import { AdsWindow } from '@ads/types/window';
import { isMobileOrTablet, makeSticky, markElement, removeLoadingClass } from './adx';
import customerReduceAdsMap from '@ads/config/customerReduceAdsMap.config';

declare const window: AdsWindow;

export function initDiMatter({ isAdFree, isApp }: { isAdFree: boolean; isApp: boolean }) {
  // Check if not already initialized
  if (window.dimatterDone) {
    return;
  }

  // Allow disabling via hashtag #__noadnet or in specific cases
  if (window.location.hash.indexOf('__noadnet') !== -1 || document.location.pathname.indexOf('/zaidimaivaikams/') !== -1) {
    return;
  }

  // Do not initialize for adfree & bots
  if (isAdFree || /(bot|ouse)/['test'](navigator['userAgent'])) {
    return;
  }

  // Load dimatter script, if not loaded yet
  const script = window.document.createElement('script');

  if (document.location.pathname.indexOf('/kablys') !== -1) {
    script.src = `//core.dimatter.ai/pubs/kablys-lt.min.js`;
  } else {
    script.src = `//core.dimatter.ai/pubs/delfi-lt-${isMobileOrTablet() ? 'mobile' : 'desktop'}.min.js`;
  }

  script.async = true;
  window.document.head.appendChild(script);

  window.dimatter = window.dimatter || [];
  window.dimatter.push('standalone');
  window.dimatter.push(['_initialize']);
  window.dimatter.push(function (this: any) {
    this.prebid.timeout = 2000;
    this.prebid.setConfig({
      bidderTimeout: 2000,
      maxRequestsPerOrigin: 4,
      userSync: {
        syncEnabled: true,
        pixelEnabled: true,
        iframeEnabled: true,
        syncDelay: 10000,
        syncsPerBidder: 1,
        filterSettings: {
          iframe: {
            bidders: '*',
            filter: 'include',
          },
        },
      },
      cache: {
        url: 'https://prebid.adnxs.com/pbc/v1/cache',
      },
      consentManagement: {
        cmpApi: 'iab',
        timeout: 3000,
        allowAuctionWithoutConsent: true,
      },
    });
  });

  // No user syncs in app and frontpages
  if (isApp || document.location.pathname.length === 1) {
    window.dimatter.push(function (this: any) {
      this.prebid.setConfig({
        userSync: {
          syncEnabled: false,
          pixelEnabled: false,
          iframeEnabled: false,
          syncDelay: 50000,
          syncsPerBidder: 0,
          filterSettings: undefined,
        },
      });
    });
  }

  document.addEventListener('DOMContentLoaded', ibbBid);

  // Set window.adxDone to avoid multiple initialization
  window.dimatterDone = true;
}

export function initLazy() {
  if (window.ibbLazyObserver) {
    return true;
  }

  if ('IntersectionObserver' in window) {
    window.ibbLazyObserver = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          const target = entry.target as HTMLElement;
          if (entry.isIntersecting && target.dataset.hbt) {
            ibbTag(entry.target.id, { hbt: parseInt(target.dataset.hbt) });

            // Remove loading class
            removeLoadingClass(entry.target.id);

            if (window.ibbLazyObserver) {
              window.ibbLazyObserver.unobserve(entry.target);
            }

            delete target.dataset.hbt;
          }
        });
      },
      { rootMargin: `300px` }
    );
  } else {
    return false;
  }

  return true;
}

export function initReloader() {
  if (window.ibbInscreenObserver) {
    return true;
  }

  const DEFAULT_INTERVAL = 20;

  if ('IntersectionObserver' in window) {
    window.ibbInscreenObserver = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          const target = entry.target as HTMLElement;
          if (entry.isIntersecting) {
            target.dataset.inscreenStart = String((Date.now() / 1000) | 0);

            // calculate left timeout value
            let timeout = target.dataset.reload ? parseInt(target.dataset.reload) : DEFAULT_INTERVAL;
            if (target.dataset.inscreenTime) {
              timeout = timeout - parseInt(target.dataset.inscreenTime) || 0;
            }

            // set timeout callback with remaining time
            // deepcode ignore FunctionDeclarationInBlock: <please specify a reason of ignoring this>
            function reloader(element: HTMLElement, t: number) {
              if (element.dataset.timerId) {
                return;
              }

              element.dataset.timerId = window.setTimeout(
                function (e: any) {
                  // call banner reset function unless page tab is hidden
                  if ((typeof document.hidden === 'undefined' || document.hidden === false) && e.dataset.adCall) {
                    const call_id = parseInt(e.dataset.adCall);
                    window.adCalls[call_id] && window.adCalls[call_id].call();
                  }

                  // reset inscreen timing data
                  e.dataset.inscreenTime = 0;
                  e.dataset.inscreenStart = (Date.now() / 1000) | 0;
                  delete e.dataset.timerId;

                  // keep refreshing
                  reloader(e, e.dataset.reload ? parseInt(e.dataset.reload) : DEFAULT_INTERVAL);
                }.bind(null, element),
                t * 1000
              ) as any;
            }

            reloader(target, timeout);
          } else {
            // calculate inscreen time spent so far
            if (target.dataset.inscreenStart && parseInt(target.dataset.inscreenStart)) {
              target.dataset.inscreenTime = String(
                parseInt(String(target.dataset.inscreenTime)) + ((Date.now() / 1000) | 0) - parseInt(target.dataset.inscreenStart)
              );
              target.dataset.inscreenStart = undefined;
            }

            // remove timeout callback
            if (target.dataset.timerId) {
              window.clearTimeout(parseInt(target.dataset.timerId));
              delete target.dataset.timerId;
            }
          }
        });
      },
      { threshold: [0.7] }
    );
  } else {
    return false;
  }
  return true;
}

/**
 * Billboard footer bidder for adnet
 */
export function ibbBid() {
  // Find and manage slots
  const slots = document.getElementsByClassName('ibb-tag-slot');

  if (!slots.length) {
    return;
  }

  // push to adnetmedia call queue
  const bidSlots = [];
  for (let i = 0; i < slots.length; i++) {
    const slotId = slots[i].id;

    window.dimatter.push([slotId]);
    bidSlots.push(slotId);

    markElement(slotId);
  }
  window.dimatter.push(['_requestBanners', bidSlots]);
}

export default function ibbTag(id: string, options: Record<string, any>) {
  if (window.adx_mkw && (window.adx_mkw.includes('ck-tvari-lietuva') || window.adx_mkw.includes('corner_ad_only') || window.adx_mkw.includes('no_ads'))) {
    return;
  }

  options = options || {};

  // Reduce ads for customers if placement found in map
  if (options.isCustomer && customerReduceAdsMap.includes(id)) {
    return;
  }

  // legacy compatibility
  if (typeof options !== 'object') {
    options = {};
    options.mark = true;
  }

  // responsive website safety checks
  if (options.minwidth && options.minwidth > document.documentElement.clientWidth) return;

  // show on mobile devices
  if (options.maxwidth && options.maxwidth < document.documentElement.clientWidth) return;

  // header bidder timeout
  const hbt = options.hbt && typeof options.hbt === 'number' ? options.hbt : 1000;

  // id randomisation for infinity scrolls
  if (id.indexOf('--dr') === -1) {
    const od = document.getElementById(id);
    if (od) {
      const idr = id + '--dr' + Math.random().toString(16).substring(2);
      id = od.id = idr;
    }
  }

  // lazyload?
  const dom = document.getElementById(id) as HTMLElement;
  if (dom && options.lazy && initLazy() && window.ibbLazyObserver) {
    dom.dataset.hbt = String(hbt);
    window.ibbLazyObserver.observe(dom);
  } else {
    // push to adnetmedia call queue
    window.dimatter.push(function (this: any) {
      this.prebid.timeout = hbt;
    });
    window.dimatter.push([id]);
    window.dimatter.push(['_requestBanners', id]);

    // Remove loading class
    removeLoadingClass(id);
  }

  if (options.fallbackId) {
    dom.dataset.fallbackId = options.fallbackId;
  }

  if (options.mark) {
    markElement(id);
  }

  if (options.sticky) {
    makeSticky(id);
  }

  // ad reloader
  if (options.reload && typeof options.reload === 'number' && initReloader() && window.ibbInscreenObserver) {
    // save reload interval to dom
    dom.dataset.reload = String(options.reload);

    // remove options which do not apply to reloadable ads
    delete options.mark;
    delete options.reload;
    delete options.lazy;
    delete options.sticky;

    // save ad reload call
    dom.dataset.adCall = String(window.adCalls.push(() => ibbTag(id, options)) - 1);

    // start inscreen observer
    window.ibbInscreenObserver.observe(dom);
  }
}
