import { getRootConfig } from '@core/utils/helpers';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import { Label } from '@root/modules/channel/types/channel';

type LabelItem = {
  ids: number[];
  type: string;
  label: Label;
};

export function getDefinedLabels(headline: HeadlineWithRelatedArticles, type: string): Label[] {
  const labels = getRootConfig('labels');
  const definedLabels: Label[] = [];
  const matchingTag = labels?.tag.find(
    (labelItem: LabelItem) => headline.tags?.items?.some((tag) => labelItem.ids.includes(tag.id)) && labelItem.type === type
  );
  if (matchingTag) {
    definedLabels.push(matchingTag.label);
  }

  const matchingCategory = labels?.category.find((labelItem: LabelItem) => labelItem.ids.includes(headline.primaryCategory?.id) && labelItem.type === type);
  if (matchingCategory) {
    definedLabels.push(matchingCategory.label);
  }

  const matchingSource = labels?.source.find(
    (labelItem: LabelItem) => headline.sources?.items?.some((source) => labelItem.ids.includes(source.id)) && labelItem.type === type
  );
  if (matchingSource) {
    definedLabels.push(matchingSource.label);
  }

  return definedLabels;
}
