
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';

interface Props {
  value: any;
  rule: string | boolean;
  validate: boolean;
  focus: boolean;
}

interface Data {
  isValid: boolean | null;
  savedValue: any | null;
}

interface Methods {
  validateValue: (type: string, value: any) => boolean;
  handleInput: (value: any) => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  directives: {
    focus: {
      inserted(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },
  props: {
    value: {
      type: undefined,
      default: null,
      required: true,
    },
    rule: {
      type: [String, Boolean],
      default: '',
      required: false,
      validator(value: string | boolean) {
        const isBoolean = typeof value === 'boolean';
        return !isBoolean ? oneOf(value as string, ['email', 'text', '']) : true;
      },
    },
    validate: {
      type: Boolean,
      default: false,
      required: false,
    },
    focus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isValid: null,
      savedValue: this.value,
    };
  },
  watch: {
    validate: {
      handler(value: boolean) {
        if (value) {
          this.handleInput(this.savedValue);
        }
      },
      immediate: true,
    },
  },
  methods: {
    validateValue(type, value) {
      let regex = null;
      switch (type) {
        case 'email':
          regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return regex.test(value);
        case 'text':
          return value?.trim().length > 0;
        default:
          throw new Error(`validateValue type ${type} unknown`);
      }
    },
    handleInput(value) {
      if (this.validate) {
        const isBoolean = typeof this.rule === 'boolean';
        this.isValid = !isBoolean ? this.validateValue(this.rule as string, value) : (this.rule as boolean);
        this.$emit('is-valid', {
          rule: this.rule,
          isValid: this.isValid,
        });
      }

      this.savedValue = value;
      this.$emit('input', value);
    },
  },
});
