
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';

// When you need to add or remove svg then update iconNames. (vue validates all names written in iconNames.)

// Get svgs from assets/svg/icons/ folder and automatically import them.
// Use when you need to render inline svg. Example: copy svg angle-right.svg to assets/svg/icons folder and then use it <BaseIcon name="angle-right" class="fill-color-1" />.
interface Props {
  name: string;
}

const iconNames: string[] = [
  'comments',
  'burger',
  'search',
  'angle-down',
  'angle-left',
  'angle-right',
  'arrow-left',
  'envelope',
  'airplane',
  'close',
  'clock',
  'crown',
  'user',
  'user-picture',
  'back',
  'bell',
  'bookmark',
  'home',
  'total-package',
  'door',
  'social-envelope',
  'social-facebook',
  'social-facebook-circle',
  'social-linkedin',
  'social-messenger',
  'social-twitter',
  'social-instagram',
  'social-vk',
  'listen',
  'share',
  'link',
  'live',
  'issue-toc',
  'info-circle',
  'mobile',
  'most-bought',
  'microphone',
  'pc',
  'play',
  'print',
  'gallery',
  'gallery-border',
  'group',
  'calendar',
  'trending',
  'trash',
  'forecast-cloud',
  'question-bubble',
  'warning',
  'circle-plus',
  'circle-minus',
  'd-plius',
  'text-size',
  'saugu',
];

export default Vue.extend<Props>({
  name: 'BaseIcon',
  functional: true,
  props: {
    name: {
      type: String,
      default: () => '',
      required: true,
      validator(value: string) {
        const result = oneOf(value, iconNames);

        if (!result) {
          console.warn(`[BaseIcon] '${value}' does not exists in iconNames list`);
        }

        return result;
      },
    },
  },
});
