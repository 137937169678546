
import Vue from 'vue';
import BlockType14Grid from './BlockType14Grid.vue';
import BlockType14List from './BlockType14List.vue';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType14';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface Props {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: BlockTypeSettings;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    BlockType14Grid,
    BlockType14List,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
});
