import BlockType23Component from '@headlines/components/block/21-30/block-type-23/BlockType23.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType23: AdminBlockType = {
  component: BlockType23Component,
  attributes: AdminBlockTypeAttributes({
    channelDomain: 'www.delfi.ee',
    streamsLimit: 5,
    showAllStreams: false,
  }),
  articlesCount: 0,
};

export default AdminBlockType23;
