import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';

import { Weather } from '@root/modules/weather/typings/weather';
import getWeather from '@root/modules/weather/graphql/getWeather.graphql';

type ServiceVariables = Weather.FetchWeather['variables'];

export default class WeatherService extends Service implements ServiceInterface {
  public async fetch(variables: ServiceVariables) {
    if (variables.placeId && /,/.test(variables.placeId)) {
      const coords = variables.placeId.split(',');
      variables.placeId = undefined;
      variables.lat = coords[0];
      variables.lon = coords[1];
    }

    try {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('weather-api').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const options = Object.assign({ query: getWeather }, { variables });
      const response = await apiProvider.query<Weather.FetchWeather['data']>(options);

      return response.data.weather;
    } catch (e) {
      const error = this.generateErrorData(e);
      this.handleError(error);
    }
  }
}
