import { isUndefined } from './helpers';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

interface Settings {
  locale: string;
  component: {
    comment: {
      enable_icon: boolean;
    };
    pullout: {
      icon: {
        enabled: boolean;
        position?: 'side' | 'background';
        src?: string;
      };
      primaryColor: boolean;
    };
    sidebar: {
      border: {
        enabled: boolean;
        color: 'secondary' | 'grey';
        isThick: boolean;
      };
      hasBorderTop: boolean;
      hasBg: boolean;
      isLeadSeparated: boolean;
    };
    featured: {
      border: {
        enabled: boolean;
        color: 'secondary' | 'grey';
        isThick: boolean;
      };
      hasBorderTop: boolean;
      hasBg: boolean;
    };
    headline: {
      title: {
        facebook: {
          minShareCount: number;
        };
      };
    };
  };
}

type OptionalSettings = DeepPartial<Settings>;

type SettingsKeys = keyof Settings;

type GetPortalCoreSettings = <K extends SettingsKeys | 'config'>(key: K) => K extends SettingsKeys ? Settings[K] : Settings;

const defaultSettings = {
  locale: 'et_EE',
  component: {
    comment: {
      enable_icon: false,
    },
    pullout: {
      icon: {
        enabled: false,
      },
      primaryColor: true,
    },
    sidebar: {
      border: {
        enabled: true,
        color: 'grey',
        isThick: false,
      },
      hasBorderTop: true,
      hasBg: true,
      isLeadSeparated: true,
    },
    featured: {
      border: {
        enabled: true,
        color: 'secondary',
        isThick: false,
      },
      hasBorderTop: false,
      hasBg: false,
    },
    headline: {
      title: {
        facebook: {
          minShareCount: 20,
        },
      },
    },
  },
};

let globalPortalCoreSettings: () => typeof defaultSettings | null = () => {
  return null;
};

function setPortalCoreSettings(config: OptionalSettings) {
  const { locale, component } = config;
  const defaultComponent = defaultSettings.component;

  const settings = {
    locale: locale || defaultSettings.locale,
    component: {
      comment: {
        enable_icon: !isUndefined(component?.comment?.enable_icon) ? (component!.comment!.enable_icon as boolean) : defaultComponent.comment.enable_icon,
      },
      pullout: {
        icon: !isUndefined(component?.pullout?.icon) ? (component!.pullout!.icon as Settings['component']['pullout']['icon']) : defaultComponent.pullout.icon,
        primaryColor: !isUndefined(component?.pullout?.primaryColor)
          ? (component!.pullout!.primaryColor as Settings['component']['pullout']['primaryColor'])
          : defaultComponent.pullout.primaryColor,
      },
      sidebar: {
        border: !isUndefined(component?.sidebar?.border)
          ? (component!.sidebar!.border as Settings['component']['sidebar']['border'])
          : defaultComponent.sidebar.border,
        hasBorderTop: !isUndefined(component?.sidebar?.hasBorderTop)
          ? (component!.sidebar!.hasBorderTop as Settings['component']['sidebar']['hasBorderTop'])
          : defaultComponent.sidebar.hasBorderTop,
        hasBg: !isUndefined(component?.sidebar?.hasBg)
          ? (component!.sidebar!.hasBg as Settings['component']['sidebar']['hasBg'])
          : defaultComponent.sidebar.hasBg,
        isLeadSeparated: !isUndefined(component?.sidebar?.isLeadSeparated)
          ? (component!.sidebar!.isLeadSeparated as Settings['component']['sidebar']['isLeadSeparated'])
          : defaultComponent.sidebar.isLeadSeparated,
      },
      featured: {
        border: !isUndefined(component?.featured?.border)
          ? (component!.featured!.border as Settings['component']['featured']['border'])
          : defaultComponent.featured.border,
        hasBorderTop: !isUndefined(component?.featured?.hasBorderTop)
          ? (component!.featured!.hasBorderTop as Settings['component']['featured']['hasBorderTop'])
          : defaultComponent.featured.hasBorderTop,
        hasBg: !isUndefined(component?.featured?.hasBg)
          ? (component!.featured!.hasBg as Settings['component']['featured']['hasBg'])
          : defaultComponent.featured.hasBg,
      },
      headline: {
        title: {
          facebook: {
            minShareCount: !isUndefined(component?.headline?.title?.facebook?.minShareCount)
              ? (component!.headline!.title!.facebook!.minShareCount as Settings['component']['headline']['title']['facebook']['minShareCount'])
              : defaultComponent.headline.title.facebook.minShareCount,
          },
        },
      },
    },
  };

  globalPortalCoreSettings = () => {
    return settings;
  };
}

const getPortalCoreSettings: GetPortalCoreSettings = (key) => {
  const settings = globalPortalCoreSettings() || defaultSettings;
  const noKey = !key || key === 'config';
  // @ts-ignore
  return noKey ? settings : settings[key];
};

export { setPortalCoreSettings, getPortalCoreSettings, OptionalSettings as Settings };
