import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const type: AdminBlockTypeAttribute = {
  label: 'Choose mobile layout',
  key: 'type',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: 'list',
  typeOptions: [
    { key: 'list', value: 'list' },
    { key: 'grid', value: 'grid' },
  ],
};

export default type;
