export default <T>(array: T[], chunkSize = 4): T[][] => {
  const chunkedItems = [];
  let index = 0;

  while (index < array.length) {
    chunkedItems.push(array.slice(index, chunkSize + index));
    index += chunkSize;
  }

  return chunkedItems;
};
