
import Vue from 'vue';
import BlockType11Grid from './BlockType11Grid.vue';
import BlockType11List from './BlockType11List.vue';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType11';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface Computed {
  type: string;
}

interface Props {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: BlockTypeSettings;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    BlockType11Grid,
    BlockType11List,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      let type = this.settings.type;
      type = type && (type === 'grid' || type === 'list') ? type : 'list';

      return type;
    },
  },
});
