interface AnalyticsWindow extends Window {
  dataLayer: any;
  gtag?: (type: string, value: any) => void;
}

declare const window: AnalyticsWindow;

export default function loadGtagScript(containerId: string) {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
  window.gtag('config', containerId);
}
