
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import lt_LT from '@core/locales/lt_LT.json';

import { BlockCollection } from './index';

Vue.use(VueI18n);

const locale = 'lt_LT';
const i18n = new VueI18n({ locale, messages: { lt_LT } });

export default Vue.extend({
  i18n,
  components: {
    ...BlockCollection,
  },
  props: {
    blockType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
});
