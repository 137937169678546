
import Vue from 'vue';
import { ImageItem } from '@gallery/types/Gallery';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import buildPictureUrl from '@gallery/utils/buildPictureUrl';
import { emittedPayload as PinchZoomPayload, PinchZoom } from '@gallery/utils/PinchZoom';

interface Data {
  showMobileDescription: boolean;
  scaledIn: boolean;
  pinchZoom: PinchZoom | undefined;
  zoomScale: number;
}

interface Methods {
  buildPictureUrl: ({ url, id, extension }: { url?: string; id: string; extension?: string }) => string;
  transformPictures: (index: number) => Promise<void>;
  createTouchEvents: () => void;
  changePicture: (index: number, forceLightbox?: boolean) => void;
  resetScale: () => void;
  changeScale: (zoom: boolean) => void;
  mountZoomFeatures: () => void;
}

interface Props {
  pictures: ImageItem[];
  activePictureIndex: number;
  isLightboxed: boolean;
  activePicture: ImageItem;
  showAd: boolean;
  navigationDisabled: boolean;
  adObject: {
    banner: string;
    height: number;
    width: number;
  } | null;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    BaseIcon,
    BaseButton,
  },
  props: {
    pictures: {
      type: Array,
      required: true,
    },
    activePictureIndex: {
      type: Number,
      required: true,
    },
    isLightboxed: {
      type: Boolean,
      required: false,
      default: false,
    },
    activePicture: {
      type: Object,
      required: false,
    },
    showAd: {
      type: Boolean,
      required: false,
      default: false,
    },
    navigationDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    adObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showMobileDescription: false,
      scaledIn: false,
      zoomProperties: {},
      zoomScale: 1,
      pinchZoom: undefined,
    };
  },
  watch: {
    activePictureIndex(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.transformPictures(newVal);
        this.resetScale();
      }
    },
    showAd(newVal) {
      if (!this.pinchZoom) {
        return;
      }
      this.pinchZoom.disableTransformElement = newVal;
      if (newVal) {
        this.resetScale();
      }
    },
  },
  methods: {
    buildPictureUrl,
    async transformPictures(index) {
      if (index < 0 || index > this.pictures.length - 1) {
        return;
      }

      const wrapperEl = this.$refs['wrapper'] as HTMLDivElement;

      if (!wrapperEl) {
        return;
      }

      const transform = index * -100;

      wrapperEl.style.transform = `translateX(${transform}%)`;
    },
    async changePicture(index, forceLightbox) {
      this.$emit('active-picture-changed', index);

      if (forceLightbox) {
        this.$emit('toggle-lightbox', index);
      }
    },
    resetScale() {
      this.zoomScale = 1;
      if (this.pinchZoom) {
        this.pinchZoom.resetScale();
      }
    },
    changeScale(zoom) {
      const maxScale = 5;
      const newScale = zoom ? this.zoomScale + 0.5 : this.zoomScale - 0.5;
      this.zoomScale = newScale <= maxScale ? newScale : this.zoomScale;
      if (this.pinchZoom) {
        this.pinchZoom.changeScale(this.zoomScale);
      }
    },
    mountZoomFeatures() {
      this.zoomScale = 1;
      if (!this.isLightboxed) {
        return;
      }

      const zoomProperties = {
        element: this.$refs['contentBlock'] as HTMLDivElement,
        limitZoom: 40,
        // is set and used to detect if touchStart should be allowed
        // @ts-ignore-next-line
        onScaleChange: (payload: PinchZoomPayload): void => {
          this.zoomScale = payload.scale;
          if (this.zoomScale > 1) {
            this.showMobileDescription = false;
            this.$emit('show-mobile-description', this.showMobileDescription);
          }

          this.scaledIn = this.zoomScale > 1;
        },
      };

      this.pinchZoom = new PinchZoom(zoomProperties);
    },
    createTouchEvents() {
      const imageSlider = this.$refs['wrapper'] as HTMLDivElement;
      const contentBlock = this.$refs['contentBlock'] as HTMLDivElement;
      const imageWidth = imageSlider.clientWidth;

      let startPositionX: number | null = null;
      let deltaX: number | null = null;
      let deltaXPercentage: number | null = null;
      let touchStartTimestamp: number | null = null;

      const touchStart = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        // Zoomed in. No change
        if (this.zoomScale > 1 && this.isLightboxed) {
          return;
        }

        // @ts-ignore-next-line
        const touches = event.touches ? event.touches[0] : event;

        startPositionX = touches.clientX;

        touchStartTimestamp = Date.now();
        imageSlider.style.transition = 'unset';

        contentBlock.addEventListener('touchmove', touchMove);
        contentBlock.addEventListener('touchend', touchEnd);

        if (!navigator.userAgent.match('iPad') && !navigator.userAgent.match('iPhone')) {
          contentBlock.addEventListener('mousemove', touchMove);
          contentBlock.addEventListener('mouseup', touchEnd);
        }
      };

      const touchMove = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        // Zoomed in. No change
        if (this.zoomScale > 1 && this.isLightboxed) {
          return;
        }
        // @ts-ignore-next-line
        const touches = event.touches ? event.touches[0] : event;
        const clientX = touches.clientX;

        deltaX = clientX - startPositionX!;

        deltaXPercentage = (deltaX / imageWidth) * 100;

        const transform = deltaXPercentage + this.activePictureIndex * -100;

        imageSlider.style.transform = `translateX(${transform}%)`;
      };

      const touchEnd = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        const touchDuration = Date.now() - touchStartTimestamp!;
        imageSlider.style.transition = 'transform 0.5s';

        const fallbackTransform = () => {
          const transform = this.activePictureIndex * -100;
          imageSlider.style.transform = `translateX(${transform}%)`;
        };

        if (Math.abs(deltaXPercentage!) >= 20) {
          if (deltaXPercentage! > 0) {
            if (this.activePictureIndex - 1 >= 0) {
              this.changePicture(this.activePictureIndex - 1);
            } else {
              this.changePicture(this.pictures.length - 1);
            }
          } else if (this.activePictureIndex + 1 < this.pictures.length) {
            this.changePicture(this.activePictureIndex + 1);
          } else {
            this.changePicture(0);
          }
          // Touch (click)
        } else if (touchDuration < 250 && Math.abs(deltaX!) < 25) {
          if (this.isLightboxed) {
            if (this.zoomScale > 1) {
              this.showMobileDescription = false;
            } else {
              this.showMobileDescription = !this.showMobileDescription;
            }
            this.$emit('show-mobile-description', this.showMobileDescription);
          } else {
            this.$emit('toggle-lightbox', this.activePictureIndex);
          }
          fallbackTransform();
        } else {
          fallbackTransform();
        }

        startPositionX = null;
        deltaX = null;
        deltaXPercentage = null;
        touchStartTimestamp = null;

        contentBlock.removeEventListener('mousemove', touchMove);
        contentBlock.removeEventListener('mouseup', touchEnd);
        contentBlock.removeEventListener('touchmove', touchMove);
        contentBlock.removeEventListener('touchend', touchEnd);
      };

      contentBlock.addEventListener('touchstart', touchStart);

      if (!navigator.userAgent.match('iPad') && !navigator.userAgent.match('iPhone')) {
        contentBlock.addEventListener('mousedown', touchStart);
      }
    },
  },
  mounted() {
    this.transformPictures(this.activePictureIndex);
    this.createTouchEvents();
    if (this.isLightboxed) {
      this.mountZoomFeatures();
    }
  },
});
