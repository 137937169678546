
import Vue from 'vue';
import { mapState } from 'vuex';
import Header from '@root/common/components/layout/header/Header.vue';
import HeaderBottom from '@root/common/components/layout/header/HeaderBottom.vue';
import Footer from '@root/common/components/layout/footer/Footer.vue';
import LiveStreamAnnouncement from '@root/common/components/layout/LiveStreamAnnouncement.vue';
import { Header as IHeader, Footer as IFooter, Mobile as IMobile } from '@root/modules/navigation/types/navigation';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import AdFormSiteheader from '@ads/components/adForm/AdFormSiteheader.vue';
import AdFormPromotionLine from '@ads/components/adForm/AdFormPromotionLine.vue';
import cookieBot from '@root/common/utils/cookieBot';
import { gridConfig } from '@core/config/grid.config';
import getBannerKey from '@root/modules/ads/utils/getBannerKey';
import { formatAdSurround, resetFormatAdSurround } from '@root/modules/ads/utils/formatAdSurround';
import MobileHeader from '@root/common/components/layout/header/MobileHeader.vue';
import ScrollTop from '@root/common/components/base/ScrollTop.vue';
import isTildaArticle from '@root/modules/article/utils/isTildaArticle';
import isFullWidthArticle from '@root/modules/article/utils/isFullWidthArticle';

const PianoMaintenanceAlert = () => import('@piano/components/PianoMaintenanceAlert.vue');

interface Computed {
  contentCenterClass: Record<string, boolean>;
  hideHeader: boolean;
  headerNavigation: IHeader;
  footerNavigation: IFooter;
  mobileNavigation: IMobile[];
  hideOrdering: boolean;
  pageContentLoaded: string;
  alerts: { type: string; title: string; text: string }[];
  adsPlacements: Record<string, any>;
  siteHeaderAd: string;
  mobileSiteHeaderAd: string;
  headerAd: Record<string, any>;
  floatingAd: Record<string, any>;
  scrollerAd: Record<string, any>;
  bottomAd: string;
  mobileinterstitialAd: string;
  promotionAd: Record<string, any>;
  mobilePromotionAd: Record<string, any>;
  mobileBottomAd: Record<string, any>;
  isDelfiFrontpage: boolean;
  isFrontpage: boolean;
  isArticle: boolean;
  isCrArticle: boolean;
  isTildaArticle: boolean;
  isFullWidthArticle: boolean;
  refetch: boolean;
  disableConsentModal: boolean;
  showPianoMaintenanceAlert: boolean;
}

interface Data {
  bannerKeys: Record<string, string>;
  isMobileView: boolean;
  observer: MutationObserver | null;
}

interface Methods {
  resetAdFormKey: (index?: number, skipHeader?: boolean) => void;
  copyUrlInject: () => void;
  getKey: (params: Record<string, any>) => string;
}

const pageFetchLogic = ['frontpage', 'channel', 'category', 'article', 'customPage'];

interface DefaultWindow extends Window {
  _AutofillCallbackHandler: any;
  dataLayer: any;
}

declare const window: DefaultWindow;

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    AdFormSiteheader,
    AdFormBanner,
    AdFormPromotionLine,
    Header,
    HeaderBottom,
    Footer,
    MobileHeader,
    LiveStreamAnnouncement,
    ScrollTop,
    PianoMaintenanceAlert,
  },
  data() {
    return {
      bannerKeys: {
        siteHeader: 'siteheader',
        header: 'header',
        floating: 'floating',
        mobileBottom: 'mobileBottom',
        mobileInterstitial: 'mobileInterstitial',
        mobilePageview: 'mobilePageview',
        mobilePageviewGoogle: 'mobilePageviewGoogle',
        bottom: 'bottom',
        promotion: 'promotion',
      },
      isMobileView: false,
      observer: null,
    };
  },
  head() {
    const { lang, locale } = this.$channelConfig('settings');
    const { cbid } = this.$channelConfig('analytics')!.cookieBot;
    const bodyClass = this.hideHeader || this.isTildaArticle ? 'no-header' : '';
    const script = [];

    if (!this.hideOrdering) {
      script.push({
        id: 'Cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        async: true,
        'data-cbid': cbid,
        'data-framework': 'TCFv2.2',
        'data-culture': locale.split('_')[0],
        'data-wipe-preferences': '0',
        'data-wipe-statistics': '0',
        'data-wipe-marketing': '0',
      });
    }

    script.push({ src: '//s1.adform.net/banners/scripts/adx.js', async: true });

    // Inject ld+json into head
    const { base } = this.$channelConfig('meta');
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: base.baseUrl,
      author: {
        '@type': 'Person',
        name: base.title,
      },
      publisher: {
        '@type': 'Organization',
        name: 'Delfi',
      },
      description: base.description,
    };

    script.push({ hid: 'default-ld-json', type: 'application/ld+json', json: jsonLd });

    return {
      htmlAttrs: {
        lang,
      },
      bodyAttrs: {
        class: bodyClass,
      },
      script,
    };
  },
  computed: {
    contentCenterClass() {
      const disabledAdsList: string[] = this.$store.getters['adForm/getDisabledAdsList'];
      return { 'content-center': disabledAdsList.includes('tower') };
    },
    hideHeader() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.disableHeader;
    },
    ...mapState({
      headerNavigation: (state: any) => state.navigation.header.data as IHeader,
      footerNavigation: (state: any) => state.navigation.footer.data as IFooter,
      mobileNavigation: (state: any) => state.navigation.mobile.data as IMobile[],
    }),
    hideOrdering() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.hideSignupAndOrders.enabled;
    },
    pageContentLoaded() {
      return this.$store.state.index.pageContentLoaded;
    },
    alerts() {
      return this.$store.state.index.alerts || [];
    },
    adsPlacements() {
      const ads: Record<string, any> = this.$store.getters['adForm/getAdsPlacements'];

      return ads || {};
    },
    siteHeaderAd() {
      return (this.adsPlacements.siteheader && this.adsPlacements.siteheader.mid[0]) || null;
    },
    mobileSiteHeaderAd() {
      return (this.adsPlacements.mobilesiteheader && this.adsPlacements.mobilesiteheader.mid[0]) || null;
    },
    headerAd() {
      const placement: Record<string, any> = { mid: null, adb: null };

      if (!this.adsPlacements.header) {
        return placement;
      }

      if (this.isDelfiFrontpage && this.adsPlacements.header.dfp && this.adsPlacements.header.dfp.mid && this.adsPlacements.header.dfp.mid.length) {
        placement.mid = this.adsPlacements.header.dfp.mid[0];

        if (this.adsPlacements.header.dfp.group) {
          placement.group = this.adsPlacements.header.dfp.group;
        }
      } else if (this.adsPlacements.header.mid.length) {
        placement.mid = this.adsPlacements.header.mid[0];

        if (this.adsPlacements.header.group) {
          placement.group = this.adsPlacements.header.group;
        }
      }

      if (this.adsPlacements.header.adb) {
        placement.adb = this.adsPlacements.header.adb;
      }

      return placement;
    },
    floatingAd() {
      const placement: Record<string, any> = { mid: null, adb: null };

      if (!this.adsPlacements.floating) {
        return placement;
      }

      if (this.isDelfiFrontpage && this.adsPlacements.floating.dfp && this.adsPlacements.floating.dfp.mid && this.adsPlacements.floating.dfp.mid.length) {
        placement.mid = this.adsPlacements.floating.dfp.mid[0];
      } else if (this.adsPlacements.floating.mid.length) {
        placement.mid = this.adsPlacements.floating.mid[0];
      }

      if (this.adsPlacements.floating.adb) {
        placement.adb = this.adsPlacements.floating.adb;
      }

      return placement;
    },
    scrollerAd() {
      const placement: Record<string, any> = { mid: null, adb: null };

      if (this.adsPlacements.scroller && this.adsPlacements.scroller.mid.length > 0 && this.adsPlacements.scroller.mid[0]) {
        placement.mid = this.adsPlacements.scroller.mid[0];
      }

      if (this.adsPlacements.scroller && this.adsPlacements.scroller.adb) {
        placement.adb = this.adsPlacements.scroller.adb;
      }

      return placement;
    },
    bottomAd() {
      return (this.adsPlacements.bottom && this.adsPlacements.bottom.mid[0]) || null;
    },
    mobileinterstitialAd() {
      return (this.adsPlacements.mobileinterstitial && this.adsPlacements.mobileinterstitial.mid[0]) || null;
    },
    promotionAd() {
      return (this.adsPlacements.promotion && this.adsPlacements.promotion.mid[0]) || null;
    },
    mobilePromotionAd() {
      return (this.adsPlacements.mobilepromotion && this.adsPlacements.mobilepromotion.mid[0]) || null;
    },
    mobileBottomAd() {
      return (this.adsPlacements.mobilebottom && this.adsPlacements.mobilebottom.mid[0]) || null;
    },
    isDelfiFrontpage() {
      return this.isFrontpage && this.$channelConfig('settings').id === 'delfi';
    },
    isFrontpage() {
      return this.$route.name === 'frontpage';
    },
    isArticle() {
      return this.$route.name === 'article';
    },
    isCrArticle() {
      if (!this.isArticle) {
        return false;
      }

      const activeArticle = this.$store.getters['article/getActiveArticle'];
      if (!activeArticle) {
        return false;
      }

      const crArticles = this.$store.getters['article/getCrArticles'];
      const isCrArticle = crArticles.find((article: number) => article === activeArticle.id);

      return this.isArticle && isCrArticle;
    },
    isTildaArticle() {
      if (!this.isArticle) {
        return false;
      }

      const customCategorySettings = this.$channelConfig('page').category?.component?.header;
      return isTildaArticle(this.$route, customCategorySettings);
    },
    isFullWidthArticle() {
      if (!this.isArticle) {
        return false;
      }

      const activeArticle = this.$store.getters['article/getActiveArticle'];
      if (!activeArticle) {
        return false;
      }

      return isFullWidthArticle(activeArticle);
    },
    refetch() {
      return this.$store.state.frontpage.refetch;
    },
    disableConsentModal() {
      const { specialFeatures } = this.$channelConfig('settings').application;

      return specialFeatures.disableConsentModal;
    },
    showPianoMaintenanceAlert() {
      return this.$store.state.piano.maintenanceAlertVisible;
    },
  },
  watch: {
    // asyncData logic - key will change after page asyncData has finished
    $route() {
      if ((this.$route.name && !pageFetchLogic.includes(this.$route.name)) || process.client) {
        this.resetAdFormKey();
      }

      resetFormatAdSurround();

      if (process.client) {
        // Dispatch custom event for player.js
        window.dispatchEvent(new CustomEvent('nuxtRouteChange'));
      }
    },
    // fetch logic - key will change after page fetch has finished
    pageContentLoaded() {
      if (this.$route.name && pageFetchLogic.includes(this.$route.name) && !this.isCrArticle) {
        this.resetAdFormKey();
      }
    },
    refetch(refetch) {
      if (refetch) {
        this.resetAdFormKey(Math.floor(10000 + Math.random() * 90000));
      }
    },
  },
  mounted() {
    if (process.client) {
      // Remove self-injected Google CMP container in review
      if (this.disableConsentModal || this.hideOrdering) {
        const mutationCallback = (mutations: any) => {
          const mutation = mutations[0] as MutationRecord;
          const nodes = mutation.addedNodes as NodeList;
          const element = nodes[0] as HTMLElement;
          if (element && element.nodeName === 'DIV' && element.className === 'fc-consent-root') {
            element.remove();
          }
        };

        this.observer = new MutationObserver(mutationCallback);
        this.observer.observe(document.body, { childList: true, subtree: true });
      }

      const screenWidth = window.screen.width;
      const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;
      this.isMobileView = screenWidth <= gridConfig.breakpoints.lg || isIpad;
    } else {
      this.isMobileView = this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
    }

    if (process.client) {
      window.addEventListener('message', (event: MessageEvent) => {
        if (event.data === 'formatAdSurround') {
          formatAdSurround();
        }
      });

      // Mute unknown FB iOS app error
      // https://developers.facebook.com/community/threads/320013549791141
      window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};
    }

    try {
      this.$instanceConnector('headlines').listen('headlineClick', async ({ headline }) => {
        if (headline.clickData) {
          let crIndex = null;

          if (this.isArticle && this.isCrArticle) {
            const activeArticle = this.$store.getters['article/getActiveArticle'];
            const id = activeArticle.id;
            const crArticles = this.$store.state.article.crArticles;
            crIndex = crArticles.indexOf(id) + 1;
          }

          window.dataLayer.push({
            event: 'article_click',
            page_layout: headline.clickData.articleLayout,
            article_list_type: headline.clickData.listType,
            article_list_position: headline.clickData.listPosition,
            article_id: headline.clickData.listArticleId,
            article_url: headline.clickData.listArticleUrl,
            article_position: headline.clickData.listArticlePosition,
            article_index: this.isArticle && this.isCrArticle && crIndex ? crIndex : null,
          });
        }
      });
    } catch (e) {
      this.$sentry.captureException(`Headline click fail: ${e}`, {
        tags: { 'process.server': process.server ? 'server' : 'client' },
        contexts: { vue: { componentName: 'default.vue layout' } },
      });
    }

    if (process.client) {
      cookieBot();
      this.copyUrlInject();
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getKey({ prefix, index }) {
      return getBannerKey({ prefix, route: this.$route, store: this.$store, index });
    },
    resetAdFormKey(index = 0) {
      this.bannerKeys = {
        siteHeader: this.getKey({ prefix: 'siteheader', index }),
        header: this.getKey({ prefix: 'header', index }),
        floating: this.getKey({ prefix: 'floating', index }),
        mobileBottom: this.getKey({ prefix: 'mobileBottom', index }),
        mobileInterstitial: this.getKey({ prefix: 'mobileInterstitial', index }),
        bottom: this.getKey({ prefix: 'bottom', index }),
      };
    },
    copyUrlInject() {
      if (!process.client || !window) {
        return;
      }

      window.document.addEventListener('copy', () => {
        const sel = window.getSelection();

        if (!sel || sel.anchorNode?.nodeName !== '#text' || sel.toString().length < 32) {
          return;
        }

        const isPaid = this.$store.getters['article/getActiveArticle']?.content.paywall.enabled || false;
        if (sel.toString().length > 100 && isPaid) {
          sel.removeAllRanges();
        }

        const loc = this.$t('copy_inject_url.message');
        const xtext = '<br/><br/> ' + loc + '<a href="' + document.location.href + '">' + document.location.href + '</a><br/><br/>';

        const div = window.document.createElement('div');
        div.innerHTML = sel + xtext;
        div.style.position = 'absolute';
        div.style.left = '-99999px';

        document.body.appendChild(div);

        sel.selectAllChildren(div);

        window.setTimeout(function () {
          div.remove();
        }, 0);
      });
    },
  },
});
