// Has to be JS file otherwise, nuxt won't overwrite its store.
import Vue from 'vue';
import Vuex from 'vuex';
import createModules from '@root/store/storeModulesImporter';

Vue.use(Vuex);
const modules = createModules();

export const createStore = () => {
  return new Vuex.Store({
    modules,
  });
};
