
import { Fragment } from './Fragment';
import { loadScript } from '@core/utils/helpers';
import srcConfig from '@core/config/src.config';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentQuiz',
  data() {
    return {
      pollId: null as number | null,
      uid: '',
    };
  },
  async mounted() {
    const { code } = this;

    if (!code) {
      return;
    }

    const id = this.extractId({ code });
    const script = `${srcConfig.quiz}#id=${id}`;

    await loadScript({ script, attr: { async: true }, parentElement: this.$refs.quizElement as HTMLElement });
    this.uid = `quiz-poll-${id}`;
  },
  methods: {
    extractId({ code }: { code: string }) {
      const regex = /quiz\.php#id=(\d+)/gm;
      const match = regex.exec(code);
      const id = match && match.length > 0 ? Number(match[1]) : null;

      return id;
    },
  },
});
