
import ContentImage from '@core/components/UI/ContentImage.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType19';
import chunkArray from '@headlines/utils/ChunkArray';

const defaultSettings = {
  title: true,
  lead: true,
  picture: true,
  type: 'grid',
};

interface Methods {
  chunkArray: <T>(array: T[], chunkSize?: number) => T[][];
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, unknown, unknown>({
  components: {
    BaseButton,
    ContentImage,
    LinkHandler,
  },
  methods: {
    chunkArray,
  },
});
