import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const initialTab: AdminBlockTypeAttribute = {
  label: 'Open tab',
  key: 'initialTab',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: 1,
  typeOptions: [
    { key: 'Latest', value: 1 },
    { key: 'Top', value: 2 },
  ],
};

export default initialTab;
