
import axios from 'axios';
import Vue from 'vue';

import VideoPlayer from '@media/components/video/VideoPlayer.vue';
import ContentImage from '@core/components/UI/ContentImage.vue';

interface LSAItem {
  id: string;
  slug: string;
  attributes: Record<string, unknown>[];
  metaImage: {
    id: string;
  };
  content: {
    title: {
      text: string;
    };
    leadElement: {
      type: string;
      content: StreamItem[];
    };
  };
}

interface StreamItem {
  type: string;
  attrs: {
    id: string;
    type: string;
    metadata: Record<string, unknown>;
    streamData: Record<string, unknown>;
    cropperData: Record<string, unknown>;
  };
}

interface LSAResponse {
  status: string;
  items: LSAItem[];
}

interface Data {
  activeStream: LSAItem | null;
  lsaUrl: string;
  streams: Array<any>;
}

interface Methods {
  clearInterval: () => void;
  getActiveStream: () => void;
  handleClick: () => void;
  handleClose: () => void;
}

interface Computed {
  isMobileOrTablet: boolean;
  content: StreamItem | null;
  isWowzaStream: boolean;
}

interface lsaWindow extends Window {
  _dlf: any;
  ga: any;
  geoipdata: any;
  lsa: any;
}

declare let window: lsaWindow;

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    VideoPlayer,
    ContentImage,
  },
  data() {
    return {
      activeStream: null,
      lsaUrl: 'https://www.delfi.lt/misc/video/ajax.php?get=live-stream-announcement-v3',
      streams: [],
    };
  },
  computed: {
    isMobileOrTablet() {
      return (typeof window !== 'undefined' && window.innerWidth <= 991) || false;
    },
    content() {
      return this.activeStream?.content.leadElement.content[0] || null;
    },
    isWowzaStream() {
      return this.content?.attrs?.streamData?.videoVendorId === 'wowza';
    },
  },
  watch: {
    activeStream(value) {
      if (value && this.activeStream) {
        const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toUTCString();
        window.document.cookie = `dLSA_${this.activeStream.id}=1;expires=${expires};path=/`;

        if (window.ga) {
          window.ga('send', 'event', 'Video', 'teaserPlayerShow');
        }

        this.clearInterval();

        if (this.isMobileOrTablet) {
          setTimeout(
            () => {
              window.document.getElementById(`lsa-${this.activeStream?.id}`)?.classList.add('live-stream-announcement--active');
            },
            2000,
            this.activeStream?.id
          );

          setTimeout(
            () => {
              window.document.getElementById(`lsa-${this.activeStream?.id}`)?.classList.remove('live-stream-announcement--active');
            },
            10000,
            this.activeStream?.id
          );
        }
      }
    },
  },
  async mounted() {
    if (process.client) {
      if (
        !/\.lt\/video\//.test(window.location.href) &&
        !/\.lt\/puslapis\/premium\//.test(window.location.href) &&
        window.document.cookie.indexOf('__dLSA') === -1
      ) {
        this.getActiveStream();

        window.lsa = setInterval(() => {
          this.getActiveStream();
        }, 15000);
      }
    }
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    clearInterval() {
      if (typeof window.lsa !== 'undefined') {
        clearInterval(window.lsa);
      }
    },
    async getActiveStream() {
      const { data }: { data: LSAResponse } = await axios.get(this.lsaUrl);
      const items = Object.values(data.items); // Stupid JSON has keys

      if (items.length) {
        items.forEach((item) => {
          if (window.document.cookie.indexOf(`dLSA_${item.id}`) === -1) {
            this.clearInterval();
            this.activeStream = item;
          }
        });
      }
    },
    handleClick() {
      if (this.isMobileOrTablet) {
        window.location.href = `https://www.delfi.lt/a/${this.activeStream?.id}`;
      } else {
        window.open(`https://www.delfi.lt/a/${this.activeStream?.id}`, '_blank');
      }

      if (window.ga) {
        window.ga('send', 'event', 'Video', 'teaserPlayerClick');
      }
    },
    handleClose() {
      window.document.getElementById(`lsa-${this.activeStream?.id}`)?.remove();

      if (window.ga) {
        window.ga('send', 'event', 'Video', 'teaserPlayerClose');
      }
    },
  },
});
