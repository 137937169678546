import { partlyCloudyNight, rain, snow, sun, sunCloudy, thunder } from '../config/weatherTypes.config';

const formatForecastInput = (input: any) => {
  const uniqueDays: string[] = [];
  const forecast: Array<any> = [];
  input.forEach((item: any) => {
    const date = toFullDate(item.time);
    if (date) {
      if (!uniqueDays.includes(date)) {
        uniqueDays.push(date);
        forecast.push({
          date,
          availableHours: [],
        });
      }
    }
  });
  return { uniqueDays, forecast };
};

const to24hTime = (input: string | Date | number) => {
  // @ts-ignore
  return new Intl.DateTimeFormat('lt-LT', { timeStyle: 'short' }).format(new Date(input));
};

const toDailyForecast = (input: any, days = 7) => {
  const { uniqueDays, forecast } = formatForecastInput(input);
  uniqueDays.forEach((item, index: number) => {
    const uniqueHours: string[] = [];
    forecast[index].availableHours.forEach((hour: any) => {
      uniqueHours.push(hour.time);
    });

    input.forEach((subItem: any) => {
      const day = toFullDate(subItem.time);
      if (day === item && !uniqueHours.includes(subItem.time)) {
        forecast[index].availableHours.push(subItem);
      }
    });
  });

  return forecast.slice(0, days);
};

const toFullDate = (input: string | Date | number) => {
  if (!input) return;
  return new Intl.DateTimeFormat('lt-LT', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(input));
};

const weatherBackgroundParser = (input: string, dateTime: string) => {
  let output = input.replace('_', '-');
  input = input.replace('sleet', 'snow');
  if (input.includes('thunder')) {
    input = 'thunder';
  }
  const itemsToCheckAgainst = ['thunder', 'fog', 'snow', 'rain', 'cloudy'];

  itemsToCheckAgainst.forEach((item) => {
    if ((item.includes(output) && !['partlycloudy'].includes(input)) || input.includes(item)) {
      output = item;
    }
  });

  if (['cloudy'].includes(output) && output !== 'partlycloudy') {
    output = 'cloudy';
  }

  const hours = new Date(dateTime).getHours();
  const isDayTime = hours > 6 && hours < 20;

  if (itemsToCheckAgainst.includes(output)) {
    if (isDayTime) {
      output += '-day';
    } else {
      output += '-night';
    }
  }

  return output;
};

const toHourlyForecast = (input: any) => {
  const { forecast } = formatForecastInput(input);

  forecast.forEach((day) => {
    input.forEach((hour: any) => {
      const hourDate = toFullDate(hour.time);
      if (day.date === hourDate) {
        day.availableHours.push(hour);
      }
    });
  });

  return forecast;
};

const toWidgetDailyForecast = (input: any, howManyDays: number) => {
  let headerDailyForecast: Array<any> = input.map((item: any) => {
    return {
      date: item.date,
    };
  });

  input.forEach((day: any, index: number) => {
    day.availableHours.forEach((hour: any) => {
      const timeOfDay = to24hTime(hour.time);

      const setForecastTime = (headerDailyForecast: any[], index: number, timeOfDay: string, hour: any) => {
        const forecastTime: any = {};
        forecastTime.temperature = hour.data.instant?.details.air_temperature;
        if (hour.data.next_1_hours) {
          forecastTime.symbol_code = hour.data.next_1_hours.summary.symbol_code;
        } else if (hour.data.next_6_hours) {
          forecastTime.symbol_code = hour.data.next_6_hours.summary.symbol_code;
        } else if (hour.data.next_12_hours) {
          forecastTime.symbol_code = hour.data.next_12_hours.summary.symbol_code;
        }

        if (timeOfDay === '09:00' || timeOfDay === '08:00') {
          headerDailyForecast[index].morning = forecastTime;
        } else if (timeOfDay === '15:00' || timeOfDay === '14:00') {
          headerDailyForecast[index].daytime = forecastTime;
        } else if (timeOfDay === '21:00' || timeOfDay === '20:00') {
          headerDailyForecast[index].night = forecastTime;
        }
      };

      if (isSummerTime(new Date())) {
        if (timeOfDay === '09:00' || timeOfDay === '15:00' || timeOfDay === '21:00') {
          setForecastTime(headerDailyForecast, index, timeOfDay, hour);
        }
      } else if (timeOfDay === '08:00' || timeOfDay === '14:00' || timeOfDay === '20:00') {
        setForecastTime(headerDailyForecast, index, timeOfDay, hour);
      }
    });
  });

  headerDailyForecast.shift();
  headerDailyForecast = headerDailyForecast.slice(0, howManyDays);

  return headerDailyForecast;
};

interface iconParserInput {
  string: string;
  thick?: boolean;
  colored?: boolean;
}

// This util takes in icon codes and converts some icon codes to others
// https://api.met.no/weatherapi/weathericon/2.0/documentation
const weatherIconParser = (input: iconParserInput) => {
  let output = '';

  if (input.thick) {
    output = 'thick_';
  } else if (!input.colored) {
    output = 'thin_';
  } else {
    output = 'colored_';
  }

  if (sun.includes(input.string)) {
    output += 'sun';
  } else if (sunCloudy.includes(input.string)) {
    output += 'sun_cloudy';
  } else if (partlyCloudyNight.includes(input.string)) {
    output += 'partlycloudy_night';
  } else if (thunder.includes(input.string.replace('_day', '').replace('_night', ''))) {
    output += 'thunder';
  } else if (rain.includes(input.string.replace('_day', '').replace('_night', ''))) {
    output += 'rain';
  } else if (snow.includes(input.string.replace('_day', '').replace('_night', ''))) {
    output += 'snow';
  } else {
    output += input.string;
  }
  return output;
};

const windDirection = (item: any) => {
  let angle = 0;

  if (item.data.instant) {
    angle = item.data.instant.details.wind_from_direction || 0;
  }

  // 180 is a required offset to get accurate results, check with yr.no website if in doubt
  angle = 180 + angle;

  return { transform: `rotate(${angle}deg)` };
};

const isSummerTime = (date: Date): boolean => {
  const getLastSundayOfMonth = (year: number, month: number): Date => {
    const lastDayOfMonth = new Date(year, month + 1, 0);
    return new Date(year, month, lastDayOfMonth.getDate() - lastDayOfMonth.getDay());
  };

  const year = date.getFullYear();
  const lastSundayOfMarch = getLastSundayOfMonth(year, 2); // March is month index 2
  const lastSundayOfOctober = getLastSundayOfMonth(year, 9); // October is month index 9

  return date >= lastSundayOfMarch && date < lastSundayOfOctober;
};

export { toDailyForecast, toHourlyForecast, toWidgetDailyForecast, weatherIconParser, weatherBackgroundParser, windDirection, isSummerTime };
