
import Vue from 'vue';
import AudioPlayerLead from '@media/components/audio/AudioPlayerLead.vue';
import AudioPlayerWidget from '@media/components/audio/AudioPlayerWidget.vue';
import { AudioItem } from '@media/types/getAudio';

type widgetType = 'widget' | 'lead';

interface Props {
  type: widgetType;
  data: {
    attrs: AudioItem;
  };
  ads: Record<string, unknown>;
}
interface Computed {
  playerComponent: string;
}

const components = {
  lead: 'AudioPlayerLead',
  widget: 'AudioPlayerWidget',
};

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    type: {
      type: String as () => widgetType,
      required: false,
      default: 'widget',
    },
    data: {
      type: Object,
      required: true,
    },
    ads: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    AudioPlayerLead,
    AudioPlayerWidget,
  },
  computed: {
    playerComponent() {
      return components[this.type as widgetType];
    },
  },
});
