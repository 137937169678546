
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType7';
import BlockType2 from './block-type-2/BlockType2.vue';
import BlockType3 from './block-type-3/BlockType3.vue';
import BlockType4 from './block-type-4/BlockType4.vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface DataWithSettings {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: BlockTypeSettings;
}

interface Computed {
  blocks: {
    name: string;
    data: {
      items: HeadlineWithRelatedArticles[];
    };
    settings: BlockTypeSettings;
  }[];
}

interface Methods {
  getBlockData: (items: HeadlineWithRelatedArticles[], amount: number, indexOffset?: number) => DataWithSettings;
}

const defaultSettings = {
  icons: true,
  lead: true,
  titleComment: false,
  relatedTitles: false,
  author: true,
  channel: false,
  category: true,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, Computed, unknown>({
  components: {
    BlockType2,
    BlockType3,
    BlockType4,
  },
  computed: {
    blocks() {
      const { getBlockData, data } = this;
      const items = [...data.items];
      const blocks = [
        { name: 'BlockType2', ...getBlockData(items, 2) },
        { name: 'BlockType3', ...getBlockData(items, 3, 2) },
        { name: 'BlockType4', ...getBlockData(items, 4, 5) },
        { name: 'BlockType3', ...getBlockData(items, 3, 9) },
      ];

      return blocks;
    },
  },
  methods: {
    getBlockData(items, amount, indexOffset = 0) {
      return {
        data: { items: items.splice(0, amount) },
        settings: { indexOffset, ...this.getSettings },
      };
    },
  },
});
