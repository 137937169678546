import { render, staticRenderFns } from "./ContentFragment.vue?vue&type=template&id=02f96365"
import script from "./ContentFragment.vue?vue&type=script&lang=ts"
export * from "./ContentFragment.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports