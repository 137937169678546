import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { cXenseTracker } from './cXense';
import { gTagTracker } from './Gtag';
import { GemiusTracker } from './Gemius';

export default (player: jwplayer.JWPlayer, mediaData: VideoItem | AudioItem, lsa: boolean) => {
  new cXenseTracker(player, mediaData, lsa);
  new gTagTracker(player, mediaData, lsa);
  new GemiusTracker(player, mediaData, lsa);
};
