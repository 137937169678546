import { Config } from '@root/modules/channel/types/channel';

type Application = Config['settings']['application'];
type GetAdBlockLayerStateArguments = { application: Application; adBlockEnabled: boolean; hasChannelAccess: boolean; adFree: boolean };

export default function getAdBlockLayerState({ application, adBlockEnabled, hasChannelAccess, adFree }: GetAdBlockLayerStateArguments) {
  const isAdFreeOrSubscription = hasChannelAccess || adFree;

  return adBlockEnabled && !isAdFreeOrSubscription && !application.isSearchBot;
}
