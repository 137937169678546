import { MutationTree, GetterTree } from 'vuex';
// import { footer_navigation } from '~root/tests/data/navigation';
import { State, Footer, Header, Mobile } from '@root/modules/navigation/types/navigation';

export const state: () => State = () => ({
  header: {
    data: {
      top: null,
      top_left: null,
      primary: null,
      bottom: null,
      bottom_mobile: null,
      info: null,
      sitemap: null,
      misc: null,
    },
    actions: {
      search: false,
      sitemap: false,
      profileInfo: false,
      sidebar: false,
    },
    overwrite: {
      bottom: false,
    },
  },
  footer: {
    data: null,
  },
  mobile: {
    data: null,
  },
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getHeaderAction: (state) => (type: string) => {
    return state.header.actions[type];
  },
};

export const mutations: MutationTree<State> = {
  setHeaderNavigation(state, navigation: Header) {
    state.header.data.top = Object.freeze(navigation.top) as Header['top'];
    state.header.data.top_left = Object.freeze(navigation.top_left) as Header['top_left'];
    state.header.data.primary = Object.freeze(navigation.primary) as Header['primary'];
    state.header.data.info = Object.freeze(navigation.info) as Header['info'];
    state.header.data.sitemap = Object.freeze(navigation.sitemap) as Header['sitemap'];
    state.header.data.misc = Object.freeze(navigation.misc) as Header['misc'];

    if (!state.header.overwrite.bottom) {
      state.header.data!.bottom = navigation.bottom;

      if (navigation.bottom_mobile) {
        state.header.data!.bottom_mobile = navigation.bottom_mobile;
      }
    }
  },
  setHeaderBottomNavigation(state, { navigation, overwrite }: { navigation: Header['bottom']; overwrite: boolean }) {
    state.header.overwrite.bottom = overwrite;
    state.header.data.bottom = navigation;

    if (overwrite) {
      state.header.data.bottom_mobile = null;
    }
  },
  setFooterNavigation(state, navigation: Footer) {
    state.footer.data = navigation;
  },
  setMobileNavigation(state, navigation: Mobile[]) {
    state.mobile.data = navigation;
  },
  setHeaderAction(state, actions: { type: string; value: boolean }) {
    const { type, value } = actions;
    // Toggle search or sitemap
    state.header.actions[type] = value;

    // Close sitemap if search is open
    if (type === 'search') {
      state.header.actions.sitemap = false;
    }
  },
};
