/* eslint-disable @typescript-eslint/no-unused-vars */
import { GetChannelConfigFunction, GetChannelConfigTypes } from '../types';
import { Config } from '../../../modules/channel/types/channel';

// Inject into Vue instances (this.$channelConfig)
declare module 'vue/types/vue' {
  interface Vue {
    $channelConfig: GetChannelConfigFunction;
  }
}
// Inject into context (context.$channelConfig)
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $channelConfig: GetChannelConfigFunction;
  }
}
// Inject into store
declare module 'vuex/types/index' {
  interface Store<S> {
    $channelConfig: GetChannelConfigFunction;
  }
}

export default function initChannelConfig(channelConfig: Config) {
  const initChannelConfig = (key: GetChannelConfigTypes) => {
    if (!key || key === 'config') {
      return channelConfig;
    } else {
      return channelConfig[key];
    }
  };

  return initChannelConfig;
}
