import * as Vue from 'vue';
import * as vueRouter from 'vue-router';
import * as vueI18n from 'vue-i18n';
import * as apolloClient from '@apollo/client/core';
import * as cryptoHash from 'crypto-hash';
import dayjs from 'dayjs';
import lodash from 'lodash';

import * as headlinesBlocks from '@headlines/exports';
import * as portalCore from '@core/exports';
import * as portalAds from '@ads/exports';

// This plugin exposes Portal Root dependencies for Custom Pages projects via window property

type PortalImport = {
  Vue: typeof Vue;
  vueRouter: typeof vueRouter;
  vueI18n: typeof vueI18n;
  apolloClient: typeof apolloClient;
  portalCore: typeof portalCore;
  headlinesBlocks: typeof headlinesBlocks;
  portalAds: typeof portalAds;
  dayjs: typeof dayjs;
  cryptoHash: typeof cryptoHash;
  lodash: typeof lodash;
};

interface ExternalizedWindow extends Window {
  PortalRoot: PortalImport | Record<string, unknown>;
}

declare const window: ExternalizedWindow;

// Namespace dependencies under PortalRoot
window.PortalRoot = window.PortalRoot || {};

// All dependencies used in Portal Custom Pages projects
window.PortalRoot.Vue = Vue.default;
window.PortalRoot.vueRouter = vueRouter;
window.PortalRoot.vueI18n = vueI18n;
window.PortalRoot.apolloClient = apolloClient;
window.PortalRoot.portalCore = portalCore;
window.PortalRoot.headlinesBlocks = headlinesBlocks;
window.PortalRoot.portalAds = portalAds;
window.PortalRoot.dayjs = dayjs;
window.PortalRoot.cryptoHash = cryptoHash;
window.PortalRoot.lodash = lodash;
