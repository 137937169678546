
import Vue from 'vue';
import HeaderCustomerMenu from '@root/common/components/layout/header/HeaderCustomerMenu.vue';
import Dropdown from '@core/components/UI/dropdown/Dropdown.vue';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Data {
  forceClose: {
    value: boolean;
    timestamp: string;
  };
}

interface Props {
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  openLoginModal: () => void;
  logout: () => void;
}

interface Methods {
  linkClicked: () => void;
}

interface Computed {
  isFirstNameFilled: boolean;
  userName: string | null;
  backgroundColor: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    HeaderCustomerMenu,
    Dropdown,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      forceClose: {
        value: false,
        timestamp: '',
      },
    };
  },
  computed: {
    isFirstNameFilled() {
      return this.$store.state.piano.profile?.firstName || false;
    },
    userName() {
      if (!this.data.isLoggedIn) {
        return null;
      }

      return this.$store.state.piano.profile?.firstName
        ? this.$store.state.piano.profile?.firstName
        : this.$store.state.piano.extendedProfile?.displayName
        ? this.$store.state.piano.extendedProfile?.displayName
        : this.$store.state.piano.profile?.email.split('@')[0];
    },
    backgroundColor() {
      if (!this.data.isLoggedIn) {
        return null;
      }

      if (this.userName) {
        let hash = 0;
        let bgColor = null;

        for (let i = 0; i < this.userName.length; i += 1) {
          hash = this.userName.charCodeAt(i) + ((hash << 5) - hash);
        }

        // eslint-disable-next-line unicorn/number-literal-case
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();
        bgColor = '00000'.substring(0, 6 - c.length) + c;

        return `#${bgColor}`;
      }

      return null;
    },
  },
  methods: {
    linkClicked() {
      this.forceClose = {
        value: true,
        timestamp: String(new Date().getTime()),
      };
    },
  },
});
