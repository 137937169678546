
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons, HeadlineLabels, HeadlineLead, HeadlineAuthor, HeadlinePublishDate } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType101';

const defaultSettings = {
  icons: true,
  titleComment: true,
  channel: false,
  category: true,
  lead: true,
  publishDate: false,
  author: false,
  adsInject: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    AdFormBanner,
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    HeadlineLabels,
    HeadlineLead,
    HeadlineAuthor,
    HeadlinePublishDate,
  },
});
