import { AccessObject } from '@piano/types/pianoTp';

export const checkUserAccess = (ridArray: string[], userAccesses: AccessObject[]): boolean => {
  let hasAccess = false;

  ridArray.forEach((rid) => {
    if (userAccesses.some((obj) => obj.resource.rid === rid && obj.granted)) {
      hasAccess = true;
    }
  });

  return hasAccess;
};
