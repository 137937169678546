const getEventByKey = <T>(events: Record<string, Record<string, T>>, key: string, locale: string) => {
  const fallbackNamespace = 'base';

  const eventsByNamespace = events[locale] || events[fallbackNamespace] || null;

  if (!eventsByNamespace) {
    return null;
  }

  return <T>eventsByNamespace[key] || null;
};

export default getEventByKey;
