import apolloClient from '../apollo/apolloClient';
import getVideoQuery from '../apollo/graphql/getVideo.graphql';
import { GetVideo } from '../types/getVideo';

const getVideo = async (variables: { id: string }) => {
  const queryOptions = { variables };
  const options = Object.assign({ query: getVideoQuery }, queryOptions);

  const { data } = await apolloClient.query<GetVideo['data']>(options);

  return data;
};

export { getVideo };
