
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType108';
import Swiper from '@core/components/UI/swiper/Swiper.vue';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  type: 'list',
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    Swiper,
  },
  computed: {
    swiperOptions() {
      return {
        spaceBetween: 12,
        initialSlide: 0,
        autoplay: false,
        loop: false,
        slidesPerView: 2,
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          enabled: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        simulateTouch: false,
        breakpoints: {
          768: {
            freeMode: false,
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      };
    },
  },
});
