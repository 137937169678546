import Cookie from 'cookie-universal';
// import { getBaseDomain } from '@root/common/utils/url';

export const setAdBlockCookie = (value: boolean): any => {
  const cookies = Cookie();
  const d = new Date();
  d.setTime(d.getTime() + 60 * 5 * 1000);

  cookies.set('__adblocker', value, {
    // domain: getBaseDomain() || '',
    path: '/',
    expires: d,
  });
};
