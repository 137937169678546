
import Vue from 'vue';
import Simplebar from 'simplebar-vue';

interface Data {
  activeTab: string;
}

interface Props {
  initialTab: number;
  tabs: Record<string, string>;
  scroll: boolean;
}

interface Computed {
  tabPanelSlotName: string;
}

interface Methods {
  switchTab: (tabKey: string) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    // User Simplebar component as HTML attribute 'data-simplebar'.
    // Component should be imported, but eslint thinks it's not used.
    // eslint-disable-next-line vue/no-unused-components
    Simplebar,
  },
  props: {
    initialTab: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Object,
      required: true,
    },
    scroll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeTab: String(this.initialTab),
    };
  },
  computed: {
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`;
    },
  },
  methods: {
    switchTab(tabName) {
      this.activeTab = tabName;
    },
  },
});
