import Vue from 'vue';
import { HeadlineWithRelatedArticles, HeadlineLabel } from '@headlines/types/headline';
import getItemFromObject from 'lodash/get';
import { SwiperOptions } from 'swiper/types/swiper-options';
import categoryUrl from '@root/modules/category/utils/buildCategoryUrl';
import { gridConfig } from '@core/config/grid.config';
import { getRootConfig } from '@core/utils/helpers';
import { politicalAdCategories } from '@headlines/config/icons.config';

interface $nuxtWindow extends Window {
  $nuxt: any;
}

interface Process {
  client: boolean;
  env: any;
}

declare let window: $nuxtWindow;
declare let process: Process;

type Settings<T> = T;
export default <T>(settings: Settings<T>) => {
  interface Methods {
    getGlobalIndex: (index: number, indexOffset: number) => number;
    getItemFromObject: typeof getItemFromObject;
    labels: (headline: HeadlineWithRelatedArticles, domain?: string) => (HeadlineLabel | undefined)[];
    ptContent: (headline: HeadlineWithRelatedArticles) => boolean;
    politicalAd: (headline: HeadlineWithRelatedArticles) => boolean;
  }

  interface Props {
    blockKey: string;
    data: {
      items: HeadlineWithRelatedArticles[];
      groups: {
        name: string;
        title: string;
        items: HeadlineWithRelatedArticles[];
      }[];
      content?: any;
    };
    settings: Settings<T>;
    hasChannelSubscription: boolean;
    index: number;
  }

  interface Computed {
    domain: string;
    fakeDomainEnabled: boolean;
    getSettings: Settings<T>;
    isMobileView: boolean;
    swiperOptions?: SwiperOptions | undefined;
  }

  return Vue.extend<unknown, Methods, Computed, Props>({
    props: {
      blockKey: {
        type: String,
        required: false,
        default: '',
      },
      data: {
        type: Object,
        required: true,
      },
      settings: {
        type: Object,
        required: true,
      },
      hasChannelSubscription: {
        type: Boolean,
        required: false,
        default: false,
      },
      index: {
        type: Number,
        required: false,
      },
    },
    computed: {
      domain() {
        const settings = getRootConfig('settings');
        if (settings) {
          return settings.domain;
        }

        return 'www.delfi.lt';
      },
      fakeDomainEnabled() {
        return typeof process !== 'undefined'
          ? process && process.client
            ? window.$nuxt && window.$nuxt.$config.FAKE_DOMAIN_ENABLED
            : process.env.FAKE_DOMAIN_ENABLED
          : false;
      },
      getSettings() {
        return { ...settings, ...this.settings };
      },
      isMobileView() {
        if (process.client) {
          const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;

          return window.screen.width <= gridConfig.breakpoints.lg || isIpad;
        } else {
          return this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
        }
      },
    },
    methods: {
      getGlobalIndex(index, indexOffset) {
        if (indexOffset && indexOffset > 0) {
          return indexOffset + index;
        }

        return index;
      },
      getItemFromObject,
      labels(headline, domain = 'www.delfi.lt') {
        const labels = [];
        const sameChannel = domain === headline.primaryCategory?.channel.url;
        const fakeDomainEnabled = this.fakeDomainEnabled;

        if (this.$props.settings.labelType) {
          switch (this.$props.settings.labelType) {
            case 'parent-category':
              labels.push({
                title: getItemFromObject(headline, 'primaryCategory.parentCategory.name'),
                href: categoryUrl({ category: headline.primaryCategory.parentCategory, domain, fakeDomainEnabled }),
              });
              break;
            case 'category':
              labels.push({
                title: getItemFromObject(headline, 'primaryCategory.name'),
                href: categoryUrl({ category: headline.primaryCategory, domain, fakeDomainEnabled }),
              });
              break;
            case 'channel':
            default:
              labels.push({
                title: getItemFromObject(headline, 'primaryCategory.channel.name'),
                href: `https://www.delfi.lt/${headline.primaryCategory?.channel?.path || ''}`,
              });
              break;
          }
        } else if (headline.primaryCategory?.channel?.url === 'www.delfi.lt/saugu') {
          labels.push({
            title: 'DELFI INICIATYVA SAUGU',
            href: categoryUrl({ category: headline.primaryCategory, domain, fakeDomainEnabled }),
          });
        } else if (
          !sameChannel &&
          headline.primaryCategory?.channel?.url !== 'www.delfi.lt' &&
          headline.primaryCategory?.channel?.url !== 'www.delfi.lt/projektai' &&
          headline.primaryCategory?.channel?.url !== 'www.delfi.lt/multimedija'
        ) {
          labels.push({
            title: getItemFromObject(headline, 'primaryCategory.channel.name'),
            href: `https://www.delfi.lt/${headline.primaryCategory?.channel?.path || ''}`,
          });
        } else {
          labels.push({
            title: getItemFromObject(headline, 'primaryCategory.name'),
            href: categoryUrl({ category: headline.primaryCategory, domain, fakeDomainEnabled }),
          });
        }

        return labels;
      },
      ptContent(headline: HeadlineWithRelatedArticles) {
        return (
          (headline.settings?.find((setting) => setting.key === 'icon.reklama')?.value ?? false) ||
          (headline.settings?.find((setting) => setting.key === 'icon.sponsored_content')?.value ?? false) ||
          !!headline.settings?.find((setting) => setting.key === 'frontPageSponsoredContent' && setting.value === true) ||
          headline.primaryCategory?.channel?.url === 'www.delfi.lt/uzsakomasis-turinys'
        );
      },
      politicalAd(headline: HeadlineWithRelatedArticles) {
        return (
          politicalAdCategories.includes(headline.primaryCategory?.id) ||
          !!headline.tags?.items?.find((tag) => tag.id === 71865184) ||
          !!headline.settings?.find((setting) => setting.key === 'frontPagePoliticalAd' && setting.value === true)
        );
      },
    },
  });
};
