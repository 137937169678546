import { GetterTree, MutationTree } from 'vuex';
import { AdFormState } from '@ads/types/AdForm';
import { mergeAdsPlacements } from '@ads/utils/mergeAdsPlacements';
import { getDisabledAdsList } from '@ads/utils/getDisabledAdsList';
import adsConfig from '@ads/config/adForm.config';

export const state: () => AdFormState = () => ({
  allowAdsRendering: true,
  enableAdBlockLayer: false,
  disableAdsCondition: {
    adFree: false,
    subscriber: false,
  },
  hasSettings: false,
  default: {
    disableAds: [],
    placements: {},
    mkw: [],
    mkv: [],
    isAdFree: false,
    isApp: false,
    isCustomer: false,
    isLoggedIn: false,
  },
  page: {
    disableAds: [],
    enableAds: [],
    placements: {},
    mkw: [],
    mkv: [],
    uniqueKey: [],
    isAdFree: false,
    isApp: false,
    isCustomer: false,
    isLoggedIn: false,
  },
});

export const getters: GetterTree<AdFormState, Record<string, unknown>> = {
  // Return unique key that ads components can watch and use it for re-rendering their content
  getAdsUniqueKey: (state) => {
    return state.page.uniqueKey;
  },
  setAdsRenderingState: (state) => {
    return state.allowAdsRendering;
  },
  getAdBlockLayerState(state) {
    return state.enableAdBlockLayer;
  },
  // Return default ads keywords merged with page specific ads keywords
  getAdsKeywords: (state) => {
    return [...state.default.mkw, ...state.page.mkw];
  },
  // Return default ads key values merged with page specific ads key values
  getAdsKeyValues: (state) => {
    return [...state.default.mkv, ...state.page.mkv];
  },
  // Return page specific ads placements or faLLback to default ads placements
  getAdsPlacements: (state) => {
    const pagePlacements = state.page.placements;
    const pagePlacementsFound = !(Object.keys(pagePlacements).length === 0 && pagePlacements.constructor === Object);

    return pagePlacementsFound ? pagePlacements : state.default.placements;
  },
  // Return page specific disabled ads list or faLLback to default ads disabled ads list
  getDisabledAdsList: (state) => {
    const pageDisableAds = state.page.disableAds;

    if (state.disableAdsCondition.adFree) {
      return adsConfig.types;
    }

    if (state.disableAdsCondition.subscriber) {
      return pageDisableAds.length > 0 ? pageDisableAds : state.default.disableAds;
    }

    return pageDisableAds;
  },
  getIsAdFree: (state) => {
    return state.default.isAdFree;
  },
  getIsApp: (state) => {
    return state.default.isApp;
  },
  getIsCustomer: (state) => {
    return state.default.isCustomer;
  },
  getIsLoggedIn: (state) => {
    return state.default.isLoggedIn;
  },
  getHasSettings: (state) => {
    return state.hasSettings;
  },
};

export const mutations: MutationTree<AdFormState> = {
  setDefaultAdsSettings(state, adsSettings: AdFormState['default']) {
    const adsData = adsSettings;
    const storedAdsData = state.default;

    storedAdsData.placements = adsData.placements;
    storedAdsData.disableAds = adsData.disableAds;
    storedAdsData.mkw = adsData.mkw;
    storedAdsData.mkv = adsData.mkv;
    storedAdsData.isAdFree = adsData.isAdFree;
    storedAdsData.isApp = adsData.isApp;
    storedAdsData.isCustomer = adsData.isCustomer;
    storedAdsData.isLoggedIn = adsData.isLoggedIn;
  },
  setPageAdsSettings(state, adsSettings: AdFormState['page']) {
    const adsData = adsSettings;
    const storedDefaultAdsData = state.default;
    const storedAdsData = state.page;

    // Merge page specific ads placements with default ads placements
    storedAdsData.placements = mergeAdsPlacements(storedDefaultAdsData.placements, adsData.placements);
    storedAdsData.disableAds = getDisabledAdsList(storedDefaultAdsData.disableAds, adsData.disableAds, adsData.enableAds);
    storedAdsData.mkw = adsData.mkw;
    storedAdsData.mkv = adsData.mkv;
    storedAdsData.uniqueKey = adsData.uniqueKey;

    state.hasSettings = true;
  },
  resetPageAdsSettings(state) {
    const storedAdsData = state.page;
    storedAdsData.placements = {};
    storedAdsData.mkw = [];
    storedAdsData.mkv = [];

    state.hasSettings = false;
  },
  setDisabledAdsCondition(state, { type, disable }: { type: keyof AdFormState['disableAdsCondition']; disable: boolean }) {
    state.disableAdsCondition[type] = disable;
  },
  setAdBlockLayerState(state, enabled: boolean) {
    state.enableAdBlockLayer = enabled;
  },
  setAdsRenderingState(state, enabled: boolean) {
    state.allowAdsRendering = enabled;
  },
};

export const adFormStoreModule = {
  adForm: {
    state,
    getters,
    mutations,
    namespaced: true,
  },
};
