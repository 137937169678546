import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/lt';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('lt');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Vilnius');

const formatDate = (val: string) => val && dayjs(val).tz('Europe/Vilnius').format('YYYY MM DD');
const formatDateWithTime = (val: string) => val && dayjs(val).tz('Europe/Vilnius').format('YYYY.MM.DD HH:mm');
const formatLongDate = (val: string) => val && dayjs(val).tz('Europe/Vilnius').format('YYYY [m.] MMMM DD [d.]');
const formatCustomDate = (val: string | number, formatType: string) => String(val && formatType && dayjs(val).tz('Europe/Vilnius').format(formatType));
const convertToLocal = (val: string) => val && dayjs(val).tz('Europe/Vilnius').format();
const convertToISO = (val: string) => val && dayjs(val).tz('Europe/Vilnius').utc().format();

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateWithTime', formatDateWithTime);
Vue.filter('formatLongDate', formatLongDate);
Vue.filter('convertToLocal', convertToLocal);
Vue.filter('convertToISO', convertToISO);
Vue.filter('formatCustomDate', formatCustomDate);

export { formatDate, formatDateWithTime, formatLongDate, convertToLocal, convertToISO };
