import { render, staticRenderFns } from "./StoryHead.vue?vue&type=template&id=34b3efaf&scoped=true"
import script from "./StoryHead.vue?vue&type=script&lang=ts"
export * from "./StoryHead.vue?vue&type=script&lang=ts"
import style0 from "./StoryHead.vue?vue&type=style&index=0&id=34b3efaf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b3efaf",
  null
  
)

export default component.exports