var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"story-pointers",class:{
    'story-pointers--viewing': _vm.storyIndex === _vm.activeStoryIndex,
    'story-pointers--paused': _vm.storyIndex === _vm.activeStoryIndex && (_vm.isPaused || _vm.isLoading),
  }},[_c('div',{staticClass:"story-pointers__wrap"},_vm._l((_vm.story.items),function(item,itemIndex){return _c('span',{key:`item-${itemIndex}`,staticClass:"story-pointers__item",class:{
        'story-pointers__item--active': itemIndex === _vm.activeItemIndex && _vm.activeStoryIndex === _vm.storyIndex,
        'story-pointers__item--seen': itemIndex < _vm.activeItemIndex && _vm.storyIndex === _vm.activeStoryIndex,
      }},[_c('b',{ref:"pointer",refInFor:true})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }