const ru_LT = {
  authors: 'avtory',
  latest: 'ru/poslednie',
  popular: 'ru/populjarnye',
  search: 'poisk',
  author: 'avtor',
  category: 'kategorija',
  topics: 'temy',
  page: 'stranica',
  client: 'klient',
  article: 'statja',
  comments: 'kommentarii',
  bookmarks: 'zakladka',
};

export default ru_LT;
