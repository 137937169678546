import { Plugin } from '@nuxt/types';
import { BbcTracker } from '@analytics/trackers';

const BbcTrackerPlugin: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!(analytics?.bbcTracker?.partner && analytics?.bbcTracker?.language && analytics?.bbcTracker?.producer)) {
    return;
  }

  const bbcTracker = new BbcTracker();

  await bbcTracker.init(analytics?.bbcTracker);

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        const { externalTracker = [] } = store.state.article.activeArticle.externalTracker;

        if (externalTracker && externalTracker.includes('bbcRussian')) {
          bbcTracker.pageView();
        }
      }
    },
    {
      immediate: true,
    }
  );
};

export default BbcTrackerPlugin;
