
import Vue from 'vue';
import BigPictureWrapper from '@gallery/components/BigPictureWrapper.vue';
import { ImageItem } from '@gallery/types/Gallery';
import ThumbWrapper from '@gallery/components/thumb/ThumbWrapper.vue';
import LightboxInfo from './LightboxInfo.vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { gridConfig } from '@core/config/grid.config';
import axios from 'axios';
import config from '@gallery/config/gallery.config';
import trackGemius from '@gallery/utils/trackGemius';

const showAdAfterNthPicture = config.ads.showAdAfterNthPicture;

interface Data {
  config: Record<string, any>;
  activePictureIndex: number;
  changedPicturesCount: number;
  nextAd: {
    banner: string;
    height: number;
    width: number;
  } | null;
  showThumbWrapper: boolean;
  showMobileDescription: boolean;
  navigationDisabled: boolean;
  galleryTowerKey: string;
}

interface Methods {
  setActivePictureIndex: (index: number) => Promise<void>;
  nativeShare: () => void;
  addPictureRelatedHash: () => void;
  keyboardNavigation: (e: KeyboardEvent) => void;
  handleFullImageAd: () => Promise<boolean>;
  toggleThumbWrapper: () => void;
  setMobileDescription: (show: boolean) => void;
}

interface Computed {
  activePicture: ImageItem;
  showAd: boolean;
  disableFullImageAd: boolean;
  adsAttributes: {
    mkw: string[];
    mkv: Record<string, string>;
  };
  activePictureDescription: string;
  authorsList: string;
}

interface Props {
  pictures: ImageItem[];
  initialIndex: number;
  galleryId: string;
  settings: Record<string, any>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BigPictureWrapper,
    ThumbWrapper,
    LightboxInfo,
    AdFormBanner,
    BaseIcon,
  },
  props: {
    pictures: {
      type: Array,
      required: true,
    },
    initialIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    galleryId: {
      type: String,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      config,
      activePictureIndex: this.initialIndex,
      changedPicturesCount: 0,
      nextAd: null,
      showThumbWrapper: true,
      showMobileDescription: false,
      navigationDisabled: false,
      galleryTowerKey: 'gt-0',
    };
  },
  computed: {
    activePicture() {
      return this.pictures[this.activePictureIndex];
    },
    disableFullImageAd() {
      return this.$store.getters['adForm/getDisabledAdsList']?.includes('content');
    },
    showAd() {
      return Boolean(this.changedPicturesCount === showAdAfterNthPicture && this.nextAd);
    },
    adsAttributes() {
      const keywords: string[] = this.$store.getters['adForm/getAdsKeywords'];
      const keyValues: Record<string, string> = this.$store.getters['adForm/getAdsKeyValues'];

      return {
        mkw: keywords,
        mkv: keyValues,
      };
    },
    activePictureDescription() {
      return this.activePicture?.metadata.credit ? this.activePicture?.metadata.credit : '';
    },
    authorsList(): string {
      return this.activePicture?.metadata.authors ? this.activePicture?.metadata.authors.join(', ') : '';
    },
  },
  methods: {
    async setActivePictureIndex(index) {
      const result = await this.handleFullImageAd();
      if (result) {
        return;
      }

      if (index >= this.pictures.length) {
        this.activePictureIndex = 0;
      } else if (index < 0) {
        this.activePictureIndex = this.pictures.length - 1;
      } else {
        this.activePictureIndex = index;
      }

      this.addPictureRelatedHash();

      // Track Gemius view, if uid is present
      if (this.settings.analytics.gemius) {
        trackGemius(this.settings.analytics.gemius);
      }
    },
    addPictureRelatedHash() {
      const hash = new URLSearchParams();
      hash.append('gallery-id', this.galleryId);
      hash.append('image-id', this.activePicture.id);

      history.replaceState(null, '', `#${hash.toString()}`);
    },
    nativeShare() {
      window.navigator.share({
        text: this.activePicture.metadata.title || '',
        url: window.location.href,
      });
    },
    keyboardNavigation(e) {
      switch (e.key) {
        case 'ArrowLeft':
          this.setActivePictureIndex(this.activePictureIndex - 1);
          break;
        case 'ArrowRight':
          this.setActivePictureIndex(this.activePictureIndex + 1);
          break;
        case 'Escape':
          this.$emit('close-lightbox');
          break;
      }
    },
    async handleFullImageAd() {
      const isDesktop = window.screen.width > gridConfig.breakpoints.lg;

      if (this.disableFullImageAd) {
        return false;
      }

      this.changedPicturesCount++;

      if (this.changedPicturesCount === showAdAfterNthPicture - 2 && !isDesktop) {
        const mid = config.ads.mobile;
        const mkv = Object.keys(this.adsAttributes.mkv)
          .map((key) => {
            return `${encodeURIComponent(key)}:${encodeURIComponent(this.adsAttributes.mkv[key])}`;
          })
          .join(',');

        const adFormUrl = new URL('https://adx.adform.net/adx/');
        adFormUrl.searchParams.append('mid', String(mid));
        adFormUrl.searchParams.append('mkw', this.adsAttributes.mkw.join(','));
        adFormUrl.searchParams.append('mkv', mkv);
        adFormUrl.searchParams.append('callback', 'show');

        try {
          const { data } = await axios.get(adFormUrl.href);
          const adObject = JSON.parse(data.substring(5, data.length - 2));

          if (adObject?.banner) {
            const isWidthAndHeightValid = Number.isInteger(adObject.width) && Number.isInteger(adObject.height);

            this.nextAd = {
              banner: adObject.banner,
              height: isWidthAndHeightValid ? adObject.height : 1080,
              width: isWidthAndHeightValid ? adObject.width : 1920,
            };
          }
        } catch {
          console.error('Gallery: Could not query for ad');
        }
      }

      if (this.showAd && !isDesktop) {
        return true;
      }

      if (this.changedPicturesCount === showAdAfterNthPicture) {
        const index = Number(this.galleryTowerKey.replace('gt-', ''));
        this.galleryTowerKey = `gt-${index + 1}`;
      }

      if (this.changedPicturesCount === showAdAfterNthPicture + 1) {
        this.changedPicturesCount = 0;
        this.nextAd = null;
      }

      return false;
    },
    toggleThumbWrapper() {
      this.showThumbWrapper = !this.showThumbWrapper;
    },
    setMobileDescription(show) {
      this.showMobileDescription = show;
    },
  },
  mounted() {
    this.addPictureRelatedHash();
    document.addEventListener('keydown', this.keyboardNavigation);
  },
});
