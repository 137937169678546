
import Vue from 'vue';
import AdFormBanner from './AdFormBanner.vue';
import { AdFormOptions } from '@ads/types/AdForm';
import { isMobileOrTablet } from '@ads/utils/adx';

interface Props {
  settings: AdFormOptions;
}

interface Data {
  hasSiteHeader: boolean;
  showSiteheader: boolean;
  observer: MutationObserver | null;
}

interface Methods {
  handleSiteHeader: () => void;
}

interface Computed {
  isMobileOrTablet: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AdFormBanner,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      hasSiteHeader: false,
      showSiteheader: false,
      observer: null,
    };
  },
  computed: {
    isMobileOrTablet() {
      return isMobileOrTablet();
    },
  },
  watch: {
    hasSiteHeader(value) {
      if (value) {
        if (!this.isMobileOrTablet) {
          setTimeout(() => {
            window.scrollTo(0, 1);
            window.scrollTo(0, 0);
          }, 100);
        }

        window.document.body.classList.add('has-siteheader');
        window.document.documentElement.style.setProperty('--delfi-topbar-height', `${this.isMobileOrTablet ? 125 : 400}px`);
        window.addEventListener('scroll', this.handleSiteHeader);

        const closeButton = window.document.getElementById('siteheader-close-button') as HTMLElement;
        const closeButtonImage = closeButton.querySelector('img') as HTMLElement;
        closeButtonImage.addEventListener('click', () => {
          console.log('siteHeader click triggered');
          window.document.documentElement.style.setProperty('--delfi-topbar-height', `0px`);
          window.document.body.classList.remove('has-siteheader');
          window.removeEventListener('scroll', this.handleSiteHeader);
          this.showSiteheader = false;
        });

        this.showSiteheader = true;
      }
    },
  },
  mounted() {
    const callback = (mutations: any) => {
      this.$nextTick(() => {
        if (this.isMobileOrTablet) {
          const mutation = mutations[0] as MutationRecord;
          const nodes = mutation.addedNodes as NodeList;
          const node = nodes[0];
          if (node && node.nodeName === 'ADFM-AD') {
            this.hasSiteHeader = true;
          }
        } else {
          const mutation = mutations[0] as MutationRecord;
          const nodes = mutation.addedNodes as NodeList;
          const node = nodes[0];
          if (node && node.nodeName === 'IFRAME' && document.getElementById('delfi-adx-siteheader')?.querySelector('iframe')) {
            this.hasSiteHeader = true;
          }
        }
      });
    };

    const siteheader = this.$refs.siteheader as any;
    this.observer = new MutationObserver(callback);
    this.observer.observe(siteheader.$el, { subtree: true, childList: true });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  destroyed() {
    window.document.body.classList.remove('has-siteheader');
    window.document.documentElement.style.setProperty('--delfi-topbar-height', `0px`);
    window.removeEventListener('scroll', this.handleSiteHeader);
  },
  methods: {
    handleSiteHeader() {
      const isSiteHeaderShown = window.document.body.classList.contains('has-siteheader');

      if (this.isMobileOrTablet) {
        const adfmAd = window.document.querySelector('adfm-ad') as HTMLElement;
        const adfmAdIframe = adfmAd.querySelector('iframe') as any;
        const adfmAdIframeCollapsed = adfmAdIframe.contentWindow.document.body.querySelector('#collapsed') as HTMLElement;
        const adfmAdIframeExpanded = adfmAdIframe.contentWindow.document.body.querySelector('#expanded') as HTMLElement;

        if (isSiteHeaderShown) {
          if (window.pageYOffset < 50) {
            window.document.documentElement.style.setProperty('--delfi-topbar-height', `${125 - window.pageYOffset}px`);
            adfmAdIframeCollapsed.style.display = 'none';
            adfmAdIframeExpanded.style.display = 'block';
          } else {
            window.document.documentElement.style.setProperty('--delfi-topbar-height', `50px`);
            adfmAdIframeCollapsed.style.display = 'block';
            adfmAdIframeExpanded.style.display = 'none';
          }
        }
      } else {
        const iframe = document.getElementById('delfi-adx-siteheader')?.querySelector('iframe') as any;
        const iframeBody = iframe.contentWindow.document.body as HTMLElement;
        iframeBody.style.float = 'none';

        if (isSiteHeaderShown) {
          if (window.pageYOffset < 300) {
            iframeBody.style.height = `${400 - window.pageYOffset}px`;
            window.document.documentElement.style.setProperty('--delfi-topbar-height', `${400 - window.pageYOffset}px`);
          } else if (isSiteHeaderShown) {
            iframeBody.style.height = '100px';
            window.document.documentElement.style.setProperty('--delfi-topbar-height', `100px`);
          }
        }
      }
    },
  },
});
