import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const excludeSpecialProject: AdminBlockTypeAttribute = {
  label: 'Exclude special project',
  key: 'excludeSpecialProject',
  inputType: AdminBlockAttributeInputType.CHECKBOX,
  defaultValue: false,
};

export default excludeSpecialProject;
