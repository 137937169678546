
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import Icon from '@root/modules/weather/components/Icon.vue';
import WeatherService from '@root/modules/weather/services/Weather.service';
import { weatherIconParser } from '@root/modules/weather/utils/weatherParser';
import { ListData } from '@root/modules/navigation/types/navigation';

interface Data {
  placeId: string;
  weather: Record<string, any> | null;
}

interface Computed {
  temperature: string | null;
  icon: string | null;
}

interface Methods {
  getWeather: () => void;
  weatherIconParser: typeof weatherIconParser;
}

interface Props {
  link: ListData;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'WeatherIcon',
  components: {
    LinkHandler,
    Icon,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placeId: 'ChIJ9QhkXPuT3UYRQNzpcIzRAAQ',
      weather: null,
    };
  },
  computed: {
    temperature() {
      let temperature = null;

      if (this.weather) {
        temperature = this.weather.currentWeather.data.instant.details.air_temperature.toFixed(0);
      }

      return temperature;
    },
    icon() {
      let icon = null;

      if (this.weather) {
        icon = this.weather.currentWeather.data.next_1_hours.summary.symbol_code;
      }

      return icon;
    },
  },
  async created() {
    this.getWeather();
  },
  methods: {
    async getWeather() {
      const weatherService = new WeatherService();
      const data = await weatherService.fetch({ hourlyTime: 0, dailyTime: 0, placeId: this.placeId, ttl: 3600 });
      this.weather = data;
    },
    weatherIconParser,
  },
});
