
import { HeadlineTitle, HeadlineImage, HeadlineIcons, HeadlinePublishDate, HeadlineLabels } from '../../fragments';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType6';

const defaultSettings = {
  channel: false,
  icons: true,
  titleComment: false,
  publishDate: true,
  contentSeparator: false,
  adsInject: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    HeadlinePublishDate,
    HeadlineLabels,
    AdFormBanner,
  },
});
