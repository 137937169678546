import { NuxtAppOptions } from '@nuxt/types';
import { Resources } from '../types';
import { Config } from '../../../modules/channel/types/channel';
import Meta from '@root/common/utils/Meta';
import { getProductionStyles, initDevelopmentStyles } from './initStyles';
interface NuxtMeta {
  [x: string]: string;
}

const initMetaInfo = async (app: NuxtAppOptions, channelConfig: Config, resource: Resources) => {
  const { meta: metaData, ...restOfHeadTags } = channelConfig.meta.headTags;
  let appHead: Record<string, any> | null = Object.assign({}, app.head);
  let meta: Meta | null = new Meta(channelConfig.meta.base);
  let metaInfo: Record<string, any> | null = Object.assign({}, { title: meta.title, meta: [...meta.data, ...metaData], ...restOfHeadTags });

  if (process.env.NODE_ENV === 'production') {
    const channelStyles = getProductionStyles(app.$config, resource, channelConfig.settings.theme);
    metaInfo!.link.push(...channelStyles);
  } else if (process.env.NODE_ENV === 'development') {
    await initDevelopmentStyles(channelConfig.settings.theme);
  }

  if (typeof channelConfig.meta === 'undefined') {
    return;
  }

  // Merge initial nuxt.config meta with Portal Root  api config
  Object.keys(appHead).forEach((key: string) => {
    const headPart: NuxtMeta[] = appHead![key];
    const metaPart: NuxtMeta[] | string = metaInfo![key];

    if (typeof metaPart === 'object' && typeof headPart === 'object') {
      const newPart: NuxtMeta[] = [];
      metaPart.forEach((mp: NuxtMeta) => {
        const tagIndex: number = headPart.findIndex((hp: NuxtMeta) => hp?.hid && mp?.hid && hp.hid === mp.hid);

        if (tagIndex !== -1) {
          delete headPart[tagIndex];
        }
        newPart.push(mp);
      });
      appHead![key] = [...headPart, ...newPart].filter(Boolean);
    } else if (typeof metaPart === 'string' && headPart) {
      appHead![key] = metaPart;
    }
  });

  app.head = appHead;

  // Reset variables for memory optimization
  appHead = null;
  meta = null;
  metaInfo = null;
};

export default initMetaInfo;
