
import Vue from 'vue';
import { AudioItem } from '@media/types/getAudio';
import { BaseButton } from '@core/exports';

interface Data {
  trackChanged: boolean;
  moreEpisodesLoaded: boolean;
  activeEpisode: string;
}

interface Methods {
  loadMoreEpisodes: () => void;
  handleTrackChange: (id: string) => void;
}

interface Props {
  activeEpisodeId: string;
  playlist: AudioItem[];
  doLoadMore: boolean;
}

interface Computed {
  activeEpisode: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseButton,
  },
  props: {
    playlist: {
      type: Array,
      required: true,
    },
    activeEpisodeId: {
      type: String,
      required: false,
    },
    doLoadMore: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      moreEpisodesLoaded: false,
      trackChanged: false,
      activeEpisode: this.activeEpisodeId,
    };
  },
  methods: {
    loadMoreEpisodes() {
      const playlist = document.querySelector('.audio-player-playlist');
      if (playlist) {
        this.moreEpisodesLoaded = true;
        playlist.scroll({
          top: 116,
          behavior: 'smooth',
        });
      }
    },
    handleTrackChange(id) {
      this.$emit('handle-track-change', id);
      this.activeEpisode = id;
    },
  },
  watch: {
    doLoadMore() {
      if (this.doLoadMore) {
        this.loadMoreEpisodes();
      }
    },
  },
});
