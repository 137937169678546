
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType102';

import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import Grid1 from './Grid1.vue';
import Grid22 from './Grid22.vue';
import Grid23 from './Grid23.vue';
import Grid3 from './Grid3.vue';
import Grid4 from './Grid4.vue';

import { Headline } from '@headlines/types/headline';

interface DataWithSettings {
  data: {
    items: Headline[];
  };
  settings: BlockTypeSettings;
}

interface Computed {
  blocks: {
    name: string;
    data: {
      items: Headline[];
    };
    settings: BlockTypeSettings;
  }[];
  mergedSettings: BlockTypeSettings;
  ads: Record<string, any>[];
}

interface Methods {
  getBlockData: (items: Headline[], amount: number) => DataWithSettings;
}

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: false,
  channel: false,
  category: true,
  adsInject: null,
  content: null,
  items: [],
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, Computed, unknown>({
  components: {
    AdFormBanner,
    Grid1,
    Grid22,
    Grid23,
    Grid3,
    Grid4,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    blocks() {
      const { getBlockData, data } = this;
      const items = [...data.items];

      const blocks = [];
      const itemsCount = items.length;
      const itemsMod = itemsCount % 5;
      const fullBlockItems = itemsCount - itemsMod;
      const modItems = itemsMod;

      switch (itemsMod) {
        case 4:
          if (itemsCount === modItems) {
            blocks.push({ name: 'Grid4', ...getBlockData(items, modItems) });
          } else {
            blocks.push({ name: 'Grid23', ...getBlockData(items, fullBlockItems) });
            blocks.push({ name: 'Grid22', ...getBlockData(items, modItems) });
          }
          break;
        case 3:
          blocks.push({ name: 'Grid23', ...getBlockData(items, fullBlockItems) });
          blocks.push({ name: 'Grid3', ...getBlockData(items, modItems) });
          break;
        case 2:
          blocks.push({ name: 'Grid23', ...getBlockData(items, fullBlockItems) });
          blocks.push({ name: 'Grid22', ...getBlockData(items, modItems) });
          break;
        case 1:
          blocks.push({ name: 'Grid1', ...getBlockData(items, modItems) });
          blocks.push({ name: 'Grid23', ...getBlockData(items, fullBlockItems) });
          break;
        default:
          blocks.push({ name: 'Grid23', ...getBlockData(items, fullBlockItems) });
      }

      return blocks;
    },
    mergedSettings() {
      let adsInject = this.getSettings?.adsInject || null;
      const adsInjectSetting: any = this.data.content?.adsInject;
      if (adsInjectSetting) {
        adsInject = JSON.parse(adsInjectSetting);
      }

      return { ...this.getSettings, ...this.settings, ...this.data, adsInject };
    },
    ads() {
      const ads: Record<string, any>[] = [];

      if (this.mergedSettings.content?.ads && this.mergedSettings.content?.ads !== '') {
        const adsArray: string[] = this.mergedSettings.content?.ads.split(',') || [];

        if (adsArray.length) {
          adsArray.forEach((item: string) => {
            const ad = item.split('/');
            const mid = ad[0].split(':');
            ads.push({ mid: mid[0], fallback: mid[1] || 'null', type: ad[1] || 'tower' });
          });
        }
      }

      return ads;
    },
  },
  methods: {
    getBlockData(items, amount) {
      return {
        data: { items: items.splice(0, amount) },
        settings: this.getSettings,
      };
    },
  },
});
