import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';
import { GtagOptions } from './types/Gtag';
import loadGtagScript from './utils/loadGtagScript';
import { loadScripts } from '@analytics/util/scriptLoader';

declare let window: Awindow;

export default class Gtag implements Analytics {
  private gtagUrl = 'https://www.googletagmanager.com/gtag/js?id=';

  private options!: GtagOptions;

  public init(options: GtagOptions) {
    if (!options.containerId) {
      logger.error('Gtag container id not provided');
      return;
    }
    this.options = options;
    this.loadScript();
  }

  public event(eventName: string, eventOptions: Record<string, unknown> = {}) {
    if (window && window.gtag && typeof window.gtag === 'function') {
      window.gtag('event', eventName, eventOptions);
    }
  }

  private loadScript() {
    const { containerId } = this.options;
    loadScripts(this.gtagUrl + containerId, { async: true });
    loadGtagScript(containerId);
  }
}
