
import Vue from 'vue';
import { NuxtError } from '@nuxt/types';
import { TranslateResult } from 'vue-i18n';
import Meta from '@root/common/utils/Meta';
import rootConfig from '@root/config/root.config';
import { gridConfig } from '@core/config/grid.config';

interface CustomNuxtError extends NuxtError {
  debug?: Record<string, unknown>;
}

interface Data {
  mounted: boolean;
  isMobileView: boolean;
}

interface Props {
  error: CustomNuxtError;
}

interface Computed {
  errorMessage: TranslateResult;
  errorImage: string;
  isFrontpage: boolean;
  frontPageUrl: string;
}

interface Methods {
  getTranslation: (message: string) => TranslateResult;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    const settings = this.$channelConfig('meta').base;
    const meta = new Meta(this.$channelConfig('meta').base, this.$route.path);
    meta.setTitle({ title: `${this.errorMessage} - ${settings.title}` });

    return {
      title: meta.title,
    };
  },
  data() {
    return {
      mounted: false,
      isMobileView: false,
    };
  },
  computed: {
    errorMessage() {
      const msg = this.error.statusCode && this.error.statusCode.toString().startsWith('4') ? this.$t('error.page_not_found') : this.$t('error.page_500');
      return msg;
    },
    errorImage() {
      const errorNo = this.error.statusCode && this.error.statusCode.toString().startsWith('4') ? '404' : '500';
      const device = this.isMobileView ? 'mobile' : 'desktop';
      return errorNo + '-' + device;
    },
    isFrontpage() {
      return this.$route.name === 'frontpage';
    },
    frontPageUrl() {
      const frontPageUrl = rootConfig.main_portal_url ? rootConfig.main_portal_url : 'https://www.delfi.lt';
      return frontPageUrl;
    },
  },
  mounted() {
    const { error } = this;

    this.mounted = true;

    console.error(`Error: ${error?.statusCode}`, error);

    if (error?.statusCode && !rootConfig.sentryIgnoredCodes.includes(error?.statusCode)) {
      this.$sentry.captureException(error, {
        tags: { 'process.server': process.server ? 'server' : 'client' },
        contexts: { vue: { componentName: 'error.vue layout' } },
      });
    }

    if (process.client) {
      const screenWidth = window.screen.width;
      const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;
      this.isMobileView = screenWidth <= gridConfig.breakpoints.lg || isIpad;
    } else {
      this.isMobileView = this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
    }
  },
  methods: {
    getTranslation(message) {
      return this.$te(message) ? this.$t(message) : this.$t('error.page_not_found');
    },
  },
});
