import { Plugin } from '@nuxt/types';
import { setPortalCoreSettings } from '@core/utils/initSettings';

const portalCore: Plugin = async ({ app: { $channelConfig } }) => {
  const { pullout, sidebar } = $channelConfig('page').article.component;

  setPortalCoreSettings({
    component: {
      pullout: {
        primaryColor: pullout?.primaryColor,
        icon: pullout?.icon,
      },
      sidebar: {
        border: sidebar?.border,
        hasBorderTop: sidebar?.hasBorderTop,
        hasBg: sidebar?.hasBg,
        isLeadSeparated: sidebar?.isLeadSeparated,
      },
      featured: {
        border: sidebar?.border,
        hasBorderTop: sidebar?.hasBorderTop,
        hasBg: sidebar?.hasBg,
      },
      headline: {
        title: {
          facebook: {
            minShareCount: 20,
          },
        },
      },
    },
  });
};

export default portalCore;
