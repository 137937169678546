import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const excludeContentMarketing: AdminBlockTypeAttribute = {
  label: 'Exclude content marketing',
  key: 'excludeContentMarketing',
  inputType: AdminBlockAttributeInputType.CHECKBOX,
  defaultValue: false,
};

export default excludeContentMarketing;
