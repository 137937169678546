import BlockType18 from '@headlines/components/block/11-20/BlockType18.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType18: AdminBlockType = {
  component: BlockType18,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    channel: true,
    category: true,
    lead: false,
    pictureShadowColor: '',
    author: false,
  }),
  articlesCount: 3,
};

export default AdminBlockType18;
