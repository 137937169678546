import AdminAttributesCollection, { AdminAttributeType, AllowedAttributes } from '@headlines/config/block/shared/attributes/AdminAttributesCollection';
import clone from 'lodash/clone';
import forOwn from 'lodash/forOwn';
import { AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const AdminBlockTypeAttributes = (initialAttributes: AdminAttributeType) => {
  const attributes: AdminBlockTypeAttribute[] = [];
  forOwn(initialAttributes, (value, key) => {
    const attribute = clone(AdminAttributesCollection[key as AllowedAttributes]);
    attribute.defaultValue = value;
    attributes.push(attribute);
  });
  return attributes;
};

export default AdminBlockTypeAttributes;
