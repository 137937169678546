import { Plugin } from '@nuxt/types';
import { AdFormOptions } from '@ads/types/AdForm';

import getAdBlockLayerState from '@root/modules/ads/utils/getAdBlockLayerState';
// eslint-disable-next-line import/named
import { detectAnyAdblocker } from 'vue-adblock-detector';

const portalAds: Plugin = async (ctx) => {
  const { store, app } = ctx;
  // Access channel settings
  const { application } = app.$channelConfig('settings');
  // Access default banner placements
  const placements: Record<string, AdFormOptions> | null = { ...app.$channelConfig('ads').component.adForm.placements };

  // Access paywall settings
  const { channelAccess, adFree } = store.state.piano.access;

  // detect native app
  const isApp = application.type?.includes('NativeApp');

  const defaultAdsSettings = ({ isAdFree, isCustomer, isLoggedIn }: { isAdFree: boolean; isCustomer: boolean; isLoggedIn: boolean }) => {
    const mkw = ['channel_vue', `logged-${isLoggedIn ? 'in' : 'out'}`];

    if (isCustomer) {
      mkw.push('subscriber');
    }

    return {
      mkw,
      mkv: [],
      placements,
      disableAds: [],
      isAdFree,
      isApp,
      isLoggedIn,
      isCustomer,
    };
  };

  const adBlockEnabled = await detectAnyAdblocker();
  const enableAdBlockLayer = getAdBlockLayerState({ application, adBlockEnabled, hasChannelAccess: channelAccess, adFree });
  store.commit('adForm/setAdBlockLayerState', enableAdBlockLayer);

  const isScriptInitedWatcher = store.watch(
    (state) => state.piano.isScriptInited,
    async (isScriptInited: boolean) => {
      if (isScriptInited) {
        const isLoggedIn = store.state.piano.isLoggedIn;
        const { channelAccess, adFree } = store.state.piano.access;

        store.commit('adForm/setAdsRenderingState', true);
        store.commit('adForm/setDefaultAdsSettings', defaultAdsSettings({ isAdFree: adFree, isLoggedIn, isCustomer: channelAccess }));
        store.commit('adForm/setAdBlockLayerState', enableAdBlockLayer);
        isScriptInitedWatcher();
      } else {
        store.commit('adForm/setAdsRenderingState', false); // TODO is this needed?
      }
    },
    {
      immediate: true,
    }
  );

  // Update ads settings when customer logs in and has access to channel or has adFree package
  store.watch(
    (state) => state.piano.access,
    async (access) => {
      const { channelAccess, adFree } = access;

      store.commit('adForm/setAdsRenderingState', true);
      store.commit('adForm/setDisabledAdsCondition', { type: 'subscriber', disable: channelAccess });
      store.commit('adForm/setDisabledAdsCondition', { type: 'adFree', disable: adFree });

      const isLoggedIn = store.state.piano.isLoggedIn;
      store.commit('adForm/setDefaultAdsSettings', defaultAdsSettings({ isAdFree: adFree, isLoggedIn, isCustomer: channelAccess }));
    }
  );
};

export default portalAds;
