
import { Fragment } from './Fragment';
import { loadScript, Settings as LoadScriptSettings, getBaseDomain } from '@core/utils/helpers';
import srcConfig from '@core/config/src.config';
import whitelistConfig from '@core/config/whitelist.config';

import { CoreWindow } from '@core/types/window';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code', 'type', 'id']);

const FragmentComponent = fragment.createComponent();

// TODO: executeScript is it necessary?
export default FragmentComponent.extend({
  name: 'FragmentEmbed',
  async mounted() {
    const { code, type, id } = this;

    if (!code || !type) {
      return;
    }

    const src = srcConfig[type];

    this.setMountedState(true);

    await this.$nextTick();

    window.addEventListener('message', (event) => this.handleDocHeightMsg(event), false);

    if (src) {
      await this.loadScriptWithSrc({ src, id });
    } else {
      this.executeScript({ code });
    }

    this.reInitScripts(type);
  },
  destroyed() {
    window.removeEventListener('message', this.handleDocHeightMsg);
  },
  methods: {
    async loadScriptWithSrc({ src, id }: { src: string; id?: number }) {
      const loadScriptSettings: LoadScriptSettings = {
        attr: {
          async: true,
        },
        script: src,
      };

      if (id) {
        loadScriptSettings.attr.id = id;
      }

      await loadScript(loadScriptSettings);
    },
    executeScript({ code }: { code: string }) {
      const regExp = /<script\b[^>]*>(.*?)<\/script>/gm;
      const scripts = code.match(regExp);

      if (!scripts) {
        return;
      }

      scripts.forEach(async (script: string) => {
        const code = script.replace(/<\/?script\b[^>]*>/g, '');
        if (code) {
          eval(code);
        } else {
          const srcRegEx = /src="?(.*?)["> ]/g;
          const source = srcRegEx.exec(script);

          if (source && source.length > 0) {
            const loadScriptSettings: LoadScriptSettings = {
              attr: {
                async: true,
              },
              script: source[1],
            };

            await loadScript(loadScriptSettings);
          }
        }
      });
    },
    // Some embed services on SPA pages have to be re-inited,
    // because on first page load whole SDK was loaded, and after that only rendering functions have to triggered
    reInitScripts(type: string) {
      // FIXME:
      const win = window as unknown as CoreWindow;
      switch (type) {
        case 'playbuzz':
          if (win?.Playbuzz?.render) {
            win.Playbuzz.render();
          }
          break;
        case 'instagram':
          if (win?.instgrm?.Embeds?.process) {
            win.instgrm.Embeds.process();
          }
          break;
        case 'imgur':
          if (win?.imgurEmbed?.createIframe) {
            win.imgurEmbed.createIframe();
          }
          break;
        case 'twitter':
          if (win?.twttr?.widgets?.load) {
            win.twttr.widgets.load();
          }
          break;
      }
    },
    // Change iframes height dynamically
    handleDocHeightMsg(event: MessageEvent) {
      const origin = getBaseDomain();
      // Accept only delfi domains
      const isWhitelisted = whitelistConfig.some((domain) => origin === domain);

      if (isWhitelisted) {
        try {
          if (event.data.id && event.data.docHeight) {
            const el = document.getElementById(event.data.id);

            if (el) {
              el.style.height = `${event.data.docHeight}px`;
            }
          }
        } catch (error) {
          return false;
        }
      }
      return true;
    },
  },
});
