
import Vue from 'vue';
import { Headline } from '@headlines/types/headline';

interface Props {
  authors: Headline['authors'];
}

interface Computed {
  authorNames: string | boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  name: 'HeadlineAuthor',
  props: {
    authors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorNames() {
      const names: Array<string> = [];

      if (this.authors.items.length) {
        this.authors.items.forEach((author) => {
          names.push(author.name);
        });
      }

      return names.join(', ') || false;
    },
  },
});
