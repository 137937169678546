/* tslint:disable: object-literal-sort-keys */
// FIXME: use proper logger
export default {
  error: console.error || console.log,
  warn: console.warn || console.log,
  info: console.info || console.log,
  verbose: console.log,
  debug: console.debug || console.log,
  silly: console.log,
};
