import { DataWithHref, GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';
import UniversalHeadlinesService from '@headlines/services/UniversalHeadlines.service';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import Service from '@root/common/base/Service';

interface HeadlinesWithHref {
  items: DataWithHref[];
  pager: GetUniversalHeadlines['data']['headlines']['pager'];
}

interface FetchContext {
  domain?: string;
  fakeDomainEnabled?: boolean;
}

interface PaywallApiResponse {
  data: [];
}

export default class GetMostHeadlinesService {
  private _universalHeadlinesService: UniversalHeadlinesService;

  constructor() {
    this._universalHeadlinesService = new UniversalHeadlinesService();
  }

  private async getMostPurchasedIds(isDev: Boolean = false) {
    const service = new Service();
    const apiProvider = service.createProvider('HTTP');
    try {
      const { data } = await apiProvider.request<PaywallApiResponse>({ url: `${isDev ? process.env.DEV_PATH : ''}/api/get-most-purchased` });

      return typeof data !== 'undefined' ? data : [];
    } catch (error) {
      return [];
    }
  }

  private async getHeadlines(variables: GetUniversalHeadlines['variables'], context?: FetchContext): Promise<HeadlinesWithHref> {
    try {
      const headlines = await this._universalHeadlinesService.fetch(variables);
      const headlinesWithHref: HeadlinesWithHref = {
        items: [],
        pager: headlines.pager,
      };

      headlinesWithHref.items = headlines.items.map((headline) => {
        return getHeadlineWithHref({
          headline,
          domain: context?.domain || '',
          fakeDomainEnabled: context?.fakeDomainEnabled || false,
        });
      });

      return headlinesWithHref;
    } catch {
      return {
        items: [],
        pager: {
          offset: 0,
          count: 0,
        },
      };
    }
  }

  private resort(items: DataWithHref[], ids: number[]) {
    if (!ids.length) {
      return items;
    }

    return items.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
  }

  public async fetch(variables: GetUniversalHeadlines['variables'], context?: FetchContext) {
    const mostPurchasedIds = await this.getMostPurchasedIds(context?.fakeDomainEnabled || false);
    const excludeCategories: number[] = [89789159];
    const excludeChannels: string[] = ['www.delfi.lt/ru', 'www.delfi.lt/en', 'www.delfi.lt/elta', 'www.delfi.lt/uzsakomasis-turinys'];

    const mostReadHeadlines = await this.getHeadlines(
      { ...variables, excludeCategories, excludeChannels, orderBy: 'VIEWS', orderOptions: { viewsSince: 'SINCE_24_HOURS' } },
      context
    );
    const mostCommentedHeadlines = await this.getHeadlines(
      { ...variables, excludeCategories, excludeChannels, orderBy: 'COMMENTS', orderOptions: { commentsSince: 'SINCE_24_HOURS' } },
      context
    );
    const mostSharedHeadlines = await this.getHeadlines(
      { ...variables, excludeCategories, excludeChannels, orderBy: 'SHARES', orderOptions: { sharesSince: 'SINCE_24_HOURS' } },
      context
    );
    const mostViewedHeadlines = await this.getHeadlines(
      { ...variables, domain: 'www.delfi.lt/video', excludeCategories, orderBy: 'VIEWS', orderOptions: { viewsSince: 'SINCE_24_HOURS' } },
      context
    );
    const mostPurchasedHeadlines = await this.getHeadlines({ ...variables, id: mostPurchasedIds }, context);

    return [
      { name: 'mostRead', title: '', items: mostReadHeadlines.items },
      { name: 'mostCommented', title: '', items: mostCommentedHeadlines.items },
      { name: 'mostShared', title: '', items: mostSharedHeadlines.items },
      { name: 'mostViewed', title: '', items: mostViewedHeadlines.items },
      { name: 'mostPurchased', title: '', items: this.resort(mostPurchasedHeadlines.items, mostPurchasedIds) },
    ];
  }
}
