export type FieldsObject = UniversalAnalytics.FieldsObject;
export interface AnalyticsFieldsObject {
  allowAdFeatures?: boolean;
  allowAdPersonalizationSignals?: boolean;
  path?: string;
}

export default abstract class GoogleAnalyticsBase {
  public abstract init(): void;
  public abstract setName(name: string): void;
  public abstract create(
    trackingId: string,
    name?: string | null,
    extraFields?: FieldsObject | AnalyticsFieldsObject,
  ): void;

  public abstract pageView(
    url?: string,
    title?: string,
    extraFields?: FieldsObject | AnalyticsFieldsObject,
  ): void;

  public abstract event(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    extraFields?: FieldsObject | AnalyticsFieldsObject,
  ): void;

  public abstract set(data: FieldsObject | AnalyticsFieldsObject): void;
  public abstract setVariable(
    key: keyof AnalyticsFieldsObject | keyof FieldsObject,
    value: string | number,
  ): void;
}

export const enum GoogleAnalyticsLibra {
  Ga = 'Ga',
  Analytics = 'Analytics',
}
