
import Vue from 'vue';
import { TooltipPayload } from '@core/types/Tooltip';

interface Data {
  showMessage: boolean;
  timer: ReturnType<typeof setTimeout> | undefined;
}

interface Props {
  tooltip: TooltipPayload;
}

interface Methods {
  clearTimer(): void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    tooltip: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showMessage: false,
      timer: undefined,
    };
  },
  watch: {
    tooltip() {
      this.showMessage = true;
      this.timer = setTimeout(() => {
        this.showMessage = false;
        this.clearTimer();
      }, 1500);
    },
  },

  methods: {
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = undefined;
    },
  },
});
