
import BaseBlock from '@headlines/utils/BaseBlock';
import BaseButton from '@core/components/UI/BaseButton.vue';
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons } from '../../fragments';
import ContentFragment from '@core/components/ContentFragment.vue';
import BlockTabs from '../../BlockTabs.vue';
import HeadlineTitleList from '../../HeadlineTitleList.vue';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType10';

interface Methods {
  executeScript: (script: string) => void;
}

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  initialTab: 1,
  showTabsComments: true,
  mobileHeadlineListLimit: 5,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, unknown, unknown>({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    BlockTabs,
    HeadlineTitleList,
    BaseButton,
    ContentFragment,
  },
  methods: {
    executeScript(script) {
      eval(script);
    },
  },
});
