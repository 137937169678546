
import Vue from 'vue';
interface Data {
  newValue: boolean;
}
interface Props {
  value: boolean;
  disabled: boolean;
  name: string;
}
interface Methods {
  handleCheck: (value: boolean) => void;
}
export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.handleCheck(value);
    },
  },
  methods: {
    handleCheck(value) {
      this.newValue = value;
      this.$emit('inputChange', value);
    },
  },
});
