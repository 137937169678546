/* eslint-disable @typescript-eslint/no-unused-vars */
import Awindow from '@analytics/types/Awindow';
import GoogleAnalyticsBase, {
  FieldsObject,
  AnalyticsFieldsObject,
} from './types/GoogleAnalyticsBase';

import logger from '@analytics/util/logger';

declare let window: Awindow;

export default class GA extends GoogleAnalyticsBase {
  private prefix = '';

  public init() {
    // noop
  }

  public setName(name: string) {
    this.prefix = `${name}.`;
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration#_setaccount
   * @param trackingId
   * @param name
   * @param extraFields
   */
  public create(
    trackingId: string,
    name: string | null = null,
    extraFields?: FieldsObject,
  ) {
    if (trackingId === '') {
      logger.error('Empty trackingId not allowed');
      return;
    }

    if (name) {
      this.setName(name);
    }

    window._gaq = window._gaq || [];
    window._gaq.push([`${this.prefix}_setAccount`, trackingId]);
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration#_gat.GA_Tracker_._trackPageview
   * @param url
   * @param title
   * @param extraFields
   */
  public pageView(url?: string, title?: string, extraFields?: FieldsObject) {
    window._gaq = window._gaq || [];

    if (url) {
      if (url.match(/^https?:\/\//)) {
        // if you send a full url, it will be handled as path
        // e.g sending http://www.delfi.ee/misc on http://www.delfi.ee
        // would result in google tracking
        // http://www.delfi.ee/http://www.delfi.ee/misc
        logger.warn(
          'Google Analytics GA does not allow setting URL for pageview only path',
        );
      }
      window._gaq.push([`${this.prefix}_trackPageview`, url]);
      return;
    }

    window._gaq.push([`${this.prefix}_trackPageview`]);
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEventTracking
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   * @param extraFields
   */
  public event(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    extraFields: FieldsObject = {},
  ) {
    window._gaq = window._gaq || [];
    // @ts-ignore
    window._gaq.push([
      `${this.prefix}_trackEvent`,
      eventCategory,
      eventAction,
      eventLabel || '',
      extraFields.eventValue,
      !!extraFields.nonInteraction,
    ]);
  }

  public set(data: AnalyticsFieldsObject | FieldsObject) {
    // noop
  }

  public setVariable(
    key: keyof AnalyticsFieldsObject | keyof FieldsObject,
    value: string | number,
  ) {
    // noop
  }
}
