
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons } from '../../fragments';
import Swiper from '@core/components/UI/swiper/Swiper.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType106';

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: false,
  category: true,
  slidesPerBreakpoint: [1.5, 3, 4], // small, medium, large
  navigationPerBreakpoint: [false, true, true], // small, medium, large
  paginationPerBreakpoint: [false, false, false], // small, medium, large
  scrollbarPerBreakpoint: [false, false, false], // small, medium, large
  autoplay: false,
  loop: false,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    Swiper,
  },
  computed: {
    swiperOptions() {
      return {
        spaceBetween: 12,
        initialSlide: 0,
        autoplay: false,
        loop: this.getSettings.loop,
        slidesPerView: this.getSettings.slidesPerBreakpoint[0],
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          enabled: this.getSettings.paginationPerBreakpoint[0],
        },
        navigation: {
          nextEl: `.swiper-${this.blockKey}-wrapper .block-type-106__nav-next`,
          prevEl: `.swiper-${this.blockKey}-wrapper .block-type-106__nav-prev`,
          enabled: this.getSettings.navigationPerBreakpoint[0],
        },
        scrollbar: {
          enabled: false,
        },
        simulateTouch: false,
        breakpoints: {
          768: {
            freeMode: false,
            slidesPerView: this.getSettings.slidesPerBreakpoint[1],
            slidesPerGroup: this.getSettings.slidesPerBreakpoint[1],
            loop: this.getSettings.loop,
            pagination: {
              enabled: this.getSettings.paginationPerBreakpoint[1],
            },
            navigation: {
              enabled: this.getSettings.navigationPerBreakpoint[1],
            },
          },
          1230: {
            spaceBetween: 24,
            freeMode: false,
            slidesPerView: this.getSettings.slidesPerBreakpoint[2],
            slidesPerGroup: this.getSettings.slidesPerBreakpoint[2],
            loop: this.getSettings.loop,
            pagination: {
              enabled: this.getSettings.paginationPerBreakpoint[2],
            },
            navigation: {
              enabled: this.getSettings.navigationPerBreakpoint[2],
            },
          },
        },
      };
    },
  },
});
