export function mergeAdsPlacements(storedPlacements: Record<string, any>, placements: Record<string, any>) {
  if (!placements || (placements && !(Object.keys(placements).length > 0))) {
    return {};
  }

  const storedPlacementsCopy = { ...storedPlacements };

  for (const [key, value] of Object.entries(placements)) {
    if (placements[key]) {
      storedPlacementsCopy[key] = { ...storedPlacementsCopy[key], ...value };
    }
  }

  return storedPlacementsCopy;
}
