var render = function render(_c,_vm){return _c('div',{staticClass:"fragment fragment-html",class:[
    `fragment-html--${_vm.props.content.type}`,
    {
      'fragment-html--bullet_list--liedetector':
        _vm.props.content.html.includes('<li>Šaltiniai</li>') ||
        _vm.props.content.html.includes('<li><p>Šaltiniai</p></li>') ||
        _vm.props.content.html.includes('<li>Источники</li>') ||
        _vm.props.content.html.includes('<li><p>Источники</p></li>'),
    },
  ],domProps:{"innerHTML":_vm._s(_vm.props.content.html)}})
}
var staticRenderFns = []

export { render, staticRenderFns }