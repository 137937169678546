
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons } from '../../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType102';

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: true,
  category: true,
  adsInject: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    AdFormBanner,
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
  },
});
