export function getDisabledAdsList(storedDisableAds: string[] = [], disableAds: string[] = [], enableAds: string[] = []) {
  // Merge disabled ads and remove duplicates
  const mergedDisableAds = [...new Set([...storedDisableAds, ...disableAds])];

  if (!(enableAds.length > 0)) {
    return mergedDisableAds;
  }

  return mergedDisableAds.filter((disableAds) => !enableAds.includes(disableAds));
}
