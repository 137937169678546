import { Context } from '@nuxt/types';
import { Store } from 'vuex';

export default function getBannerKey({
  prefix,
  route,
  store,
  index,
}: {
  prefix: string;
  route: Context['route'];
  store: Store<any>;
  index: number | undefined;
}) {
  const path = store.getters['articles/activeArticlePath'] || route.fullPath;
  const indexSuffix = index ? `-${index}` : ``;

  return `${prefix}-${path}${indexSuffix}`;
}
