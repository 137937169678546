import { articleUrl } from '@root/common/filters/urlBuilders';
import { Data as HeadlinesData, DataWithHref } from '@root/modules/headlines/types/headlines';

type Variables = { headline: HeadlinesData; domain: string | string[]; fakeDomainEnabled: boolean; articlesLinksHash?: string };

export default function getHeadlineWithHref({ headline, domain, fakeDomainEnabled, articlesLinksHash }: Variables): DataWithHref {
  const href = articleUrl({ article: headline, domain, fakeDomainEnabled, articlesLinksHash });
  const headlineWithHref = { ...headline, href };
  return headlineWithHref;
}
