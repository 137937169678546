
import Vue from 'vue';
import srcConfig from '@core/config/src.config';

interface Props {
  content: Record<string, any>;
}

interface Computed {
  podcastUrl: string;
  isMobileOrTablet: boolean;
  width: number;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobileOrTablet() {
      return (typeof window !== 'undefined' && window.innerWidth <= 991) || false;
    },
    width() {
      return this.isMobileOrTablet ? window.innerWidth : 580;
    },
    podcastUrl() {
      return `${srcConfig.podcast}?url=${this.content.attrs.podcastUrl}&w=${this.width}&mobile=${this.isMobileOrTablet ? 1 : 0}`;
    },
  },
});
