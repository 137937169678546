export const responseByErrorPatternConfig: {
  errorMessagePattern: RegExp;
  responseCode: number;
  // responseMessageWithoutPrefix is used as a path to translation value
  // prefix is added in BaseService (this._serviceType) and represents page key in translation files
  responseMessageWithoutPrefix: string;
  // fallbackMessage is used when serviceType is not provided
  fallbackMessage: string;
}[] = [
  {
    errorMessagePattern: /^InputValueError: /gm,
    responseCode: 400,
    responseMessageWithoutPrefix: 'error.not_found',
    fallbackMessage: 'error.page_not_found',
  },
];
