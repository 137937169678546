import rootConfig from '@root/config/root.config';
import { Context } from '@nuxt/types';

const isAbsolutUrl = (href: string) => {
  const protocolRegEx = /^https?:\/\//i;
  return protocolRegEx.test(href);
};

const getUrlObj = (href: string) => {
  const isAbsolute = isAbsolutUrl(href);
  return isAbsolute ? new URL(href) : new URL(href, rootConfig.main_portal_url);
};

export const buildQueryUrl = (href: string, { query, route }: { query: Context['route']['query']; route?: Context['route'] }) => {
  const isAbsolute = isAbsolutUrl(href);
  const urlObj = getUrlObj(href);

  Object.keys(query).forEach((key) => {
    if (query[key]) {
      urlObj.searchParams.append(key, query[key].toString());
    }
  });

  if (route) {
    urlObj.hash = route.hash;
  }

  if (isAbsolute) {
    return urlObj.toString();
  } else {
    return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
  }
};
