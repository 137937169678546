import { Plugin } from '@nuxt/types';
import { EventEmitter } from 'events';

// Inject into Vue instances (this.$eventHandler)
declare module 'vue/types/vue' {
  interface Vue {
    $eventHandler: EventEmitter;
  }
}
// Inject into context (context.$eventHandler)
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $eventHandler: EventEmitter;
  }
}

const eventHandler: Plugin = async (ctx, inject) => {
  const eventHandler = new EventEmitter();

  inject('eventHandler', eventHandler);
};

export default eventHandler;
