
import Vue from 'vue';
import { FragmentContent } from '@core/types/fragment';

interface Props {
  content: FragmentContent;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
});
