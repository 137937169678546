import { DataWithHref, GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';
import UniversalHeadlinesService from '@headlines/services/UniversalHeadlines.service';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';

interface HeadlinesWithHref {
  items: DataWithHref[];
  pager: GetUniversalHeadlines['data']['headlines']['pager'];
}
interface FetchContext {
  domain?: string;
  fakeDomainEnabled?: boolean;
}
export default class GetTabsHeadlinesService {
  private _universalHeadlinesService: UniversalHeadlinesService;

  constructor() {
    this._universalHeadlinesService = new UniversalHeadlinesService();
  }

  private async getHeadlines(variables: GetUniversalHeadlines['variables'], context?: FetchContext): Promise<HeadlinesWithHref> {
    try {
      // Force lite headlines requests
      variables.lite = true;

      const headlines = await this._universalHeadlinesService.fetch(variables);
      const headlinesWithHref: HeadlinesWithHref = {
        items: [],
        pager: headlines.pager,
      };

      headlinesWithHref.items = headlines.items.map((headline) => {
        return getHeadlineWithHref({
          headline,
          domain: context?.domain || '',
          fakeDomainEnabled: context?.fakeDomainEnabled || false,
        });
      });

      return headlinesWithHref;
    } catch {
      return {
        items: [],
        pager: {
          offset: 0,
          count: 0,
        },
      };
    }
  }

  public async fetch(variables: GetUniversalHeadlines['variables'], context?: FetchContext) {
    const latestHeadlines = await this.getHeadlines(variables, context);

    const variablesForMostRead: GetUniversalHeadlines['variables'] = {
      ...variables,
      orderBy: 'VIEWS',
      orderOptions: {
        viewsSince: 'SINCE_3_DAYS',
      },
    };

    const mostReadHeadlines = await this.getHeadlines(variablesForMostRead, context);

    return {
      latestHeadlines,
      mostReadHeadlines,
    };
  }
}
