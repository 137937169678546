
import Vue from 'vue';
import Gallery from '@gallery/components/Gallery.vue';
import { FragmentContent } from '@core/types/fragment';

interface Props {
  content: FragmentContent;
}

interface Computed {
  adFormMkw: string[];
  adFormMkv: string[];
  adFree: boolean;
  settings: {
    adsSettings: {
      mkw: string[];
      mkv: string[];
      adFree: boolean;
    };
    analytics: {
      gemius: string | null;
    };
  };
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    Gallery,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    adFormMkw() {
      return this.$store.getters['adForm/getAdsKeywords'];
    },
    adFormMkv() {
      return this.$store.getters['adForm/getAdsKeyValues'];
    },
    adFree() {
      return this.$store.state.piano.access.adFree || false;
    },
    settings() {
      return {
        adsSettings: {
          mkw: this.adFormMkw,
          mkv: this.adFormMkv,
          adFree: this.adFree,
        },
        analytics: {
          gemius: this.$channelConfig('analytics')?.gemius?.dgs || null,
        },
      };
    },
  },
});
