/* eslint-disable @typescript-eslint/no-empty-function */

interface CbWindow extends Window {
  _dlf: Record<string, unknown>;
  Cookiebot: any;
  CookieConsent: Record<string, unknown>;
  CookieControl: any;
  ga: any;
  gtag: any;
}

declare let window: CbWindow;

const cookieBot = () => {
  if (window.location.hash.indexOf('__nocmp') !== -1) {
    return;
  }

  function requestNotifications() {
    try {
      // Check for PushManager and Notification support
      if ('PushManager' in window === false || 'Notification' in window === false) {
        return;
      }

      // Skip for Delfi mobile apps
      if (navigator.userAgent.indexOf('EmbeddedBrowser ') === -1) {
        return;
      }

      const pathName = window.location.pathname;
      if (window.location.hostname === 'www.delfi.lt' && !/ru/gi.test(pathName) && !/en/gi.test(pathName) && !/pl/gi.test(pathName)) {
        if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
          Notification.requestPermission().then(function (permission) {
            if (window.gtag) {
              window.gtag('event', 'webPushAskForPermission', { event_category: 'Push', event_label: permission === 'granted' ? 'Success' : 'Fail' });
            } else if (window.ga) {
              window.ga('send', 'event', 'Push', 'webPushAskForPermission', permission === 'granted' ? 'Success' : 'Fail');
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  function getCookie(name: string) {
    const cookieName = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }

    return null;
  }

  function extractCookieTimeStamp(cookie: string) {
    // Create a regular expression to find the 'utc' property
    const regex = /utc:(\d+)/;
    const match = cookie.match(regex);
    if (match) {
      return parseInt(match[1], 10);
    }

    return null;
  }

  // Delete old cookie consent cookie and force show()
  window.addEventListener('CookiebotOnLoad', function () {
    const cbCookie = getCookie('CookieConsent');
    if (cbCookie) {
      const timeStamp = extractCookieTimeStamp(cbCookie);
      const targetTimestamp = 1705917600000;
      if (timeStamp && timeStamp < targetTimestamp) {
        document.cookie = 'CookieConsent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.Cookiebot.show();
      }
    }
  });

  window.addEventListener('CookiebotOnAccept', () => {
    window.Cookiebot.hide();
    requestNotifications();
  });

  window.addEventListener('CookiebotOnDecline', () => {
    window.Cookiebot.hide();
    requestNotifications();
  });
};

export default cookieBot;
