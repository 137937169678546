import BlockType113Component from '@headlines/components/block/111-120/BlockType113.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType113: AdminBlockType = {
  component: BlockType113Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    lead: false,
    publishDate: false,
  }),
  articlesCount: 15,
};

export default AdminBlockType113;
