
import { Fragment } from './Fragment';
import { getBaseDomain } from '@core/utils/helpers';
import whitelistConfig from '@core/config/whitelist.config';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentIframe',
  mounted() {
    window.addEventListener('message', (event) => this.handleDocHeightMsg(event), false);
  },
  destroyed() {
    window.removeEventListener('message', this.handleDocHeightMsg);
  },
  methods: {
    // Change iframes height dynamically
    handleDocHeightMsg(event: MessageEvent) {
      const origin = getBaseDomain();
      // Accept only delfi domains
      const isWhitelisted = whitelistConfig.some((domain) => origin === domain);

      if (isWhitelisted) {
        try {
          const data = JSON.parse(event.data);

          if (data.id && data.docHeight) {
            const el = document.getElementById(data.id);

            if (el) {
              el.style.height = `${data.docHeight}px`;
            }
          }
        } catch (error) {
          return false;
        }
      }
      return true;
    },
  },
});
