
import Vue from 'vue';
import AdFormBanner from './AdFormBanner.vue';

export default Vue.extend({
  components: {
    AdFormBanner,
  },
  mounted() {
    if (!document.getElementById('delfi-adx-afp-interstitial')) {
      const interstitialContainer = document.createElement('div');
      interstitialContainer.setAttribute('id', 'delfi-adx-afp-interstitial');
      interstitialContainer.style.cssText = 'line-height:0px;';
      document.body.insertBefore(interstitialContainer, document.body.childNodes[0]);
    }
  },
});
