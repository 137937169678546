import Vue from 'vue';

import BaseIcon from '@core/components/UI/BaseIcon.vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import FragmentAudio from '@root/common/components/base/fragments/FragmentAudio.vue';
import FragmentGallery from '@root/common/components/base/fragments/FragmentGallery.vue';
import FragmentVideo from '@root/common/components/base/fragments/FragmentVideo.vue';

const components = { BaseIcon, BaseButton, FragmentAudio, FragmentGallery, FragmentVideo };
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
