const appRegex = /(Delfi[a-zA-Z]*\/\d+\.?\d+\.?\d+)|(FBAN|FB_IAB)/g;

const externalApplications: Record<string, string> = {
  FBAN: 'Facebook',
  FB_IAB: 'Facebook',
};

const getMobileApplication = (ua: string) => {
  const match = ua.match(appRegex);
  const application = match && match[0];

  return (application && externalApplications[application]) || application;
};

export default getMobileApplication;
