
import Vue from 'vue';

interface Props {
  title: string | null;
  authors: Array<string> | null;
  credit: string | null;
}

interface Computed {
  duplicateCredit: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    title: {
      type: String,
    },
    authors: {
      type: Array,
    },
    credit: {
      type: String,
    },
  },
  computed: {
    duplicateCredit() {
      return this.authors ? this.authors.some((author) => author.toLowerCase() === this.credit?.toLowerCase()) : false;
    },
  },
});
