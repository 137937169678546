import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const excludeCategories: AdminBlockTypeAttribute = {
  label: 'Exclude categories',
  key: 'excludeCategories',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: [],
  typeOptions: [],
};

export default excludeCategories;
