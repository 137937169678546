import apolloClient from '../apollo/apolloClient';
import getAudioQuery from '../apollo/graphql/getAudio.graphql';
import { GetAudio } from '../types/getAudio';

const getAudio = async (variables: { id: string }) => {
  const queryOptions = { variables };
  const options = Object.assign({ query: getAudioQuery }, queryOptions);

  const { data } = await apolloClient.query<GetAudio['data']>(options);

  return data;
};

export { getAudio };
