import { Context } from '@nuxt/types';
import { Resources } from '../types';
import { Config } from '../../../modules/channel/types/channel';

async function initDevelopmentStyles(theme: Config['settings']['theme']) {
  try {
    await import(
      /* webpackChunkName: "[request]" */
      `@root/assets/scss/themes/${theme}/root-variables.css`
    );
    await import(/* webpackChunkName: "main-root-styles" */ `@root/assets/scss/core/main.scss`);
  } catch {
    console.error('initDevelopmentStyles: No theme found in the configuration');
  }
}

function getProductionStyles(config: Context['$config'], resource: Resources, theme: Config['settings']['theme']) {
  const styleName = resource?.styleResources?.includes(theme) ? theme : 'delfi';
  const publicPath = process.env.CDN_PUBLIC_PATH || config.publicPath;
  const CI_UNIQUE_ID = process.env.CI_UNIQUE_ID || config.CI_UNIQUE_ID;

  return [
    {
      rel: 'stylesheet',
      hid: 'root-variables',
      href: `${publicPath}/${CI_UNIQUE_ID}/${styleName}-root-variables-css.css`,
    },
    {
      rel: 'stylesheet',
      hid: 'main_styles',
      href: `${publicPath}/${CI_UNIQUE_ID}/main-root-styles.css`,
    },
  ];
}

export { initDevelopmentStyles, getProductionStyles };
