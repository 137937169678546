import { Plugin } from '@nuxt/types';
import { Gemius } from '@analytics/trackers';
import { ChannelPages } from '@root/libs/init-channel/types';

import { getUid } from '@root/modules/analytics/utils/getUid';

const GemiusPlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.gemius?.id) {
    return;
  }

  const appType = app.$channelConfig('settings').application.type;
  const isNativeApp = appType?.includes('NativeApp');
  // channelConfig does not have articleComments or customPage sections
  // in analytics object, so undefined check is necessary
  const pageSettings = app.$channelConfig('page')[route.name as ChannelPages];

  let uid = getUid({ defaultSettings: analytics.gemius, pageSettings: pageSettings?.analytics?.gemius, isNativeApp });

  // Use category-specific Gemius ID if defined in config
  if (route.params && route.params.category) {
    const category = route.params.category;
    const categoryUids = analytics.gemius?.category || [];
    const categoryUid = categoryUids[category] || false;
    if (categoryUid) {
      uid = categoryUid;
    }
  }

  const gemiusTracker = new Gemius();

  await gemiusTracker.init({ uid });

  const isArticle = route.name === 'article';

  if (!isArticle) {
    gemiusTracker.sendPageEvent();
  }

  app?.router?.afterEach((to) => {
    // Send page view of all pages except article
    const isArticle = to.name === 'article';
    const pageSettings = app.$channelConfig('page')[to.name as ChannelPages];

    let uid = getUid({ defaultSettings: analytics.gemius, pageSettings: pageSettings?.analytics?.gemius, isNativeApp });

    // Use category-specific Gemius ID if defined in config
    if (to.params && to.params.category) {
      const category = to.params.category;
      const categoryUids = analytics.gemius?.category || [];
      const categoryUid = categoryUids[category] || false;
      if (categoryUid) {
        uid = categoryUid;
      }
    }

    gemiusTracker.changeTrackerUid(uid);

    if (!isArticle) {
      gemiusTracker.sendPageEvent();
    }
  });

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      const scrollDirection = store.state.article.scrollDirection;
      if (activeArticlePath && store.state.article.activeArticle && scrollDirection !== 'up') {
        gemiusTracker.sendPageEvent();
      }
    },
    {
      immediate: true,
    }
  );
};

export default GemiusPlugin;
