import { Middleware } from '@nuxt/types';
import config from '@root/router/config/router.config';

type CacheControlRoutes = keyof typeof config.headers.cacheControl;
type VaryRoutes = keyof typeof config.headers.vary;

const headers: Middleware = async (context) => {
  const { res, route, query } = context;

  if (process.client) {
    return;
  }

  const cacheControl = config.headers.cacheControl[route.name as CacheControlRoutes] || config.headers.cacheControl.default;
  const vary = config.headers.vary[route.name as VaryRoutes] || config.headers.vary.default;

  if (query.preview) {
    res.setHeader('Cache-control', 'no-cache');
  } else {
    res.setHeader('Cache-control', `public, max-age=${cacheControl}, stale-while-revalidate=60, stale-if-error=3600`);

    if (vary) {
      res.setHeader('Vary', vary);
    }
  }
};

export default headers;
