interface callbackFunc {
  (data: any): void;
}
type Events = Record<string, callbackFunc[]>;

export interface EventHandlerClass {
  on(eventName: string, func: callbackFunc): void;
  off(eventName: string, func: callbackFunc): void;
  emit(eventName: string, data: any): void;
}

class EventHandler implements EventHandlerClass {
  events: Events;

  constructor() {
    this.events = {};
  }

  on(eventName: string, func: callbackFunc) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(func);
  }

  off(eventName: string, func: callbackFunc) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === func) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }

  public emit(eventName: string, data: any) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(function (fn) {
        fn(data);
      });
    }
  }
}

export { EventHandler };
