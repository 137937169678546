export default {
  SYNC_SEGMENTS: {
    types: {
      sync: {
        eventType: 'sync',
        params: '{"name": "${data.name}", "type": "${data.type}", "segmentIds": "${data.segmentIds}" }',
      },
    },
  },
};
