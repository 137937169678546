
import Vue from 'vue';
import { FragmentContent } from '@core/types/fragment';

interface Props {
  content: FragmentContent;
}

interface Computed {
  doFollowContent: string;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    doFollowContent() {
      const attrs = this.content.attrs;
      let content = '<p>';

      if (attrs.text_before !== '') {
        content = content + ' ' + attrs.text_before;
      }

      if (attrs.title && attrs.url && attrs.valid_to) {
        content =
          content + ' ' + `<a href="${attrs.url}" target="_blank"${Date.parse(attrs.valid_to) < Date.now() ? ' rel="nofollow"' : ''}>${attrs.title}</a>`;
      }

      if (attrs.text_after !== '') {
        content = content + ' ' + attrs.text_after;
      }

      content = content + '</p>';

      return content;
    },
  },
});
