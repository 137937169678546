
import Vue from 'vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import BaseButton from '@core/components/UI/BaseButton.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { gridConfig } from '@core/config/grid.config';
import { getRootConfig, convertArrayToObject } from '@core/utils/helpers';
import getItemFromObject from 'lodash/get';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import hasNapoComments from '@root/modules/article/utils/hasNapoComments';
import { getDefinedLabels } from '@headlines/utils/getDefinedLabels';
import { Label } from '@root/modules/channel/types/channel';
import { stripTags } from '@root/common/filters/htmlFormater';

type ShareData = {
  url: string;
  title: string;
  text: string;
};

interface ShNavigator extends Navigator {
  share: (data?: ShareData) => Promise<void>;
}

interface ShWindow extends Window {
  [index: string]: any;
  navigator: ShNavigator;
}

declare let window: ShWindow;

interface Props {
  headline: HeadlineWithRelatedArticles;
  titleSize: string;
  mobileTitleSize: string;
  titleCommentEnabled: boolean;
  showFacebookShareCount: boolean;
  showCommentsCount: boolean;
  showViewsCount: boolean;
  showVideoDuration: boolean;
}

interface Computed {
  titleClassModifier: Record<string, any>;
  facebookShareCount: number | null;
  commentCount: string | number;
  commentsPath: string;
  facebookUrl: string;
  isMobile: boolean;
  views: number | null;
  videoDuration: string | boolean;
  definedLabels: Label[];
}

interface Methods {
  popupWindow: (url: string) => void;
  getItemFromObject: typeof getItemFromObject;
  headlineClick: (headline: HeadlineWithRelatedArticles) => void;
  hasTagId: (tagId: number) => boolean;
  getTitle: (headline: HeadlineWithRelatedArticles, strip: boolean) => string;
}

interface Data {
  popup: {
    width: number;
    height: number;
    name: string;
  };
  shareUrl: string;
}

const sizeList = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h6',
  h4: 'h6',
  h5: 'h6',
  h6: 'h6',
} as Record<string, string>;

dayjs.locale('lt');
dayjs.extend(duration);

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LinkHandler,
    BaseButton,
  },
  data() {
    return {
      popup: {
        width: 500,
        height: 400,
        name: 'share_dialog',
      },
      shareUrl: `${getRootConfig('meta')?.base?.baseUrl}/a/${this.headline.id}`,
      comments: convertArrayToObject<{
        disableComments: boolean;
        onlyRegisteredUsersAllowedToComment: boolean;
      }>(this.headline?.comments, { disableComments: false, onlyRegisteredUsersAllowedToComment: false }),
    };
  },
  props: {
    /**
     * @values  {
     *  title: string,
     *  title_short: string,
     *  title_comment: string,
     *  comments: object,
     *  shares: array,
     *  href: string,
     * }
     */
    headline: {
      type: Object,
      required: true,
    },
    /**
     * @values 'h1', 'h2', 'h3', 'h4, 'h5, 'h6'
     */
    titleSize: {
      type: String,
      default: 'h2',
      required: false,
    },
    mobileTitleSize: {
      type: String,
      default: '',
      required: false,
    },
    titleCommentEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFacebookShareCount: {
      type: Boolean,
      default: true,
      required: false,
    },
    showCommentsCount: {
      type: Boolean,
      default: true,
      required: false,
    },
    showViewsCount: {
      type: Boolean,
      default: false,
      required: false,
    },
    showVideoDuration: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    titleClassModifier() {
      const { titleSize } = this;
      let mobileTitleSize = this.mobileTitleSize;

      if (!mobileTitleSize && titleSize in sizeList) {
        mobileTitleSize = sizeList[titleSize];
      }

      return {
        [`headline-title--size-${titleSize}`]: titleSize,
        [`headline-title--size-sm-${mobileTitleSize}`]: mobileTitleSize,
      };
    },
    facebookShareCount() {
      const shares = this.getItemFromObject(this.headline, 'statistics.shares');
      // FIXME: Should be taken from config
      // but is workarounded during migration
      // to git submodules
      return shares >= 20 ? shares : null;
    },
    commentCount() {
      const commentsNoBrackets = getRootConfig('settings')?.commentsNoBrackets;
      const commentNumber = getItemFromObject(this.headline, 'statistics.comments');
      const commentCount = commentsNoBrackets ? commentNumber : `(${commentNumber})`;

      return commentCount;
    },
    commentsPath() {
      return `${this.headline.href}/${hasNapoComments(this.headline) ? 'napo' : this.$t('portal_core.comments')}`;
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.shareUrl)}&t=${encodeURI(this.getTitle(this.headline, true))}`;
    },
    isMobile() {
      return screen.width <= gridConfig.breakpoints.lg;
    },
    views() {
      if (!this.showViewsCount) {
        return false;
      }

      return this.$props.headline.statistics.views;
    },
    videoDuration() {
      if (!this.showVideoDuration) {
        return false;
      }

      if (!this.$props.headline.relatedEntities.media.items.length) {
        return false;
      }

      if (!this.$props.headline.relatedEntities.media.items[0].videoData) {
        return false;
      }

      const duration = this.$props.headline.relatedEntities.media.items[0].videoData.videoDuration;

      return duration ? dayjs.duration(duration, 'seconds').format('mm:ss') : false;
    },
    definedLabels() {
      const definedLabels = getDefinedLabels(this.headline, 'title');
      return definedLabels;
    },
  },
  methods: {
    popupWindow(url) {
      const params = `width=${this.popup.width},height=${this.popup.height},directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no`;
      const lead = this.getItemFromObject(this.headline, 'content.lead.text') || '';
      const title = this.getTitle(this.headline, true);

      if (window.navigator.share && this.isMobile) {
        window.navigator.share({
          title,
          url: this.shareUrl,
          text: lead,
        });
      } else if (window.AndroidWebAppMobileAPI && window.AndroidWebAppMobileAPI.sharePage) {
        window.AndroidWebAppMobileAPI.sharePage(this.shareUrl, title, lead);
      } else {
        window.open(url, this.popup.name, params);
      }
    },
    getItemFromObject,
    headlineClick(headline: HeadlineWithRelatedArticles) {
      this.$instanceConnector('headlines') && this.$instanceConnector('headlines').headlineClick({ headline });
    },
    hasTagId(tagId) {
      return !!this.headline.tags?.items?.find((tag) => tag.id === tagId);
    },
    getTitle(headline, strip = false) {
      const title = headline.content.title?.html
        ? headline.content.title?.html.replace(/(<\/?h1>)/gi, '')
        : headline.content.title?.text
        ? headline.content.title?.text
        : '';

      return strip && typeof stripTags === 'function' ? stripTags(title) : title;
    },
  },
});
