
import Vue from 'vue';

interface Props {
  name: string;
  rotate: string;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    name: {
      type: String,
      required: true,
    },
    rotate: {
      type: String,
      required: false,
      default: null,
    },
  },
});
