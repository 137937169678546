
import Vue from 'vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderBottomEmbed from './HeaderBottomEmbed.vue';
import { BottomNavigationProps, Header, ListData } from '@root/modules/navigation/types/navigation';
import { gridConfig } from '@core/config/grid.config';

interface Computed {
  getNavBottomItems: ListData[];
  getNavBottomSettings: BottomNavigationProps | null;
  showRegularBigNavigation: boolean;
  isMobileView: boolean;
}

interface Props {
  navigation: Header;
  hideHeaderMenu: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  name: 'Header',
  components: {
    HeaderMenu,
    HeaderBottomEmbed,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    hideHeaderMenu: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getNavBottomItems() {
      return (this.isMobileView && this.navigation?.bottom_mobile ? this.navigation?.bottom_mobile?.items : this.navigation?.bottom?.items) || [];
    },
    getNavBottomSettings() {
      return this.navigation?.bottom?.settings || null;
    },
    showRegularBigNavigation() {
      const headerType = this.getNavBottomSettings?.type;
      const isHeaderBottomLinks = this.navigation?.bottom?.items && this.navigation?.bottom?.items.length > 0;
      return isHeaderBottomLinks || headerType === 'big';
    },
    isMobileView() {
      let isMobileView = false;

      if (process.client) {
        const screenWidth = window.screen.width;
        const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;
        isMobileView = screenWidth <= gridConfig.breakpoints.lg || isIpad;
      } else {
        isMobileView = this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
      }

      return isMobileView;
    },
  },
});
