import { Plugin } from '@nuxt/types';
import { Hotjar } from '@analytics/trackers';

const HotjarPlugin: Plugin = async (ctx) => {
  const { app } = ctx;
  const analytics = app.$channelConfig('analytics');

  // Check if tracker can be enabled
  if (!(analytics?.hotjar?.id && analytics?.hotjar?.enabled)) {
    return;
  }

  // Setup tracker
  const hotjar = new Hotjar();
  hotjar.init({ id: analytics.hotjar.id });
};

export default HotjarPlugin;
