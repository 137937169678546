
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType8';

type Links = {
  text: string;
  href: string;
  target: string;
};

const defaultSettings = {
  text: true,
  logo: false,
  secondaryLogo: false,
  links: false,
  colorBlock: null,
  topMargin: false,
};

interface Props {
  settings: BlockTypeSettings;
  data: {
    title: {
      logo: string;
      text: string;
      href: string;
      target?: string;
    };
    secondaryLogo:
      | {
          href?: string;
          target?: string;
          src: string;
        }
      | string;
    links: Links[] | [];
  };
}

interface Computed {
  colorBlockStyles: string;
  fakeDomainEnabled: boolean;
  getSettings: BlockTypeSettings;
  classModifier: Record<string, unknown>;
}

// Define dummy filter if process is not defined
if (typeof process === 'undefined') {
  Vue.filter('navigationUrl', (url: string, fakeDomainEnabled: boolean) => {
    if (fakeDomainEnabled) {
      return url;
    }

    return url;
  });
}

interface $nuxtWindow extends Window {
  $nuxt: any;
}

interface Process {
  client: boolean;
  env: any;
}

declare let window: $nuxtWindow;
declare let process: Process;

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    LinkHandler,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fakeDomainEnabled() {
      return typeof process !== 'undefined'
        ? process && process.client
          ? window.$nuxt && window.$nuxt.$config.FAKE_DOMAIN_ENABLED
          : process.env.FAKE_DOMAIN_ENABLED
        : false;
    },
    getSettings() {
      return { ...defaultSettings, ...this.settings };
    },
    colorBlockStyles() {
      return this.getSettings.colorBlock ? `background: ${this.getSettings.colorBlock};` : '';
    },
    classModifier() {
      return { 'block-type-8--with-color-block': this.getSettings.colorBlock, 'block-type-8--with-top-margin': this.getSettings.topMargin };
    },
  },
});
