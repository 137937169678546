/* eslint-disable no-sequences */
// @ts-nocheck
import { loadScripts } from '@analytics/util/scriptLoader';

export default async function loadGoogleAnalyticsScript() {
  await (async (i, s, o: 'script', g, r: 'ga') => {
    i.GoogleAnalyticsObject = r;
    (i[r] =
      i[r] ||
      (() => {
        // eslint-disable-next-line prefer-rest-params
        (i[r].q = i[r].q || []).push(arguments);
      })),
      (i[r].l = 1 * new Date().getTime());
    await loadScripts(g);
  })(
    window,
    document,
    'script',
    '//www.google-analytics.com/analytics.js',
    'ga',
  );
}
