<template>
  <client-only>
    <div v-if="id" :id="id" :class="{ 'swiper-container': externalNavigation }">
      <div class="swiper" :class="className">
        <div class="swiper-wrapper">
          <slot></slot>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-scrollbar"></div>
        <template v-if="!externalNavigation">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </template>
      </div>
      <template v-if="externalNavigation">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </template>
    </div>
  </client-only>
</template>

<script>
import { Swiper } from 'swiper';
import { Navigation, Pagination, Autoplay, Scrollbar, EffectFade } from 'swiper/modules';

export default {
  props: {
    swiperOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    className: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    externalNavigation() {
      return !this.className && !this.swiperOptions.effect;
    },
  },
  mounted() {
    // Set id for multiple instances in same page
    this.id = `swiper-${Math.floor(10000 + Math.random() * 90000)}`;

    this.$nextTick(() => {
      // Override navigation elements in unique containers
      const options = Object.assign({}, this.swiperOptions);

      if (this.externalNavigation) {
        options.navigation.prevEl = `#${this.id} .swiper-button-prev`;
        options.navigation.nextEl = `#${this.id} .swiper-button-next`;
      }

      Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, EffectFade]);
      new Swiper(this.className ? `.${this.className}` : `#${this.id} .swiper`, options);
    });
  },
};
</script>

<style lang="scss">
.swiper-container {
  position: relative;

  @include media-breakpoint-down(sm) {
    & > .swiper-button-prev,
    & > .swiper-button-next {
      display: none;
    }
  }

  .swiper {
    overflow: clip;
    overflow-y: visible;
  }
}
</style>
