const lt_LT = {
  authors: 'autoriai',
  latest: 'naujausi',
  popular: 'populiariausi',
  search: 'paieska',
  author: 'autorius',
  category: 'kategorija',
  topics: 'temos',
  page: 'puslapis',
  client: 'klientas',
  article: 'straipsnis',
  comments: 'diskusija',
  bookmarks: 'issaugoti',
  forecast: 'prognoze',
};

export default lt_LT;
