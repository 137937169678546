
import BaseBlock from '@headlines/utils/BaseBlock';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType105';
import Swiper from '@core/components/UI/swiper/Swiper.vue';

const defaultSettings = {
  titleComment: true,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineIcons,
    BaseIcon,
    Swiper,
  },
  data() {
    return {
      contentTypeFilter: ['contentMarketing', 'hasStream'],
      swiperOptions: {
        spaceBetween: 12,
        initialSlide: 0,
        autoplay: false,
        loop: false,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          enabled: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        simulateTouch: false,
        breakpoints: {
          768: {
            freeMode: false,
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      },
    };
  },
  computed: {
    getSideBlockContent() {
      return this.data.items.slice(1, 6);
    },
  },
});
