
import Vue from 'vue';
import { getRootConfig } from '@core/utils/helpers';
import { formatDateWithTime } from '@root/common/filters/dateFormater';

interface Props {
  date: string;
  dateFormat: string;
}

interface Data {
  locale: string;
}

export default Vue.extend<Data, unknown, unknown, Props>({
  filters: {
    formatDateWithTime,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    /**
     * Supports dayjs formats
     */
    dateFormat: {
      type: String,
      default: 'DD.MM.YYYY, HH:mm',
      required: false,
    },
  },
  data() {
    return {
      locale: 'lt',
    };
  },
  mounted() {
    const settings = getRootConfig('settings');
    if (settings && settings.locale) {
      this.locale = settings.locale.split('_')[0];
    }
  },
});
