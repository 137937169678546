import BlockType104Component from '@headlines/components/block/100-110/BlockType104.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType104: AdminBlockType = {
  component: BlockType104Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    lead: false,
    publishDate: false,
    slidesPerBreakpoint: '1.5,2,3',
    autoplay: false,
    loop: false,
  }),
  articlesCount: 12,
};

export default AdminBlockType104;
