import BaseService from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';

import { GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';
import getUniversalHeadlines from '@root/modules/headlines/graphql/getUniversalHeadlines.graphql';

type ServiceVariables = GetUniversalHeadlines['variables'];
type FetchVariables = GetUniversalHeadlines['variables'];
type Response = GetUniversalHeadlines['data'];

export default class UniversalHeadlinesService extends BaseService implements ServiceInterface {
  private async fetchUniversalHeadlines(variables: FetchVariables) {
    try {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const options = Object.assign({ query: getUniversalHeadlines }, { variables });
      const response = await apiProvider.query<Response>(options);
      const { data } = response;

      const items = data.headlines?.items || [];
      const adaptedItems: GetUniversalHeadlines['data']['headlines']['items'] = [];

      // Merge attributes (queried as non-boolean) with settings (queried as boolean)
      items.forEach((item) => {
        if (item.attributes?.length) {
          item.settings = [...item.settings, ...item.attributes];
        }

        delete item.attributes;
        adaptedItems.push(item);
      });

      return {
        items: adaptedItems,
        pager: data.headlines.pager,
      };
    } catch {
      return {
        items: [],
        pager: {
          offset: 0,
          count: 0,
        },
      };
    }
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchUniversalHeadlines(variables);

    return response;
  }
}
