
import Vue from 'vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import Tabs from './Tabs.vue';
import HeadlineTitleList from './HeadlineTitleList.vue';

interface Props {
  initialTab: number;
  tabs: Record<string, string>[];
  buttons: Record<string, string>[];
  showComments: boolean;
  mobileHeadlineListLimit: number;
}

interface Methods {
  executeScript: (script: string) => void;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    Tabs,
    HeadlineTitleList,
    BaseButton,
  },
  props: {
    initialTab: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    showComments: {
      type: Boolean,
      required: false,
    },
    mobileHeadlineListLimit: {
      type: Number,
      required: false,
    },
  },
  methods: {
    executeScript(script) {
      eval(script);
    },
  },
});
