import Vue from 'vue';
import articleUrl, { buildArticleCommentsUrl as articleCommentsUrl } from '@root/modules/article/utils/buildArticleUrl';
import categoryUrl from '@root/modules/category/utils/buildCategoryUrl';
import tagUrl from '@root/modules/tags/utils/buildTagUrl';
import navigationUrl from '@root/modules/navigation/utils/buildNavigationUrl';

Vue.filter('articleUrl', articleUrl);
Vue.filter('articleCommentsUrl', articleCommentsUrl);
Vue.filter('categoryUrl', categoryUrl);
Vue.filter('tagUrl', tagUrl);
Vue.filter('navigationUrl', navigationUrl);

export { articleUrl, articleCommentsUrl, categoryUrl, tagUrl, navigationUrl };
