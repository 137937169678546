var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.divId && !_vm.isDisabled && _vm.banner.url && (_vm.banner.image || _vm.banner.style))?_c('adBlockComponent',{style:({ display: 'block', ..._vm.banner.style }),attrs:{"tag":_vm.divId}},[(_vm.banner.type === 'float')?_c('div',{style:({
      position: 'absolute',
      right: '10px',
      top: '10px',
      width: '42px',
      height: '42px',
      color: '#fff',
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '22px',
      background: 'rgba(0,0,0,0.5)',
      padding: '10px',
      borderRadius: '50%',
      cursor: 'pointer',
    }),on:{"click":function($event){$event.preventDefault();return _vm.close()}}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(_vm.banner.type === 'scroller')?_c('div',{style:({
      position: 'absolute',
      right: '5px',
      top: '-25px',
      width: '22px',
      height: '22px',
      color: '#878787',
      fontSize: '12px',
      textAlign: 'center',
      lineHeight: '12px',
      background: '#fff',
      padding: '5px',
      border: '1px solid',
      borderRadius: '50%',
      cursor: 'pointer',
    }),on:{"click":function($event){$event.preventDefault();return _vm.close()}}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),_c('div',{style:({ display: 'block', width: '100%', height: '100%', cursor: 'pointer' }),attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.goToUrl(_vm.banner.url)}}},[(_vm.banner.image)?_c('img',{attrs:{"src":_vm.banner.image}}):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }