
import { Fragment } from './Fragment';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentDstream',
});
