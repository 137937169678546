export function formatAdSurround() {
  const headerBanner = (window.document.querySelector('.banner--header:not(.banner--header__formatted)') as HTMLElement) || null;
  if (headerBanner) {
    const mainWrapper = (window.document.querySelector('.main-wrapper') as HTMLElement) || null;
    mainWrapper.style.paddingTop = '0px';
    const contentColumn = (window.document.querySelector('.col-main') as HTMLElement) || null;
    if (contentColumn) {
      contentColumn.style.paddingTop = '15px';
    }

    const articleColumn = (window.document.querySelector('.col-article') as HTMLElement) || null;
    if (articleColumn) {
      articleColumn.style.paddingTop = '15px';
      const contentArticle = (window.document.querySelector('.article:not(.article__formatted)') as HTMLElement) || null;
      contentArticle.classList.add('article__formatted');
    }

    headerBanner.classList.add('banner--header__formatted');
  }

  const isCrArticle = window.document.querySelectorAll('.article').length > 1 || false;
  const contentArticle = (window.document.querySelector('.article:not(.article__formatted)') as HTMLElement) || null;
  if (isCrArticle && contentArticle) {
    const contentArticleColumn = (contentArticle.querySelector('.col') as HTMLElement) || null;
    const contentArticleBanner = (contentArticle.querySelector('.banner--content') as HTMLElement) || null;
    if (contentArticleColumn && contentArticleBanner) {
      contentArticleColumn.style.paddingTop = '15px';
      contentArticleBanner.style.marginBottom = '0px';
      contentArticle.classList.add('article__formatted');
    }
  }
}

export function resetFormatAdSurround() {
  const mainWrapper = (window.document.querySelector('.main-wrapper') as HTMLElement) || null;
  mainWrapper.style.paddingTop = '15px';

  const contentColumn = (window.document.querySelector('.col-main') as HTMLElement) || null;
  if (contentColumn) {
    contentColumn.style.paddingTop = '0';
  }
}
