import BlockType26 from '@headlines/components/block/21-30/BlockType26.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType26: AdminBlockType = {
  component: BlockType26,
  attributes: AdminBlockTypeAttributes({
    adType: 'mobile',
    skipLazyLoad: true,
  }),
  articlesCount: 0,
};

export default AdminBlockType26;
