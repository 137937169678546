
import Vue from 'vue';
import { ListData, HeaderActions } from '@root/modules/navigation/types/navigation';
import CustomerMenuDropdown from './CustomerMenuDropdown.vue';
import { TranslateResult } from 'vue-i18n';
import { gridConfig } from '@core/config/grid.config';
import rootConfig from '@root/config/root.config';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Data {
  showCustomerMenuDropdown: boolean;
}

interface Props {
  misc: Record<string, ListData>;
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  logout: () => void;
  openLoginModal: () => void;
}

interface Methods {
  closeMenu: () => void;
  toggleSidebar: () => void;
  toggleLogin: () => void;
  toggleAction: (type: HeaderActions['type'], value: HeaderActions['value']) => void;
}

interface Computed {
  isSitemapOpen: boolean;
  isSearchOpen: boolean;
  isProfileInfoOpen: boolean;
  isSidebarOpen: boolean;
  userName: string | null;
  backgroundColor: string | null;
  orderButtonText: TranslateResult;
  orderButtonLink: TranslateResult;
  disableLogin: boolean;
  maintenanceMode: boolean;
  isMobileView: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CustomerMenuDropdown,
  },
  props: {
    misc: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showCustomerMenuDropdown: false,
    };
  },
  computed: {
    isSitemapOpen() {
      return this.$store.getters['navigation/getHeaderAction']('sitemap');
    },
    isSearchOpen() {
      return this.$store.getters['navigation/getHeaderAction']('search');
    },
    isProfileInfoOpen() {
      return this.$store.getters['navigation/getHeaderAction']('profileInfo');
    },
    isSidebarOpen() {
      return this.$store.getters['navigation/getHeaderAction']('sidebar');
    },
    userName() {
      if (!this.data.isLoggedIn) {
        return null;
      }

      return this.$store.state.piano.profile?.firstName
        ? this.$store.state.piano.profile?.firstName
        : this.$store.state.piano.extendedProfile?.displayName
        ? this.$store.state.piano.extendedProfile?.displayName
        : this.$store.state.piano.profile?.email.split('@')[0];
    },
    backgroundColor() {
      if (!this.data.isLoggedIn) {
        return null;
      }

      if (this.userName) {
        let hash = 0;
        let bgColor = null;

        for (let i = 0; i < this.userName.length; i += 1) {
          hash = this.userName.charCodeAt(i) + ((hash << 5) - hash);
        }

        // eslint-disable-next-line unicorn/number-literal-case
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();
        bgColor = '00000'.substring(0, 6 - c.length) + c;

        return `#${bgColor}`;
      }

      return null;
    },
    orderButtonText() {
      return this.$store.state.piano.access.channelAccess ? this.$t('header.order_customer') : this.$t('header.order_not_customer');
    },
    orderButtonLink() {
      return this.$store.state.piano.access.channelAccess ? this.$t('header.order_customer_link') : this.$t('header.order_not_customer_link');
    },
    maintenanceMode() {
      return this.$store.state.piano.config.maintenanceMode;
    },
    disableLogin() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return rootConfig.disableLogin || (!!this.$channelConfig('settings').disableLogin && this.isMobileView) || specialFeatures.hideSignupAndOrders.enabled;
    },
    isMobileView() {
      if (process.client) {
        const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;

        return window.screen.width <= gridConfig.breakpoints.lg || isIpad;
      } else {
        return this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
      }
    },
  },
  methods: {
    toggleLogin() {
      if (this.data.isLoggedIn) {
        this.showCustomerMenuDropdown = !this.showCustomerMenuDropdown;
      } else {
        this.openLoginModal();
      }
    },
    toggleAction(type, value) {
      this.$store.commit('navigation/setHeaderAction', { type, value });
    },
    closeMenu() {
      if (!this.isSitemapOpen) {
        return;
      }
      this.toggleAction('sitemap', false);
    },
    toggleSidebar() {
      this.toggleAction('sidebar', !this.isSidebarOpen);
    },
  },
});
