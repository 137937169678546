import { Analytics } from '../../types/Analytics';

import Awindow from '../../types/Awindow';
import logger from '../../util/logger';
import {
  AnalyticsFieldsObject,
  FieldsObject,
} from './types/GoogleAnalyticsBase';
import loadGoogleAnalyticsScript from './utils/loadGoogleAnalyticsScript';

declare let window: Awindow;

export default class GoogleAnalytics implements Analytics {
  private prefix = '';

  /**
   * Load script and initialize tracker
   */
  public async init() {
    await this.loadScript();
  }

  /**
   * Use this in case a tracker has been created elsewhere with a name
   *
   * @param name
   */
  public setName(name: string) {
    this.prefix = `${name}.`;
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers
   * @param trackingId
   * @param name
   * @param extraFields
   */
  public create(
    trackingId: string,
    name: string | null = null,
    extraFields: FieldsObject = {},
  ): void {
    if (trackingId === '') {
      logger.error('Empty trackingId not allowed');
      return;
    }

    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
    const defaults: FieldsObject = {
      cookieDomain: 'auto',
    };

    try {
      if (window.ga) {
        if (name) {
          extraFields.name = name;
          this.setName(name);
        }
        window.ga('create', trackingId, {
          ...defaults,
          ...extraFields,
        });
      }
    } catch (e) {
      logger.error(`ga.create threw an error ${e}`);
    }
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
   * @param url
   * @param title
   * @param extraFields
   */
  public pageView(
    url?: string,
    title?: string,
    extraFields: FieldsObject = {},
  ) {
    if (!window.ga) {
      logger.error(`ga pageview called but library not found on page`);
      return;
    }

    const analyticsFieldsObject: FieldsObject = {
      ...extraFields,
      hitType: 'pageview',
    };

    if (url) {
      if (url.match(/^https?:\/\//)) {
        // URL of the page.
        // e.g location.href
        analyticsFieldsObject.location = url;
      } else {
        // The path portion of a URL. This value should start with a slash (/) character.
        // e.g location.pathname
        analyticsFieldsObject.page = url;
      }
    }

    if (title) {
      analyticsFieldsObject.title = title;
    }

    window.ga(`${this.prefix}send`, analyticsFieldsObject);
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   * @param extraFields
   */
  public event(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    extraFields: FieldsObject = {},
  ) {
    if (!window.ga) {
      logger.error(`ga event called but library not found on page`);
      return;
    }

    window.ga(`${this.prefix}send`, {
      hitType: 'event',
      ...extraFields,
      eventAction,
      eventCategory,
      eventLabel,
    });
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/accessing-trackers
   * @param data
   */
  public set(data: AnalyticsFieldsObject | FieldsObject) {
    if (!window.ga) {
      logger.error(`ga.set called but library not found on page`);
      return;
    }

    window.ga(`${this.prefix}set`, data);
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/accessing-trackers
   * @param key
   * @param value
   */
  public setVariable(
    key: keyof AnalyticsFieldsObject | keyof FieldsObject,
    value: string | number,
  ) {
    if (!window.ga) {
      logger.error(`ga.set called but library not found on page`);
      return;
    }

    window.ga(`${this.prefix}set`, key, value);
  }

  private async loadScript() {
    if (window.ga) {
      return;
    }

    await loadGoogleAnalyticsScript();
  }
}
