import BlockType15 from '@headlines/components/block/11-20/BlockType15.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType15: AdminBlockType = {
  component: BlockType15,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: true,
    titleComment: true,
    channel: true,
    category: true,
    iframe: false,
    embedName: '',
  }),
  articlesCount: 3,
};

export default AdminBlockType15;
