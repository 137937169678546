import { MutationTree, GetterTree } from 'vuex';
import { State, Section } from '@root/modules/frontpage/types/frontpage';
import { Pager } from '@root/common/types/fragment';

export const state: () => State = () => ({
  refetch: false,
  feedData: null,
  pagerData: {},
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getFeedData: (state) => state.feedData,
  getPagerData: (state) => state.pagerData,
};

export const mutations: MutationTree<State> = {
  setRefetchState(state, value: boolean) {
    state.refetch = value;
  },
  setFeedData(state, value: Section | null) {
    state.feedData = value;
  },
  setPagerData(state, value: Pager) {
    state.pagerData = value;
  },
  resetFeedData(state) {
    state.feedData = null;
  },
};
