type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

interface Settings {
  adFree: boolean;
}

type OptionalSettings = DeepPartial<Settings>;

type SettingsKeys = keyof Settings;

export type GetPortalAdsSettings = <K extends SettingsKeys | 'config'>(key: K) => K extends SettingsKeys ? Settings[K] : Settings;

const defaultSettings = {
  adFree: false,
};

let globalPortalAdsSettings: () => typeof defaultSettings | null = () => {
  return null;
};

function setPortalAdsSettings(config: OptionalSettings) {
  const { adFree } = config;

  const settings = {
    adFree: adFree || defaultSettings.adFree,
  };

  globalPortalAdsSettings = () => {
    return settings;
  };
}

const getPortalAdsSettings: GetPortalAdsSettings = (key) => {
  const settings = globalPortalAdsSettings() || defaultSettings;
  const noKey = !key || key === 'config';
  // @ts-ignore
  return noKey ? settings : settings[key];
};

export { setPortalAdsSettings, getPortalAdsSettings, OptionalSettings as Settings };
