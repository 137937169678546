import { Analytics } from '@analytics/types/Analytics';
import logger from '@analytics/util/logger';
import { GtmOptions } from './types/Gtm';
import loadGtmScript from './utils/loadGtmScript';

export default class Gtm implements Analytics {
  private options!: GtmOptions;

  public init(options: GtmOptions) {
    if (!options.containerId) {
      logger.error('Gtm container id not provided');
      return;
    }
    this.options = options;
    this.loadScript();
  }

  private loadScript() {
    const { containerId } = this.options;
    loadGtmScript(containerId);
  }
}
