
import Vue from 'vue';
import BaseIcon from '../BaseIcon.vue';
import { AlertComponentProps } from './alert';

type Props = AlertComponentProps;

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    type: {
      type: String as () => Props['type'],
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
