export class ServiceError extends Error {
  statusCode: number;
  extraData: string;

  constructor({ message, statusCode, extraData }: { message: string; statusCode: number; extraData: string }) {
    super(message);

    this.statusCode = statusCode;
    this.extraData = extraData;
  }
}
