
import Vue from 'vue';
import { ProcessedStory } from '@headlines/types/Story';

interface Methods {
  openModal: (startPosition: number, event: MouseEvent) => void;
}

interface Props {
  stories: ProcessedStory[];
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    stories: {
      type: Array as () => Props['stories'],
      required: true,
      validator: (value: ProcessedStory[]) => {
        for (const story of value) {
          for (const item of story.items) {
            if (!item.media.photoSrc && !item.media.videoSrc) {
              return false;
            }
          }
        }

        return true;
      },
    },
  },
  methods: {
    openModal(startPosition, event) {
      const offsetX = event?.clientX || 0;
      const offsetY = event?.clientY || 0;

      this.$emit('toggle-modal', true, { startPosition, offsetX, offsetY });
    },
  },
});
