import config from '@media/config/media.config';
import stringTemplate from '@ekd/js-stringtemplate';
import { VideoItem } from '@media/types/getVideo';
import { AudioFragment } from '@media/types/AudioFragment';

type vastTypes = 'pre' & 'post' & 'overlay';
type vast = 'stream' | 'default' | 'special' | 'audio';
type adsSchedule = { offset: string; tag: string }[];

const playerAds = (type: string, metaData?: VideoItem['metadata'] | AudioFragment['attrs']['metadata'], adsData?: Record<string, unknown>) => {
  let advertizing = {};
  const { ads } = config;
  const vastData = ads.ids[type as vast] || ads.ids.default;
  const time = +new Date();
  const schedule: adsSchedule = [];
  const noAds = metaData?.adsDisabled || adsData?.adFree;

  if (!noAds) {
    Object.keys(vastData).forEach((key: string) => {
      const mkw = ((adsData?.keywords as string[]) || []).join(',') || '';
      const vastSchedule = {
        offset: key,
        tag: stringTemplate(config.ads.vastUrl, { vastId: vastData[key as vastTypes], time, mkw }),
      };
      schedule.push(vastSchedule);
    });

    advertizing = {
      client: 'vast',
      autoplayadsmuted: true,
      schedule,
      bids: ads.bids,
    };
  }

  return advertizing;
};

export { playerAds };
