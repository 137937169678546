import { Plugin } from '@nuxt/types';
import { Config } from '@root/modules/channel/types/channel';
import { Options } from '@root/libs/init-channel/types';
import initMetaInfo from '@root/libs/init-channel/utils/initMetaInfo';
import initChannelConfig from '@root/libs/init-channel/utils/initGetChannelConfig';
// Portal Root uses this plugin to init all necessary configuration
// initChannel steps:
// 1. fetchChannelConfig - get channelConfig from storage
// 2. initMetaInfo - init base meta we got from fetchChannelConfig (links, styles, scripts etc)
// 3. inject channelConfig - make channelConfig accessible via this.$channelConfig
// 4. Reset storage and variables for memory optimization

const initChannel: Plugin = async (ctx, inject) => {
  const { app, store } = ctx;

  // Get module options from module.ts
  const options: Options = JSON.parse(`{"resource":{"styleResources":["boredpanda","delfi","login","m360","multimedija","tvarilietuva","verslas","video"]}}`);
  const { resource } = options;

  // 1. fetchChannelConfig
  let channelConfig: Config | null = store.getters['getStorage']('channelConfig');
  if (!channelConfig) {
    app.$sentry.captureException(`initChannel: channelConfig not found: ${store.getters['getStorage']('channelId')}`, {
      tags: { module: 'initChannel', 'process.server': process.server ? 'server' : 'client' },
    });
    ctx.error({ statusCode: 500 });
    return;
  }

  // 2. initMetaInfo
  await initMetaInfo(app, channelConfig as Config, resource);

  // 3. inject channelConfig
  inject('channelConfig', initChannelConfig(channelConfig as Config));

  store.commit('navigation/setHeaderNavigation', channelConfig.header.navigation);
  store.commit('navigation/setFooterNavigation', channelConfig.footer.navigation);
  store.commit('navigation/setMobileNavigation', channelConfig.header.mobileNavigation);

  // 4. Clean up temporarily saved channel data
  if (process.client) {
    store.commit('resetStorage', ['channelConfig', 'channelId']);
  }

  // Reset variables for memory optimization
  channelConfig = null;
};

export default initChannel;
