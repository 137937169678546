import { Instance, instanceSettings } from '@ekd/lt-portal-connector/dist/Instance';

import { InstanceContext, Instances } from '@root/common/types/instanceConnector';

import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

@instanceSettings({ name: 'headlines' })
export class HeadlinesInstance extends Instance<InstanceContext, Instances> {
  public listen(listenTo: 'headlineClick', cb: (data: { headline: HeadlineWithRelatedArticles }) => void, { once } = { once: false }) {
    const method = once ? 'once' : 'on';
    this.context.$eventHandler[method](listenTo, cb);
  }

  public headlineClick(data: { headline: HeadlineWithRelatedArticles }) {
    this.context.$eventHandler.emit('headlineClick', data);
  }
}
