
import BaseBlock from '@headlines/utils/BaseBlock';
import BaseButton from '@core/components/UI/BaseButton.vue';
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineLead, HeadlineIcons } from '../../../fragments';
import ContentFragment from '@core/components/ContentFragment.vue';
import BlockTabs from '../../../BlockTabs.vue';
import HeadlineTitleList from '../../../HeadlineTitleList.vue';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType13';
import GetTabsHeadlinesService from '@headlines/services/GetTabsHeadlines.service';
import { DataWithHref, GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';

interface Data {
  tabs: {
    type: 'BlockTypeList';
    attributes: {
      title: string;
      listType: string;
      showDate: boolean;
    };
    data: {
      items: DataWithHref[];
    };
  }[];
}

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  initialTab: 1,
  showTabsComments: true,
  lead: true,
  mobileHeadlineListLimit: 5,
  type: 'list',
  includeChannels: [],
  excludeCategories: [],
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, unknown, unknown, unknown>({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineLead,
    BlockTabs,
    HeadlineTitleList,
    BaseButton,
    ContentFragment,
  },
  data() {
    return {
      tabs: [
        {
          type: 'BlockTypeList',
          attributes: {
            title: String(this.$t('portal_core.latest_headlines')),
            listType: 'none',
            showDate: true,
          },
          data: {
            items: [],
          },
        },
        {
          type: 'BlockTypeList',
          attributes: {
            title: String(this.$t('portal_core.most_read_headlines')),
            listType: 'number',
            showDate: false,
          },
          data: {
            items: [],
          },
        },
      ],
    };
  },
  async created() {
    const variables: GetUniversalHeadlines['variables'] = {
      limit: 10,
      channelId: this.getSettings.includeChannels,
      excludeCategories: this.getSettings.excludeCategories,
    };

    if (this.getSettings.includeCategories) {
      variables.categoryId = this.getSettings.includeCategories;
    }

    const fakeDomainEnabled = typeof this.$config !== 'undefined' && this.$config.FAKE_DOMAIN_ENABLED;

    const getTabsHeadlinesService = new GetTabsHeadlinesService();
    const headlines = await getTabsHeadlinesService.fetch(variables, { domain: this.domain, fakeDomainEnabled });

    this.tabs[0].data.items = headlines.latestHeadlines.items;
    this.tabs[1].data.items = headlines.mostReadHeadlines.items;
  },
});
