type Srcset = Record<string, { cropperData: Record<string, unknown> }>;

export default {
  default: {
    center: {
      cropperData: {
        lg: {
          width: 1200,
        },
        md: {
          width: 400,
        },
      },
    },
    left: {
      cropperData: {
        lg: {
          width: 450,
        },
      },
    },
    right: {
      cropperData: {
        lg: {
          width: 450,
        },
      },
    },
  } as Srcset,
  sidebar: {
    center: {
      cropperData: {
        lg: {
          width: 1200,
        },
      },
    },
    left: {
      cropperData: {
        lg: {
          width: 450,
        },
      },
    },
    right: {
      cropperData: {
        lg: {
          width: 450,
        },
      },
    },
  } as Srcset,
};
