
import { PropType } from 'vue';
import { Fragment } from './Fragment';
import ContentImage from '@core/components/UI/ContentImage.vue';
import ContentImageLightbox from '@core/components/UI/ContentImageLightbox.vue';
import PictureInfo from '@core/components/PictureInfo.vue';
import pictureConfig from '@core/config/picture.config';

import { FragmentContent } from '@core/types/fragment';

interface Metadata {
  title: string | null;
  credit: string | null;
  alignment: string | null;
  authors: string[];
}

type CropperData = Record<string, any>;

type CropperDataByPosition = Record<string, { cropperData: Record<string, unknown> }> | null;

interface PulloutContent extends FragmentContent {
  attrs: FragmentContent['attrs'] & {
    cropperData: CropperData;
    metadata: Metadata;
  };
}

const fragment = new Fragment();

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentPicture',
  props: {
    cropperDataByPosition: {
      type: Object as PropType<CropperDataByPosition>,
      required: false,
    },
    content: {
      type: Object as PropType<PulloutContent>,
      required: true,
    },
  },
  components: {
    ContentImage,
    ContentImageLightbox,
    PictureInfo,
  },
  data() {
    return {
      metadata: this.content.attrs?.metadata,
      cropperData: this.content.attrs?.cropperData,
      alignment: this.content.attrs?.metadata?.alignment?.toLowerCase() || 'center',
      pictureLoaded: false,
      showLightbox: false,
    };
  },
  computed: {
    defaultClasses() {
      const { type, contentType, alignment } = this;

      const className: Record<string, boolean> = {};

      className[`fragment`] = true;
      className[`fragment--align-${alignment}`] = !!alignment;
      className[`fragment-${contentType}`] = !!contentType;
      className[`fragment-${contentType}--${type}`] = !!contentType && !!type;

      return className;
    },
    caption(): string | null {
      return this.metadata?.title || null;
    },
    credit(): string | null {
      return this.metadata?.credit || null;
    },
    authors(): string[] | null {
      return this.metadata?.authors?.length && this.metadata.authors[0] ? this.metadata.authors : null;
    },
  },
  created() {
    this.cropperData = this.getCropperData();
  },
  mounted() {
    this.$nextTick(() => {
      this.pictureLoaded = true;
    });
  },
  methods: {
    handleShowLightBox() {
      this.showLightbox = !this.showLightbox;
    },
    getBaseCropperData() {
      const { cropperData } = this.content.attrs;

      const baseCropperData = {
        ...cropperData,
        ...this.getFocuspoint(),
      };

      return baseCropperData;
    },
    getFocuspoint() {
      const { cropperData } = this.content.attrs;
      const focuspoints: Record<string, unknown> = {};

      if (cropperData.focuspoint?.x) {
        focuspoints.fx = cropperData.focuspoint.x;
      }

      if (cropperData.focuspoint?.y) {
        focuspoints.fy = cropperData.focuspoint.y;
      }

      return focuspoints;
    },
    getCustomCrops(cropperData: Record<string, unknown> = {}) {
      const customCropperData = { ...cropperData };
      const customCrops = this.content.attrs.cropperData.customCrops;
      const ratio = this.content.attrs.cropperData.ratio;
      const customCrop = customCrops?.find((customCrop: Record<string, unknown>) => customCrop.ratio === ratio);

      if (!customCrop) {
        return customCropperData;
      }

      for (const size in customCropperData) {
        customCropperData[size] = {
          // @ts-ignore
          ...customCropperData[size],
          customCrop,
        };
      }

      return customCropperData;
    },
    getCropperData() {
      const ratio = this.content.attrs.cropperData.ratio;
      // Fallback to center if no match
      let cropperData = this.cropperDataByPosition
        ? this.cropperDataByPosition[this.alignment]?.cropperData
        : pictureConfig.default[this.alignment]?.cropperData || pictureConfig.default.center.cropperData;

      if (ratio) {
        cropperData = this.getCustomCrops(cropperData);
      }

      return {
        ...cropperData,
        base: this.getBaseCropperData(),
      };
    },
  },
});
