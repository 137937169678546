
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';

// Use when you need base theme, icon only or custom theme button.
// BaseButton can be button, nuxt-link or normal link
// LinkHandler decides BaseButton link type

interface Props {
  theme: string;
  size: string;
  icon: string;
  iconPosition: string;
  href: string;
}

interface Data {
  showSlot: boolean;
}

interface Computed {
  iconLeft: boolean;
  iconRight: boolean;
  classes: (string | { [x: string]: boolean })[];
  tagProps: Record<string, unknown>;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    LinkHandler,
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    /**
     * Base styles for button
     * @values 'primary', 'grey'
     */
    theme: {
      validator(value: string) {
        return oneOf(value, ['primary', 'secondary', 'tertiary', 'grey', '']);
      },
      type: String,
      // No styles by default
      // Use util classes to change button appearance
      default: () => '',
      required: false,
    },
    /**
     * @values 'auto', 'small', 'large', 'default'
     */
    size: {
      validator(value: string) {
        return oneOf(value, ['auto', 'small', 'large', 'default']);
      },
      type: String,
      default: () => 'default',
      required: false,
    },
    // Takes icon from svg/icons folder
    /**
     * Render icon with the help of BaseIcon component
     * @values All icon names that are specified inside BaseIcon
     */
    icon: {
      type: String,
      default: () => '',
      required: false,
    },
    /**
     * @values 'left', 'right'
     */
    iconPosition: {
      validator(value: string) {
        return oneOf(value, ['left', 'right']);
      },
      type: String,
      default: () => 'right',
      required: false,
    },
    /**
     * Render either nuxt-link or default 'a' link. <br>
     * Renders 'a' link if link contains http
     */
    href: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data() {
    return {
      showSlot: true,
    };
  },
  computed: {
    iconLeft() {
      return !!this.icon && this.iconPosition === 'left';
    },
    iconRight() {
      return !!this.icon && this.iconPosition === 'right';
    },
    classes() {
      const iconOnly: boolean = !this.showSlot && !!this.icon;
      return [
        'button',
        {
          [`button--${this.theme}`]: !!this.theme,
          [`button--size-${this.size}`]: !iconOnly,
        },
      ];
    },
    // Creates props for LinkHandler
    tagProps() {
      const { $attrs, href, classes } = this;
      // Return new object with all inherited props.
      return { ...$attrs, href, button: true, class: classes };
    },
  },
  created() {
    this.showSlot = this.$slots.default !== undefined;
  },
});
