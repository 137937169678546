import Vue from 'vue';

const truncate = (string: string, len: number) => (len && string.length > len ? `${string.substring(0, len)}...` : string);
const stripTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, '');
const ucfirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

Vue.filter('truncate', truncate);
Vue.filter('stripTags', stripTags);
Vue.filter('ucfirst', ucfirst);

export { truncate, stripTags };
