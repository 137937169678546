
import Vue from 'vue';
// Creates html element depending on props.
// Renders: | a | nuxt-link | button | span
interface Props {
  id?: string;
  href?: string;
  external?: boolean;
  type?: string;
  target?: string;
  button?: boolean;
  itemprop?: string;
}
export default Vue.extend<Props>({
  name: 'LinkHandler',
  functional: true,
  render(h, { props, slots, data }) {
    const { id, href, external, target, type, button, itemprop } = props;
    const slot = slots().default;

    let tag = 'span';
    let attrs = {};
    const nuxtLink = !(external || (href && href.indexOf('http') === 0));

    // Create nuxt-link or external link
    if (href) {
      switch (nuxtLink) {
        case true:
          tag = 'nuxt-link';
          attrs = {
            to: !!href && href,
            itemprop: !!itemprop && itemprop,
          };
          break;
        case false:
          tag = 'a';
          attrs = {
            id: !!id && id,
            href: !!href && href,
            target: !!target && target,
            itemprop: !!itemprop && itemprop,
          };
          break;
      }
    }

    // Create button
    if (button && !href) {
      tag = 'button';
      attrs = {
        id: !!id && id,
        type: (!!type && type) || 'button',
      };
    }

    const linkData = {
      ...data,
      attrs,
      domProps: data.domProps,
    };

    // Replace native click event on default html tags
    if (!nuxtLink && linkData.nativeOn) {
      linkData.on = linkData.nativeOn;
      delete linkData.nativeOn;
    }
    // Create correct tag | a | nuxt-link | button | span
    return h(tag, linkData, slot);
  },
});
