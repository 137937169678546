import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const adType: AdminBlockTypeAttribute = {
  label: 'Choose mobile layout',
  key: 'adType',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: 'mobile',
  typeOptions: [
    { key: 'Content', value: 'content' },
    { key: 'Mobile', value: 'mobile' },
    { key: 'Tower', value: 'tower' },
    { key: 'Custom', value: 'custom' },
  ],
};

export default adType;
