import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

import fetch from 'isomorphic-fetch';
import config from '../config/media.config';

// HTTP connection to the API
const uri = config.api.mediaGraphqlApiUrl;
const httpLink = new HttpLink({ uri, fetch });
const linkChain = createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }).concat(httpLink);

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: linkChain,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
