import { AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';
import icons from '@headlines/config/block/shared/attributes/icons';
import author from '@headlines/config/block/shared/attributes/author';
import titleComment from '@headlines/config/block/shared/attributes/titleComment';
import channel from '@headlines/config/block/shared/attributes/channel';
import category from '@headlines/config/block/shared/attributes/category';
import relatedTitles from '@headlines/config/block/shared/attributes/relatedTitles';
import isBorderEnabled from '@headlines/config/block/shared/attributes/isBorderEnabled';
import textColor from '@headlines/config/block/shared/attributes/textColor';
import accentColor from '@headlines/config/block/shared/attributes/accentColor';
import backgroundColor from '@headlines/config/block/shared/attributes/backgroundColor';
import publishDate from '@headlines/config/block/shared/attributes/publishDate';
import contentSeparator from '@headlines/config/block/shared/attributes/contentSeparator';
import lead from '@headlines/config/block/shared/attributes/lead';
import className from '@headlines/config/block/shared/attributes/className';
import iframe from '@headlines/config/block/shared/attributes/iframe';
import type from '@headlines/config/block/shared/attributes/type';
import streamsLimit from '@headlines/config/block/shared/attributes/streamsLimit';
import showAllStreams from '@headlines/config/block/shared/attributes/showAllStreams';
import text from '@headlines/config/block/shared/attributes/text';
import logo from '@headlines/config/block/shared/attributes/logo';
import secondaryLogo from '@headlines/config/block/shared/attributes/secondaryLogo';
import links from '@headlines/config/block/shared/attributes/links';
import colorBlock from '@headlines/config/block/shared/attributes/colorBlock';
import pictureShadowColor from '@headlines/config/block/shared/attributes/pictureShadowColor';
import showFbShares from '@headlines/config/block/shared/attributes/showFbShares';
import initialTab from '@headlines/config/block/shared/attributes/initialTab';
import excludeContentMarketing from '@headlines/config/block/shared/attributes/excludeContentMarketing';
import excludeIssueOnly from '@headlines/config/block/shared/attributes/excludeIssueOnly';
import excludeSpecialProject from '@headlines/config/block/shared/attributes/excludeSpecialProject';
import includeChannels from '@headlines/config/block/shared/attributes/includeChannels';
import excludeCategories from '@headlines/config/block/shared/attributes/excludeCategories';
import embedName from '@headlines/config/block/shared/attributes/embedName';
import adType from '@headlines/config/block/shared/attributes/adType';
import skipLazyLoad from '@headlines/config/block/shared/attributes/skipLazyLoad';
import channelDomain from '@headlines/config/block/shared/attributes/channelDomain';
import slidesPerBreakpoint from '@headlines/config/block/shared/attributes/slidesPerBreakpoint';
import autoplay from '@headlines/config/block/shared/attributes/autoplay';
import loop from '@headlines/config/block/shared/attributes/loop';
import topMargin from '@headlines/config/block/shared/attributes/topMargin';

export type AllowedAttributes =
  | 'icons'
  | 'author'
  | 'titleComment'
  | 'channel'
  | 'category'
  | 'relatedTitles'
  | 'isBorderEnabled'
  | 'textColor'
  | 'publishDate'
  | 'contentSeparator'
  | 'lead'
  | 'className'
  | 'iframe'
  | 'type'
  | 'streamsLimit'
  | 'showAllStreams'
  | 'accentColor'
  | 'text'
  | 'logo'
  | 'secondaryLogo'
  | 'links'
  | 'colorBlock'
  | 'pictureShadowColor'
  | 'showFbShares'
  | 'backgroundColor'
  | 'initialTab'
  | 'excludeContentMarketing'
  | 'excludeIssueOnly'
  | 'excludeSpecialProject'
  | 'includeChannels'
  | 'excludeCategories'
  | 'embedName'
  | 'channelDomain'
  | 'adType'
  | 'skipLazyLoad'
  | 'slidesPerBreakpoint'
  | 'autoplay'
  | 'loop'
  | 'topMargin';

export type AdminAttributesCollection = Record<AllowedAttributes, AdminBlockTypeAttribute>;
export type AttributeTypes = boolean | string | number | [];
export type AdminAttributeType = Partial<Record<AllowedAttributes, AttributeTypes>>;

// eslint-disable-next-line no-redeclare
const AdminAttributesCollection: AdminAttributesCollection = {
  icons,
  author,
  titleComment,
  channel,
  category,
  relatedTitles,
  isBorderEnabled,
  textColor,
  accentColor,
  backgroundColor,
  publishDate,
  contentSeparator,
  lead,
  className,
  iframe,
  type,
  streamsLimit,
  showAllStreams,
  text,
  logo,
  secondaryLogo,
  links,
  colorBlock,
  pictureShadowColor,
  showFbShares,
  embedName,
  initialTab,
  excludeContentMarketing,
  excludeIssueOnly,
  excludeSpecialProject,
  includeChannels,
  excludeCategories,
  channelDomain,
  adType,
  skipLazyLoad,
  slidesPerBreakpoint,
  autoplay,
  loop,
  topMargin,
};

export default AdminAttributesCollection;
