
import Vue from 'vue';

interface Computed {
  additionalLogo: string | null;
}

export default Vue.extend<unknown, unknown, Computed, unknown>({
  computed: {
    additionalLogo() {
      const channel = this.$channelConfig('settings').id;
      const category = this.$store.getters['category/getCategoryData'];
      if (channel === 'miestai' && category) {
        return `https://g.delfi.lt/glt/c/delfi-header-2017/l/m/miestai-${category.slug}.svg`;
      }

      return null;
    },
  },
});
