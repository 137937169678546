import { Context } from '@nuxt/types';
import { Config } from '../../../modules/channel/types/channel';
import rootConfig from '@root/config/root.config';
import { Store } from 'vuex';

// Save signature in cookies for CSR, because req.headers['x-signature'] are not accessible in CSR
function getSignature(req: Context['req'], res: Context['res'], store: Store<any>) {
  let savedSignature: string | null = null;

  if (process.server) {
    const signature = req.headers['x-signature'] as string;
    if (signature) {
      store.commit('setStorage', { xSignature: signature });
      savedSignature = signature;
    }
  } else {
    savedSignature = store.getters['getStorage']('xSignature');
    store.commit('resetStorage', ['xSignature']);
  }

  return savedSignature;
}

function getApplication(ua: string) {
  let app = null;

  for (const application of rootConfig.applications) {
    if (ua.match(application.ua)) {
      app = application;
    }
  }

  return app;
}

function isIpAllowedToAccess(req: Context['req'], store: Store<any>) {
  let isIpAllowedToAccess = false;

  if (process.server) {
    const ip = req?.headers['x-forwarded-for']?.toString()?.split(',')[0] || req?.connection?.remoteAddress || req?.socket?.remoteAddress || null;

    isIpAllowedToAccess = ip ? rootConfig.readAccessIps.includes(ip) : false;
    store.commit('setStorage', { isIpAllowedToAccess });
  } else {
    isIpAllowedToAccess = store.getters['getStorage']('isIpAllowedToAccess');
    store.commit('resetStorage', ['isIpAllowedToAccess']);
  }

  return isIpAllowedToAccess;
}

function initApplicationType(req: Context['req'], res: Context['res'], store: Store<any>) {
  const ua: string | undefined | null = process.server ? req.headers['user-agent'] : navigator.userAgent;
  const signature = getSignature(req, res, store);
  const ipAllowedToAccess = isIpAllowedToAccess(req, store);

  const rootApplication: Config['settings']['application'] = {
    type: null,
    signature: null,
    isSearchBot: false,
    specialFeatures: {
      forceArticleRefetch: false,
      adFree: false,
      disableHeader: false,
      disableConsentModal: false,
      hideSignupAndOrders: {
        enabled: false,
        affectedApps: null,
      },
    },
  };

  if (!ua) {
    return rootApplication;
  }

  // Select application based on user agent
  const application = getApplication(ua);

  rootApplication.isSearchBot = !!ua.toLowerCase().match(rootConfig.searchBotsRegex);
  rootApplication.type = application?.type || null;
  rootApplication.signature = application?.signature ? signature : null;
  rootApplication.specialFeatures = application?.specialFeatures || rootApplication.specialFeatures;

  const { hideSignupAndOrders } = rootApplication.specialFeatures;
  const { affectedApps } = hideSignupAndOrders;

  if (hideSignupAndOrders.enabled && affectedApps && affectedApps.length > 0) {
    const appUARegexResult = application?.ua.exec(ua);

    let hideSignupAndOrdersResult = false;

    for (const affectedApp of affectedApps) {
      const isSameVersion = appUARegexResult?.[3] === affectedApp.version;
      const isSameChannel = appUARegexResult?.[2] === affectedApp.channel;

      if (affectedApp.channel && affectedApp.version) {
        if (isSameVersion && isSameChannel) {
          hideSignupAndOrdersResult = true;
        }
      } else if (affectedApp.channel && isSameChannel) {
        hideSignupAndOrdersResult = true;
      } else if (affectedApp.version && isSameVersion) {
        hideSignupAndOrdersResult = true;
      }
    }

    rootApplication.specialFeatures.hideSignupAndOrders.enabled = hideSignupAndOrdersResult;
  }

  if (ipAllowedToAccess) {
    rootApplication.specialFeatures.forceArticleRefetch = ipAllowedToAccess;
  }

  return rootApplication;
}

export default initApplicationType;
