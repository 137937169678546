
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { Mobile } from '@root/modules/navigation/types/navigation';
import BookmarkButton from '@root/common/components/base/BookmarkButton.vue';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';
import SocialTooltip from '@root/common/components/base/SocialTooltip.vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import { RouteConfig } from 'vue-router';
import rootConfig from '@root/config/root.config';

interface Methods {
  openSitemap(): void;
  isUrlActive(url: string): boolean;
  trackNavigationClicks(itemName: string): void;
}

interface Computed {
  isArticle: boolean;
  activeArticle: ArticleData | null;
  articleCommentsPath: string;
  disableLogin: boolean;
}

interface Props {
  navigation: Mobile[];
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    LinkHandler,
    BookmarkButton,
    PianoCustomerStateHandler,
    SocialTooltip,
  },
  props: {
    navigation: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isArticle() {
      return this.$route.name === 'article';
    },
    activeArticle() {
      return this.$store.getters['article/getActiveArticle'];
    },
    articleCommentsPath() {
      const { domain, locale } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      return this.$options.filters!.articleCommentsUrl({ article: this.activeArticle, locale, domain, fakeDomainEnabled });
    },
    disableLogin() {
      const { specialFeatures } = this.$channelConfig('settings').application || {};
      return rootConfig.disableLogin || !!this.$channelConfig('settings').disableLogin || specialFeatures?.hideSignupAndOrders?.enabled;
    },
  },
  methods: {
    openSitemap() {
      this.$store.commit('navigation/setHeaderAction', { type: 'sitemap', value: true });
    },
    isUrlActive(url) {
      const isFakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      // All defined routes and they aliases
      const routes = this.$router.options.routes;
      let uri;
      let baseUrl;

      if (isFakeDomainEnabled) {
        // In case of fake domain enabled (reviewapp, local dev host)
        // document.location.origin will keep urls based on dev link
        baseUrl = process.client ? document.location.origin : '';
      } else {
        // In production case URLs are generated towards channel actual link
        // to avoid weird cases, it is better to take it from config
        // instead of document.location.origin
        baseUrl = this.$channelConfig('meta').base.baseUrl;
      }

      try {
        // If url and baseUrl are given, URl class will put them together
        // if url is relative, it will be added to baseUrl
        // if url is absolute, it will replace provided baseUrl
        uri = new URL(url, baseUrl);
      } catch {
        // URL class in kinda unstable
        // is recommended to wrap it into try catch
        return false;
      }

      if (!uri || !routes) {
        return false;
      }

      let result: boolean;
      // matchedRoute is needed to check routes by names, not paths
      let matchedRoute: RouteConfig | undefined = undefined;

      for (const route of routes) {
        // Try to find correct route by path name
        result = route.path === uri.pathname;

        if (!result && route.alias) {
          // Probably pathname will not match any route.path,
          // because it is not aliased (/viimased !== /latest)
          // so, try to find path from aliases
          for (const aliasItem of route.alias) {
            result = aliasItem === uri.pathname;

            if (result) {
              break;
            }
          }
        }

        if (result) {
          matchedRoute = route;
          break;
        }
      }

      // uri.origin === this.$channelConfig('meta').base.baseUrl
      // check is needed to be sure, that provided url
      // https://www.delfi.ee/latest can be active only if delfi channel is active
      return matchedRoute?.name === this.$route.name && uri.origin === this.$channelConfig('meta').base.baseUrl;
    },
    trackNavigationClicks(itemName) {
      this.$store.commit('analytics/setClickEvent', {
        googleAnalytics: { eventName: 'MOBILE_HEADER_LINK_CLICK', eventData: { type: 'open', data: { name: itemName } } },
      });
    },
  },
});
