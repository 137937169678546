
import Vue from 'vue';
import BlockType13Grid from './BlockType13Grid.vue';
import BlockType13List from './BlockType13List.vue';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType13';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface Computed {
  type: string;
}

interface Props {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: BlockTypeSettings;
  domain: string | undefined;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    BlockType13Grid,
    BlockType13List,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: false,
    },
  },
  computed: {
    type() {
      let type = this.settings.type;
      type = type && (type === 'grid' || type === 'list') ? type : 'list';

      return type;
    },
  },
});
