
import Vue from 'vue';
import { truncate } from '@core/filters/htmlFormater';
import { Headline } from '@headlines/types/headline';

interface Props {
  lead: Headline['content']['lead'];
  maxChars: number;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  name: 'HeadlineLead',
  filters: {
    truncate,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    maxChars: {
      type: Number,
      required: false,
      default: 300,
    },
  },
});
