/**
 * @namespace {Object} config - Configuration for routes
 * @property {Object} headers - Includes possible res.headers that could be provided in response
 * @property {Object.<string, number>} headers.cacheControl  - object of route names that defines cache-control header max-age, route names could be taken from router.base.ts file
 */
const config = {
  headers: {
    cacheControl: {
      default: 60,
      article: 60,
      frontpage: 60,
    },
    vary: {
      default: '',
      article: 'x-signature',
    },
  },
};

export default config;
