import Gemius from '@analytics/trackers/Gemius/Gemius';

export default async function trackGemius(uid: string) {
  if (!uid) {
    return;
  }

  const gemiusTracker = new Gemius();
  await gemiusTracker.init({ uid });
  gemiusTracker.sendPageEvent();
}
