
import Vue from 'vue';
import HeaderLogo from './HeaderLogo.vue';
import HeaderLogoAdditional from './HeaderLogoAdditional.vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderActions from './HeaderActions.vue';
import HeaderSearch from '@root/common/components/layout/header/HeaderSearch.vue';
import HeaderCustomerMenu from '@root/common/components/layout/header/HeaderCustomerMenu.vue';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';
import { gridConfig } from '@core/config/grid.config';
import AlertHandler from '@core/components/UI/alert/AlertHandler.vue';
import { Header, HeaderActions as IHeaderActions } from '@root/modules/navigation/types/navigation';
import { Data as CategoriesData } from '@root/modules/category/types/categories';
import MenuLightbox from '@root/common/components/base/MenuLightbox.vue';

const HeaderSitemap = () => import(/* webpackChunkName: "header/HeaderSitemap" */ './HeaderSitemap.vue');

interface Props {
  navigation: Header;
  hideHeaderMenu: boolean;
  alerts: { type: string; title: string; text: string }[];
}

interface Data {
  isSticky: boolean;
}

interface Methods {
  animateHeader: () => void;
  updateActionEvent: (type: IHeaderActions['type'], value: IHeaderActions['value']) => void;
  controlBodyOverflow: (isSitemapOpen: boolean) => void;
  handleOverflow: () => void;
  refetchFrontpage: () => void;
}

interface Computed {
  siteLogo: {
    src: {
      desktop: string | null;
      mobile: string | null;
    };
    url: string | null;
    alt: string;
    logoIsAnimated: boolean;
    logoWidth: number | null;
    logoWidthMobile: number | null;
    animatedLogoWidth: number | null;
  };
  isSitemapOpen: boolean;
  isSearchOpen: boolean;
  isProfileInfoOpen: boolean;
  isFrontpage: boolean;
  categoryData: CategoriesData['parentCategory'] | null;
  activeCategoryName: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Header',
  components: {
    HeaderLogo,
    HeaderLogoAdditional,
    HeaderMenu,
    HeaderActions,
    HeaderSitemap,
    HeaderSearch,
    HeaderCustomerMenu,
    PianoCustomerStateHandler,
    AlertHandler,
    MenuLightbox,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    hideHeaderMenu: {
      type: Boolean,
      required: true,
    },
    alerts: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      isSticky: false,
    };
  },
  computed: {
    siteLogo() {
      const header = this.$channelConfig('header');
      const name = this.$channelConfig('settings').name;
      let customCategoryHeaderLogo = null;

      if (this.$route && this.$route.params.category) {
        const categorySlug = this.$route.params.subcategory || this.$route.params.category;
        const customCategorySettings = this.$channelConfig('page').category?.component?.header;
        if (customCategorySettings) {
          if (Array.isArray(customCategorySettings)) {
            const categoryAdditionalData =
              customCategorySettings.find((item: { categories: string | string[] }) => item.categories.includes(categorySlug)) || null;
            customCategoryHeaderLogo = categoryAdditionalData?.logo || null;
          }
        }
      }

      return {
        src: {
          desktop: customCategoryHeaderLogo || header?.logo || null,
          mobile: customCategoryHeaderLogo || header?.logoMobile || null,
        },
        url: header.logoUrl,
        alt: name,
        logoIsAnimated: !!header.logoIsAnimated,
        logoWidth: header?.logoWidth || null,
        logoWidthMobile: header?.logoWidthMobile || null,
        animatedLogoWidth: header?.animatedLogoWidth || null,
      };
    },
    isSitemapOpen() {
      return this.$store.getters['navigation/getHeaderAction']('sitemap');
    },
    isSearchOpen() {
      return this.$store.getters['navigation/getHeaderAction']('search');
    },
    isProfileInfoOpen() {
      return this.$store.getters['navigation/getHeaderAction']('profileInfo');
    },
    isFrontpage() {
      return this.$route.name === 'frontpage';
    },
    categoryData() {
      const category = this.$store.getters['category/getCategoryData'];
      return category?.parentCategory?.id ? category?.parentCategory : category;
    },
    activeCategoryName() {
      const id = this.$channelConfig('settings').id;
      return this.navigation.bottom?.parentSlug?.toLowerCase() || this.categoryData?.name?.toLowerCase() || id || null;
    },
  },
  watch: {
    isSitemapOpen(value: boolean) {
      this.controlBodyOverflow(value);
    },
    $route() {
      this.updateActionEvent('sitemap', false);
      this.updateActionEvent('profileInfo', false);
    },
  },
  mounted() {
    // Disable header sticky in Internet Explorer
    if (this.$ua.browser() !== 'Internet Explorer') {
      this.animateHeader();
      window.addEventListener('scroll', this.animateHeader);
      window.addEventListener('resize', this.animateHeader);
    }
  },
  destroyed() {
    if (this.$ua.browser() !== 'Internet Explorer') {
      window.removeEventListener('scroll', this.animateHeader);
      window.removeEventListener('resize', this.animateHeader);
    }
  },
  methods: {
    animateHeader() {
      const $menuTop: Element | null = document.querySelector('.header-menu--top');
      const stickyThreshold = this.$channelConfig('settings').name === 'multimedija' ? 0 : 30;
      const menuTopHeight: number = $menuTop?.clientHeight || stickyThreshold;
      const header = this.$refs.header as Element;

      if (window.innerWidth > gridConfig.breakpoints.lg) {
        const scroll: number = window.pageYOffset;
        // Make header sticky after scrolling down $menuTop height
        this.isSticky = scroll >= menuTopHeight;
      } else {
        this.isSticky = true;
        if (this.isSearchOpen) {
          this.$store.commit('navigation/setHeaderAction', { type: 'search', value: false });
          this.updateActionEvent('search', false);
        }
      }

      // Set delay of header height definition caused by css transition
      setTimeout(() => {
        document.documentElement.style.setProperty('--delfi-header-height', `${header.clientHeight}px`);
      }, 305);
    },
    updateActionEvent(type, value) {
      if (type !== 'all') {
        this.$store.commit('navigation/setHeaderAction', { type, value });
        return;
      }

      this.$store.commit('navigation/setHeaderAction', { type: 'sitemap', value });
      this.$store.commit('navigation/setHeaderAction', { type: 'search', value });
    },
    handleOverflow() {
      // Hide body overflow if sitemap is open on smaller devices
      const changeOverflow = !(window.innerWidth > gridConfig.breakpoints.lg) && this.isSitemapOpen ? 'add' : 'remove';
      document.body.classList[changeOverflow]('overflow-hidden');
    },
    controlBodyOverflow(isSitemapOpen) {
      const changeEventListener = isSitemapOpen ? 'addEventListener' : 'removeEventListener';

      this.handleOverflow();
      window[changeEventListener]('resize', this.handleOverflow);
    },
    refetchFrontpage() {
      if (this.$route.name === 'frontpage') {
        this.$store.commit('frontpage/setRefetchState', true);
      } else {
        this.$router.push('/');
      }
    },
  },
});
