
import Vue from 'vue';

interface Props {
  content: Record<string, any>;
}

interface Computed {
  parts: string[];
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parts() {
      const parts = this.$props.content.attrs.code.split('\n');
      return parts.length ? parts : [];
    },
  },
});
