
import BaseBlock from '@headlines/utils/BaseBlock';
import ContentFragment from '@core/components/ContentFragment.vue';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType9';

const defaultSettings = {};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    ContentFragment,
  },
});
