import { MutationTree, GetterTree, ActionTree } from 'vuex';

import { PianoTp, PianoWindow, ShowOfferOptions, StartCheckoutOptions } from '@piano/types/pianoTp';
import { PianoState } from '@piano/types/pianoState';

import { loadScripts } from '@piano/utils/scriptLoader';
import { defaultLocale, pianoLocales, defaultAccess, defaultProfile, defaultExtendedProfile, defaultActiveCheckoutInfo } from '@piano/config/defaults';

import { checkUserAccess } from '@piano/utils/checkUserAccess';
import { collectExtendedProfile } from '@piano/utils/collectExtendedProfile';
import { collectSubscriptionInfo } from '@piano/utils/collectSubscriptionInfo';

import { setAdBlockCookie } from '@piano/utils/setAdBlockCookie';

// import CustomerConsentService from '@customer/services/customerConsent';
// import { CreateConsent, RevokeConsent } from '@customer/types/customerConsent';
// import signupConsentConfig from '@customer/config/signupConsent.config';
import { getDefaultPromotion } from '@piano/utils/getDefaultPromotion';
import { collectEndedSubscriptions } from '@piano/utils/collectEndedSubscriptions';
// import { isTrialPeriodForNewCustomersOnly } from '@piano/utils/isTrialPeriodForNewCustomersOnly';

interface AppPianoWindow extends PianoWindow {
  AndroidWebAppMobileAPI?: {
    openPianoSignIn?(): void;
  };
  iOSWebAppMobileAPI?: {
    openPianoSignIn?(): void;
  };
}

declare const window: AppPianoWindow;

const afterInit = async (callback: () => void, state: PianoState): Promise<unknown> => {
  const call = new Promise((resolve) => {
    const tp = window.tp;

    if (state.config.maintenanceMode) {
      resolve(undefined);
      return;
    }

    tp.push([
      'init',
      () => {
        const callbackResponse = callback();
        resolve(callbackResponse);
      },
    ]);
  });

  return await Promise.any([call]);
};

export const state: () => PianoState = () => ({
  config: {
    scriptSrc: null,
    aid: null,
    locale: defaultLocale,
    accessResources: {},
    offersConfig: {},
    defaultPromotions: {},
    maintenanceMode: false,
    isApplication: false,
    useAndroidAppApi: false,
    useIosAppApi: false,
  },
  scriptLoaded: false,
  token: null,
  isLoggedIn: false,
  isScriptInited: false,
  isNewCustomer: false,
  profile: Object.assign({}, defaultProfile),
  access: Object.assign({}, defaultAccess),
  extendedProfile: Object.assign({}, defaultExtendedProfile),
  subscriptionInfo: null,
  endedSubscriptions: [],
  maintenanceAlertVisible: false,
  activeCheckoutInfo: Object.assign({}, defaultActiveCheckoutInfo),
});

export const getters: GetterTree<PianoState, Record<string, unknown>> = {
  getTP: (state): PianoTp => {
    if (!state.scriptLoaded && typeof window.tp !== 'object') {
      console.warn('Piano is not initialized');
    }

    return window.tp;
  },
  getConfig: (state) => {
    return state.config;
  },
  getExtendedConfig: (state) => {
    return { config: state.config, defaultLocale, pianoLocales, defaultAccess, defaultProfile, defaultExtendedProfile };
  },
};

export const mutations: MutationTree<PianoState> = {
  setConfig(state, config: PianoState['config']) {
    state.config = config;
  },
  setProfile(state, profile: PianoState['profile']) {
    state.profile = profile;
  },
  setAccess(state, access: PianoState['access']) {
    state.access = access;
  },
  setExtendedProfile(state, extendedProfile: PianoState['extendedProfile']) {
    state.extendedProfile = extendedProfile;
  },
  setSubscriptionInfo(state, subscriptionInfo: PianoState['subscriptionInfo']) {
    state.subscriptionInfo = subscriptionInfo;
  },
  setEndedSubscriptions(state, endedSubscriptions: string[]) {
    state.endedSubscriptions = endedSubscriptions;
  },
};

export const actions: ActionTree<PianoState, Record<string, any>> = {
  async init({ state, dispatch, commit }, config: PianoState['config']) {
    commit('setConfig', config);

    if (state.config.maintenanceMode) {
      state.isLoggedIn = false;
      state.isScriptInited = true; // some logic like analytics rely on this event being true
      // set channel access?
      console.log('Maintenance mode is on');
      return;
    }
    if (state.scriptLoaded) {
      console.warn('Piano script is already initialized');
      return;
    }

    const tp = window.tp || [];

    tp.push(['setLocale', pianoLocales[state.config.locale] || defaultLocale]);

    tp.push([
      'init',
      () => {
        state.scriptLoaded = true;
        dispatch('initPianoId');
      },
    ]);

    tp.push([
      'addHandler',
      'loginSuccess',
      () => {
        dispatch('initUser', 'loginSuccess');
      },
    ]);

    // tp.push([
    //   'addHandler',
    //   'checkoutClose',
    //   (event: { state: string }) => {
    //     dispatch('onCheckoutClose', event.state);
    //   },
    // ]);

    // tp.push([
    //   'addHandler',
    //   'customEvent',
    //   (event: { eventName: string; params: { params?: string } }) => {
    //     dispatch('dispatchCustomEvent', { eventName: event.eventName, params: event.params.params });
    //   },
    // ]);

    // tp.push([
    //   'addHandler',
    //   'checkoutStateChange',
    //   function (event: { offerId: string; stateName: string; term: { termId: string; name: string; description: string } }) {
    //     state.activeCheckoutInfo.offerId = event.offerId;
    //     state.activeCheckoutInfo.termId = event.term.termId;
    //     state.activeCheckoutInfo.termName = event.term.name;
    //     state.activeCheckoutInfo.trialPeriodForNewCustomersOnly = isTrialPeriodForNewCustomersOnly(event.term.name);

    //     dispatch('updatePreviousSubscriptionNotice');
    //   },
    // ]);

    window.tp = tp;

    if (config.ESPid) {
      window.PianoESPConfig = {
        id: config.ESPid,
      };
    }

    if (!state.config.scriptSrc || !state.config.aid) {
      console.warn('Piano settings are not provided');
      return;
    }

    try {
      document.cookie = '__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      await loadScripts('//www.npttech.com/advertising.js', { async: true, onerror: () => setAdBlockCookie(true) });
    } catch (e) {
      console.error(e);
    }
    try {
      await loadScripts(`${state.config.scriptSrc}`);
    } catch (e) {
      console.log('Failed to load Piano', e);
    }
  },
  async updatePreviousSubscriptionNotice({ state }) {
    if (state.activeCheckoutInfo.termId && state.activeCheckoutInfo.trialPeriodForNewCustomersOnly) {
      try {
        const pianoIframe = document.querySelector('.tp-modal[style*="display: block;"]')?.querySelector('iframe');

        const payload = JSON.stringify({
          type: 'updatePreviousSubscriptionNotice',
          display: state.endedSubscriptions.includes(state.activeCheckoutInfo.termId) ? 'block' : 'none',
        });

        if (pianoIframe && pianoIframe.contentWindow) {
          pianoIframe.contentWindow.postMessage(payload, '*');
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  async setTags({ getters, state }, tags: string[]) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      /*
       * Usually, clients use Section for the site section (e.g., Sports, Fashion, etc.),
       * and Tags for everything else — including page type.
       * https://docs.piano.io/content-tracking/#custags
       * */
      tp.push(['setTags', tags]);

      /* Zones are specified on a page-by-page basis according to the JavaScript on a given page */
      // tp.push(['setZone', 'WEB']);
    }, state);
  },
  showMaintenanceAlert({ state }, show: boolean) {
    state.maintenanceAlertVisible = show;
  },
  async executeExperience({ getters, state }) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      tp.experience.execute();
    }, state);
  },
  async showOfferByType({ getters, state }, options: { type: string; containerSelector: string; showPromotionComponent?: boolean }) {
    const keyByLocale = `${options.type}_${state.config.locale}`;

    const offer = state.config.offersConfig[keyByLocale] || state.config.offersConfig[options.type];
    if (offer) {
      await afterInit(() => {
        const tp: PianoTp = getters['getTP'];
        const containerSelector = options.containerSelector;

        const promoCode = offer.promoCode || getDefaultPromotion(offer.offerId, state.config.defaultPromotions);
        tp.push(['setCustomVariable', 'promoComponentVisibility', options.showPromotionComponent ? 'block' : 'none']);
        tp.offer.show({ ...offer, containerSelector, promoCode });
      }, state);
    }
  },
  async startCheckout({ getters, state, dispatch }, options: StartCheckoutOptions) {
    if (state.config.maintenanceMode) {
      dispatch('showMaintenanceAlert', true);
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const promoCode = options.promoCode || getDefaultPromotion(options.offerId, state.config.defaultPromotions);
      const { showPromotionComponent = false, ...pianoOptions } = options;

      tp.push(['setCustomVariable', 'promoComponentVisibility', showPromotionComponent ? 'block' : 'none']);
      tp.offer.startCheckout({ ...pianoOptions, promoCode });
    }, state);
  },
  async showOffer({ getters, state }, options: ShowOfferOptions) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const promoCode = options.promoCode || getDefaultPromotion(options.offerId, state.config.defaultPromotions);
      const { showPromotionComponent = false, ...pianoOptions } = options;

      tp.push(['setCustomVariable', 'promoComponentVisibility', showPromotionComponent ? 'block' : 'none']);
      tp.offer.show({ ...pianoOptions, promoCode });
    }, state);
  },
  async showAccount({ getters, state, dispatch }, options) {
    if (state.config.maintenanceMode) {
      dispatch('showMaintenanceAlert', true);
      return;
    }

    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      if (tp.pianoId.isUserValid()) {
        tp.myaccount.show(options);
        return true;
      } else {
        console.warn('user is not valid');
        return false;
      }
    }, state);
  },
  async showResetPassword({ getters, state, dispatch }, containerSelector) {
    if (state.config.maintenanceMode) {
      dispatch('showMaintenanceAlert', true);
      return;
    }

    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const tokenMatch = location.search.match(/reset_token=([A-Za-z0-9]+)/);

      if (!tp.pianoId.isUserValid() && tokenMatch) {
        const token = tokenMatch[1];

        tp.pianoId.show({
          resetPasswordToken: token,
          displayMode: 'inline',
          width: 724,
          containerSelector,
          loggedIn: function () {
            location.reload();
          },
        });
        return true;
      } else {
        console.warn('user is not valid or no token');
        return false;
      }
    }, state);
  },

  async showLoginModal({ getters, state, dispatch }, screen) {
    if (state.config.maintenanceMode) {
      dispatch('showMaintenanceAlert', true);
      return;
    }

    const isApplication = state.config.isApplication;

    if (isApplication && state.config.useAndroidAppApi && window.AndroidWebAppMobileAPI?.openPianoSignIn) {
      window.AndroidWebAppMobileAPI.openPianoSignIn();
      return;
    } else if (isApplication && state.config.useIosAppApi && window.iOSWebAppMobileAPI?.openPianoSignIn) {
      window.iOSWebAppMobileAPI.openPianoSignIn();
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      // https://docs.piano.io/track/id-implementation/#idparameters
      tp.pianoId.show({
        displayMode: 'modal',
        width: 520,
        screen, // login, register, restore, new_password
        ...(state.config.isApplication && { stage: 'application' }),
        disableSignUp: false,
        loginFailed: function (response: unknown) {
          console.warn(response);
        },
      });
    }, state);
  },
  async showLoginForm({ getters, state, dispatch }, containerSelector) {
    if (state.config.maintenanceMode) {
      dispatch('showMaintenanceAlert', true);
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      // https://docs.piano.io/track/id-implementation/#idparameters
      tp.pianoId.show({
        containerSelector,
        displayMode: 'inline',
        width: 724,
        screen: 'login',
      });
    }, state);
  },
  async getRawUser({ getters, state }) {
    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      return tp.pianoId.getUser();
    }, state);
  },
  async logout({ getters, state }) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      return tp.pianoId.logout();
    }, state);
  },

  async onCheckoutClose({ dispatch, getters, state }, eventName) {
    dispatch('dispatchEvent', eventName);

    state.activeCheckoutInfo = Object.assign({}, defaultActiveCheckoutInfo);

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      tp.push(['setCustomVariable', 'promoComponentVisibility', 'none']);
    }, state);

    switch (eventName) {
      case 'checkoutCompleted':
        break;
      case 'alreadyHasAccess':
        break;
      case 'voucherRedemptionCompleted':
        break;
      case 'close':
        break;
    }
  },
  async initPianoId({ getters, dispatch }, payload: { clean: boolean }) {
    const tp: PianoTp = getters['getTP'];
    if (payload?.clean) {
      await tp.pianoId.init();
    }

    await tp.pianoId.init({
      profileUpdate: async () => {
        dispatch('initUser', 'updateProfile');
      },
      registrationSuccess: () => {
        dispatch('initUser', 'registrationSuccess');
      },
      loggedOut: () => {
        dispatch('initUser', 'logout');
      },
    });

    dispatch('initUser', 'init');
  },
  async initUser({ getters, state, commit, dispatch }, eventName) {
    const tp: PianoTp = getters['getTP'];

    state.token = tp.pianoId.getToken();
    state.isNewCustomer = state.isNewCustomer || eventName === 'registrationSuccess';

    // if (eventName === 'registrationSuccess') {
    //   return; // loginSuccess will be fired also on registration
    // }

    let isLoggedIn = false;
    let profileData = Object.assign({}, defaultProfile);
    let accessData = Object.assign({}, defaultAccess);
    let extendedProfileData = Object.assign({}, defaultExtendedProfile);
    let subscriptionInfoData = null;
    let endedSubscriptions: string[] = [];

    if (state.token && tp.pianoId.isUserValid()) {
      isLoggedIn = true;

      const pianoUserData = tp.pianoId.getUser();

      profileData = {
        lastName: pianoUserData?.lastName || '',
        firstName: pianoUserData?.firstName || '',
        email: pianoUserData?.email || '',
        uid: pianoUserData?.uid || '',
        sub: pianoUserData?.sub || '',
        emailConfirmationRequired: pianoUserData?.email_confirmation_required || false,
        confirmed: pianoUserData?.confirmed || false,
        valid: pianoUserData?.valid || false,
      };

      const accessListPromise = new Promise((resolve) => {
        tp.api.callApi('/access/list', { expand_bundled: true }, async (response) => {
          if (response.data) {
            accessData = {
              channelAccess: checkUserAccess(state.config.accessResources.channelAccess, response.data),
              adFree: checkUserAccess(state.config.accessResources.adFree, response.data),
            };

            subscriptionInfoData = await collectSubscriptionInfo(state.config.accessResources.channelAccess, response.data);
            resolve(true);
          }
          resolve(true);
        });
      });

      const extendedUserPromise = new Promise((resolve) => {
        tp.pianoId.loadExtendedUser({
          extendedUserLoaded: (data) => {
            extendedProfileData = collectExtendedProfile(data);
            resolve(true);
          },
          formName: 'MyAccountFields',
        });
      });

      const conversionPromise = new Promise((resolve) => {
        tp.api.callApi('/conversion/list', {}, async (response) => {
          if (response.conversions) {
            endedSubscriptions = collectEndedSubscriptions(response.conversions);
          }
          resolve(true);
        });
      });

      try {
        await Promise.all([accessListPromise, extendedUserPromise, conversionPromise]);
      } catch (e) {
        console.log(e);
      }
    }

    // if (extendedProfileData.agreedToMarketingConsent && (state.isNewCustomer || eventName === 'updateProfile')) {
    //   const consents: CreateConsent['variables'] = {
    //     entityType: 'EMAIL',
    //     entity: profileData.email,
    //     description: signupConsentConfig.description,
    //     tags: signupConsentConfig.tags,
    //     codes: signupConsentConfig.codes,
    //   };

    //   const customerConsentService = new CustomerConsentService();
    //   await customerConsentService.create(consents);
    // }

    // if (!extendedProfileData.agreedToMarketingConsent && eventName === 'updateProfile') {
    //   const consents: RevokeConsent['variables'] = {
    //     entityType: 'EMAIL',
    //     entity: profileData.email,
    //     codes: signupConsentConfig.codes,
    //   };

    //   const customerConsentService = new CustomerConsentService();
    //   await customerConsentService.revokeBatch(consents);
    // }

    // Redirect to homepage @ logout
    if (eventName === 'logout' && /\/(client|klient|klientas)\//gi.test(window.location.pathname)) {
      window.location.href = '/';
    }

    commit('setProfile', profileData);
    commit('setAccess', accessData);
    commit('setExtendedProfile', extendedProfileData);
    commit('setSubscriptionInfo', subscriptionInfoData);
    commit('setEndedSubscriptions', endedSubscriptions);

    state.isLoggedIn = isLoggedIn;
    state.isScriptInited = true;

    dispatch('dispatchEvent', eventName);
    dispatch('updatePreviousSubscriptionNotice');
  },
  dispatchEvent() {
    // this store action could be listened
  },
  dispatchCustomEvent() {
    // this store action could be listened
  },
};

export const pianoStoreModule = {
  piano: {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
  },
};
