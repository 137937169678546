
import BlockType2List from '../1-10/block-type-2/BlockType2List.vue';
import BlockType3List from '../1-10/block-type-3/BlockType3List.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType5';

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: false,
  category: false,
  lead: false,
  publishDate: false,
  adsInject: null,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    BlockType2List,
    BlockType3List,
  },
  computed: {
    blockType2data() {
      return { ...this.data, items: this.data.items.slice(0, 2) };
    },
    blockType3data() {
      return { ...this.data, items: this.data.items.slice(2, 5) };
    },
  },
});
