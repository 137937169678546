import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';
import { loadScript } from '../loadScript';

import PlayerAnalytics from './BaseAnalytics';
import config from '../../config/media.config';

interface AnalyticsWindow extends Window {
  [index: string]: any;
}

declare let window: AnalyticsWindow;

class GemiusTracker extends PlayerAnalytics {
  private _gemiusPlayer!: any;
  private _gemiusPlayerParams!: any;

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem, lsa: boolean) {
    super(player, mediaData, lsa);
  }

  async initAnalytics() {
    if (!window['gemius_player_data']) {
      await loadScript({ script: config.analytics.gemius.trackerUrl, attr: { async: 'async' } }).then(async () => {
        this.initTracker();
      });
    } else {
      this.initTracker();
    }
    this.setEventsListeners();
  }

  private initTracker() {
    const params = {
      currentDomain: window.location.host,
      volume: this._player.getVolume() || -1,
    };
    const trackerId = navigator.userAgent.match(config.appUa) ? config.analytics.gemius.appUid : config.analytics.gemius.uid;
    this._gemiusPlayer = new window.GemiusPlayer(config.analytics.gemius.playerId, trackerId, params);
    this.setGemiusPlayerParams();
    this._gemiusPlayer.newProgram(this._mediaData.id, this._gemiusPlayerParams);
  }

  private setGemiusPlayerParams() {
    this._gemiusPlayerParams = {
      programName: this._mediaData.type === 'AUDIO' ? this._mediaData.metadata.credit : this._mediaData.metadata.title,
      programDuration: Math.floor(this._player.getDuration()) || -1,
      programType: this._mediaData.type === 'AUDIO' ? 'Audio' : 'Video',
      transmissionType: this._mediaData.type === 'STREAM' ? 2 : 1,
      programGenre: this._mediaData.type === 'STREAM' ? 1 : 3,
      typology: this._mediaData.metadata.tags?.join(),
      volume: Math.floor(this._player.getVolume()),
      intType: 'vod',
    };
  }

  public sendEvent(eventName: string) {
    this.setGemiusPlayerParams();
    const gemiusEvents = {
      media_start: 'play',
      media_play: 'play',
      media_pause: 'pause',
      media_stop: 'stop',
      media_end: 'complete',
      media_seek: 'seek',
    };
    const event = gemiusEvents[eventName as keyof typeof gemiusEvents];
    if (event) {
      this._gemiusPlayer.programEvent(this._mediaData.id, Math.floor(this._player.getPosition()), event, {
        programDuration: this._gemiusPlayerParams.programDuration,
      });
    }
  }
}

export { GemiusTracker };
