
import BaseBlock from '@headlines/utils/BaseBlock';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType24';

const defaultSettings = {
  titleComment: true,
};

interface Data {
  contentTypeFilter: string[];
}

interface Computed {
  getSideBlockContent: HeadlineWithRelatedArticles[];
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, unknown, Computed, unknown>({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineIcons,
    BaseIcon,
  },
  data() {
    return {
      contentTypeFilter: ['contentMarketing', 'hasStream'],
    };
  },
  computed: {
    getSideBlockContent() {
      return this.data.items.slice(1, 6);
    },
  },
});
