
import Vue from 'vue';
import GalleryWrapper from '@gallery/components/GalleryWrapper.vue';
import getGalleries from '@gallery/services/getGalleries';
import { ImageItem } from '@gallery/types/Gallery';
import { FragmentContent } from '@core/types/fragment';

interface Data {
  pictures: ImageItem[] | null;
  id: string | null;
}

interface Props {
  content: FragmentContent;
  settings: Record<string, any>;
}

export default Vue.extend<Data, unknown, unknown, Props>({
  components: {
    GalleryWrapper,
  },
  data() {
    return {
      pictures: null,
      id: null,
    };
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    const id = String(this.content?.attrs?.id);

    if (!id) {
      console.log('[Gallery] No gallery ID given');
      return;
    }

    this.id = id;

    const response = await getGalleries({ id, limit: 500, offset: 0 });
    this.pictures = response.galleries.data[0].images.items;
  },
});
