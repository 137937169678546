import { Plugin } from '@nuxt/types';
import { loadScripts } from '@root/common/utils/scriptLoader';
import Service from '@root/common/base/Service';
import { gridConfig } from '@core/config/grid.config';

interface Response {
  data: {
    success: boolean;
    error: string;
  };
}

interface WebPushWindow extends Window {
  firebase: any;
  ga: any;
  gtag: any;
}

declare const window: WebPushWindow;

function subscribe(token: string) {
  // open indexed db
  const request = indexedDB.open('dlfpushsub', 1);

  // db structure init
  request.onupgradeneeded = (event: Event) => {
    const db = (event?.target as IDBOpenDBRequest)?.result;
    db.createObjectStore('subscriptions', { keyPath: 'topic' });
  };

  // db opened
  request.onsuccess = (event: Event) => {
    const db = (event?.target as IDBOpenDBRequest)?.result;

    // fetch the data from db
    db.transaction('subscriptions').objectStore('subscriptions').get('all').onsuccess = async (event: Event) => {
      // stop here if we have the topic subscription logged for token
      const target = event?.target as IDBOpenDBRequest;
      if (target.result && (target.result as any).token === token) {
        return;
      }

      // log subscription in the database
      function dblog(time: number) {
        db.transaction('subscriptions', 'readwrite').objectStore('subscriptions').put({ topic: 'all', token, time });

        // log to Google Analytics
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'webPushSubscribe', { event_category: 'Push', event_label: time > 0 ? 'Success' : 'Fail' });
        } else if (typeof window.ga === 'function') {
          window.ga('send', 'event', 'Push', 'webPushSubscribe', time > 0 ? 'Success' : 'Fail');
        }
      }

      const service = new Service();
      const apiProvider = service.createProvider('HTTP');
      try {
        const response = await apiProvider.request<Response>({
          url: '/api/push-subscribe',
          method: 'post',
          data: { token },
        });

        if (response.data.success) {
          dblog(new Date().getTime());
        }
      } catch (error) {
        dblog(0);
      }
    };
  };
}

const webPush: Plugin = async (ctx) => {
  const { app } = ctx;
  const webPushSettings = app.$channelConfig('features').webPush;
  const isApplication = Boolean(app.$channelConfig('settings').application.type);

  const screenWidth = window.screen.width;
  const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;
  const isMobileView = screenWidth <= gridConfig.breakpoints.lg || isIpad;

  if (!webPushSettings?.enabled || !webPushSettings?.apiKey || isApplication || isMobileView) {
    return;
  }

  if (!('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window && 'fetch' in window && 'indexedDB' in window)) {
    return;
  }

  try {
    // Can't be loaded over HTTP
    await loadScripts('https://www.gstatic.com/firebasejs/9.2.0/firebase-app-compat.js');
    await loadScripts('https://www.gstatic.com/firebasejs/9.2.0/firebase-messaging-compat.js');
  } catch (e) {
    // OneSignal can be handled as a tracker for some content blockers
    // So there's no point to capture these errors to Sentry
    return;
  }

  // Unregister old push-sw.js to avoid 404s
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      if (registration.active && registration.active.scriptURL && registration.active.scriptURL.indexOf('push-sw.js') > 0) {
        registration.unregister();
      }
    }
  });

  window.firebase.initializeApp(webPushSettings);
  window.firebase
    .messaging()
    .getToken({ vapidKey: webPushSettings.vapidKey })
    .then((token: string) => {
      if (token) {
        subscribe(token);
      }
    })
    .catch((e: any) => {
      console.log(e);
    });
};

export default webPush;
