import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const includeChannels: AdminBlockTypeAttribute = {
  label: 'Include channels',
  key: 'includeChannels',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: [],
  typeOptions: [],
};

export default includeChannels;
