import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const channelDomain: AdminBlockTypeAttribute = {
  label: 'Channel',
  key: 'channelDomain',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: 'www.delfi.ee',
  typeOptions: [],
};

export default channelDomain;
