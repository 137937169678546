
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType20';
import chunkArray from '@headlines/utils/ChunkArray';
import { Headline } from '@headlines/types/headline';

interface Methods {
  chunkArray: (items: Headline[], perChunk: number) => Headline[][];
}

const defaultSettings = {
  icons: true,
  category: true,
  showFbShares: false,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, Methods, unknown, unknown>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    LinkHandler,
  },
  methods: {
    chunkArray,
  },
});
