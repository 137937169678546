import { MutationTree, GetterTree } from 'vuex';
import { Weather } from '@root/modules/weather/typings/weather';

export const state: () => Weather.State = () => ({
  tempData: null,
});

export const getters: GetterTree<Weather.State, Record<string, unknown>> = {
  getTempData: (state) => state.tempData,
};

export const mutations: MutationTree<Weather.State> = {
  setTempData(state, value: Weather.Data | null) {
    state.tempData = value;
  },
  resetTempData(state) {
    state.tempData = null;
  },
};
