import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import { BbcTrackerOptions } from './types/BbcTracker';

import logger from '@analytics/util/logger';
import loadBbcTrackerScript from './utils/loadBbcTrackerScript';

declare let window: Awindow;

export default class BbcTracker implements Analytics {
  private options!: BbcTrackerOptions;

  /**
   * Load script and initialize tracker
   */
  public async init(options?: BbcTrackerOptions) {
    if (options) {
      this.options = options;
    }

    if (
      !(
        this.options?.partner &&
        this.options?.producer &&
        this.options?.language
      )
    ) {
      logger.error('BbcTracker options are not provided');
      return;
    }

    await this.loadScript();

    if (!window.s_bbcws) {
      logger.error('BbcTracker script does not exists on page');
      return;
    }

    window.s_bbcws('partner', this.options.partner);
    window.s_bbcws('producer', this.options.producer);
    window.s_bbcws('language', this.options.language);
  }

  public pageView() {
    if (!window.s_bbcws) {
      logger.error('BbcTracker script does not exists on page');
      return;
    }

    window.s_bbcws('track', 'pageView');
  }

  private async loadScript() {
    if (!window.s_bbcws) {
      console.log('window.s_bbcws is missing load script');
      await loadBbcTrackerScript();
    }
  }
}
