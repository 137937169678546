import { MutationTree } from 'vuex';
import { State, ClickEventName, ClickEventData } from '@root/modules/analytics/types/analytics';

type Payload = Record<string, { eventName: ClickEventName; eventData?: ClickEventData }>;

export const state: () => State = () => ({
  tracker: {
    googleAnalytics: {
      clickEventName: null,
      clickEventData: null,
    },
    edTracker: {
      clickEventName: null,
      clickEventData: null,
    },
    cXense: {
      clickEventName: null,
      clickEventData: null,
    },
    facebookPixel: {
      clickEventName: null,
      clickEventData: null,
    },
    getSiteControl: {
      clickEventName: null,
      clickEventData: null,
    },
  },
});

export const mutations: MutationTree<State> = {
  setClickEvent(state, payload: Payload) {
    Object.keys(payload).forEach((trackerType) => {
      state.tracker[trackerType] = {
        clickEventName: payload[trackerType].eventName,
        clickEventData: payload[trackerType].eventData ? payload[trackerType].eventData : null,
      };
    });
  },
};
