import { Plugin } from '@nuxt/types';
import Service from '@root/common/base/Service';

import { InstanceConnector } from '@ekd/lt-portal-connector/dist/InstanceConnector';

import { HeadlinesInstance } from '@headlines/plugins/HeadlinesInstance';
import { RootInstance } from './RootInstance';

const instanceConnector: Plugin = (ctx, inject) => {
  const instanceConnector = new InstanceConnector({ type: 'Nuxt' });
  const instance = instanceConnector
    .addContext({ store: ctx.store, $eventHandler: ctx.app.$eventHandler, service: new Service() })
    .addLibrary([new RootInstance(), new HeadlinesInstance()])
    .connect();

  inject('instanceConnector', instance.install());
};

export default instanceConnector;
