
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/100-110/blockType110';
import GetMostHeadlinesService from '@headlines/services/GetMostHeadlines.service';
import { DataWithHref, GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';

const Swiper = () => import(/* webpackChunkName: "Swiper" */ '@core/components/UI/swiper/Swiper.vue');
const BlockType4 = () => import(/* webpackChunkName: "BlockType4" */ '@headlines/components/block/1-10/block-type-4/BlockType4.vue');
const BlockType8 = () => import(/* webpackChunkName: "BlockType8" */ '@headlines/components/block/1-10/BlockType8.vue');
const BlockType104 = () => import(/* webpackChunkName: "BlockType104" */ '@headlines/components/block/100-110/BlockType104.vue');

interface Group {
  name: string;
  title: string;
  items: DataWithHref[];
}

const defaultSettings = {
  icons: true,
  author: false,
  titleComment: true,
  channel: false,
  category: true,
};

const titles: Record<string, string> = {
  mostRead: 'Skaitomiausios naujienos',
  mostCommented: 'Labiausiai komentuotos naujienos',
  mostShared: 'Daugiausiai pasidalintos naujienos',
  mostViewed: 'Žiūrimiausi video',
  mostPurchased: 'Perkamiausios naujienos',
};

const mobileTitles: Record<string, string> = {
  mostRead: 'Skaitomiausios',
  mostCommented: 'Komentuotos',
  mostShared: 'Pasidalintos',
  mostViewed: 'Video',
  mostPurchased: 'Perkamiausios',
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    Swiper,
    BlockType4,
    BlockType8,
    BlockType104,
  },
  data() {
    return {
      activeTab: 0,
      blockType104Settings: {
        navigationPerBreakpoint: [false, false, false],
        paginationPerBreakpoint: [false, false, false],
        slidesPerBreakpoint: [1.5, 2.5, 3],
      },
      groups: [] as Group[],
    };
  },
  computed: {
    swiperOptions() {
      return {
        spaceBetween: 12,
        initialSlide: 0,
        autoplay: false,
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          enabled: true,
        },
        pagination: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
      };
    },
    swiperOptionsMobile() {
      return {
        spaceBetween: 12,
        initialSlide: 0,
        autoplay: false,
        slidesPerView: 1.5,
        slidesPerGroup: 1.5,
        pagination: {
          enabled: false,
        },
        navigation: {
          enabled: false,
        },
      };
    },
  },
  async created() {
    const variables: GetUniversalHeadlines['variables'] = { limit: 4 };
    const fakeDomainEnabled = typeof this.$config !== 'undefined' && this.$config.FAKE_DOMAIN_ENABLED;

    const getMostHeadlinesService = new GetMostHeadlinesService();
    const groups = await getMostHeadlinesService.fetch(variables, { domain: this.domain, fakeDomainEnabled });

    groups.forEach((group: Group) => {
      const name = group.name;
      const titlesSource = this.isMobileView ? mobileTitles : titles;
      this.groups.push({ ...group, title: titlesSource[name] as string });
    });
  },
});
