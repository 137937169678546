
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons, HeadlineLead } from '../../fragments';
import ContentFragment from '@core/components/ContentFragment.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType28';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  lead: true,
  pictureShadowColor: '',
  backgroundColor: '',
};

interface Computed {
  headline: HeadlineWithRelatedArticles;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, unknown, Computed, unknown>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineLead,
    ContentFragment,
  },
  computed: {
    headline() {
      return this.data?.items?.[0];
    },
  },
});
