import { Plugin } from '@nuxt/types';

import { getChannelId } from '@root/libs/init-channel/utils/getChannelId';
import { channelConfigService } from '@root/modules/channel/services/channelConfig.service';

const saveInitialData: Plugin = async (ctx) => {
  const initialDataExists = Boolean(ctx.store.getters['getStorage']('channelConfig') && ctx.store.getters['getStorage']('channelConfig'));
  if (initialDataExists) {
    return;
  }

  if (process.server) {
    const req: PortalRootIncomingMessage = ctx.req;
    const channelConfig = req.channelConfig;
    ctx.store.commit('setStorage', { channelConfig });

    return;
  }

  if (process.client) {
    const channelId = getChannelId();
    const channelConfig = await channelConfigService.fetch(channelId);
    ctx.store.commit('setStorage', { channelConfig });
  }
};

export default saveInitialData;
