
import Vue from 'vue';
import FormInput from '@core/components/UI/form/FormInput.vue';

interface Data {
  searchValue: string | null;
  checkForm: boolean;
  isValid: boolean;
  formWidth: string;
}

interface Props {
  isMobile: boolean;
}

interface Methods {
  handleValidation: ({ isValid }: { isValid: boolean }) => void;
  submit: () => void;
  close: (event: KeyboardEvent) => void;
  calcFormWidth: () => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    FormInput,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      searchValue: null,
      checkForm: false,
      isValid: false,
      formWidth: '100%',
    };
  },
  mounted() {
    if (!this.isMobile) {
      this.calcFormWidth();
      document.addEventListener('keyup', this.close);
    }
  },
  destroyed() {
    document.removeEventListener('keyup', this.close);
  },
  methods: {
    handleValidation({ isValid }) {
      this.isValid = isValid;
    },
    async submit() {
      const { searchValue } = this;
      const { id } = this.$channelConfig('settings');
      let query;

      if (searchValue) {
        this.checkForm = true;
        await this.$nextTick();

        if (this.isValid) {
          const { $route } = this;
          const searchSettings = this.$channelConfig('page').search.component.searchForm;
          const domain = searchSettings.enableChannelsSelection ? 'all' : this.$channelConfig('settings').domain;

          // Change url only when search query changes
          if ($route.query.search === searchValue) {
            return;
          }

          this.searchValue = '';
          query = { search: searchValue, order: 'PUBLISH_AT', domain };
        }
      }

      // Close search
      this.$store.commit('navigation/setHeaderAction', { type: 'search', value: false });

      // Get destination path
      let path;
      switch (id) {
        case 'ru':
          path = '/ru/poisk';
          break;
        case 'en':
          path = '/en/search';
          break;
        case 'delfi':
        default:
          path = '/paieska';
          break;
      }

      // Navigate to search page and send search input value, current channel and default date
      this.$router.push({ path, query });
    },
    close(event) {
      if (event.keyCode === 27 || event.type === 'click') {
        this.$store.commit('navigation/setHeaderAction', { type: 'search', value: false });
      }
    },
    calcFormWidth() {
      const logo: HTMLElement | null = document.querySelector('.header__logo');
      const logoWidth: number = logo?.offsetWidth || 74;
      const gutterWidth = 24;

      this.formWidth = `calc(100% - ${logoWidth + gutterWidth}px)`;
    },
  },
});
