
import Vue from 'vue';
import { oneOf } from '@core/utils/helpers';
// @ts-ignore
import { directive as clickOutside } from 'v-click-outside-x';

interface Props {
  hover: boolean;
  triggerIconRotate: boolean;
  forceClose: { value: boolean; timestamp: string } | null;
  size: string;
}

interface Data {
  show: boolean;
}

interface Computed {
  classes: (string | { [x: string]: boolean })[];
}

interface Methods {
  handleClickOutside: () => void;
  handleClick: () => void;
  toggleDropdown: (value: boolean) => void;
  handleMouseOver: (value: boolean) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: {
    clickOutside,
  },
  props: {
    /**
     * Open dropdown with hover
     */
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Automatic angle-down icon rotation
     */
    triggerIconRotate: {
      type: Boolean,
      default: true,
      required: false,
    },
    /**
     * Close dropdown from parent component.
     * Timestamp has to be unique. Example: String(new Date().getTime())
     * @values { value: boolean; timestamp: string }
     */
    forceClose: {
      type: Object,
      default: null,
      required: false,
    },
    /**
     * @values 'auto', 'small', 'default'
     */
    size: {
      validator(value: string) {
        return oneOf(value, ['auto', 'small', 'default']);
      },
      type: String,
      default: () => 'default',
      required: false,
    },
  },
  data() {
    return {
      show: false,
      style: null,
    };
  },
  computed: {
    classes() {
      return [
        'dropdown',
        {
          'dropdown--trigger-icon-rotate': this.show && this.triggerIconRotate,
        },
        {
          [`dropdown--size-${this.size}`]: this.size !== 'auto',
        },
      ];
    },
  },
  watch: {
    forceClose(close, closeOld) {
      if (close?.value && close?.timestamp !== closeOld?.timestamp) {
        this.toggleDropdown(false);
      }
    },
  },
  methods: {
    toggleDropdown(value) {
      this.show = value;
    },
    handleClickOutside() {
      if (this.show) {
        this.toggleDropdown(false);
      }
    },
    handleClick() {
      if (this.hover) {
        return false;
      }
      this.toggleDropdown(!this.show);
    },
    handleMouseOver(value) {
      this.hover && this.toggleDropdown(value);
    },
  },
});
