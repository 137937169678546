
import Vue from 'vue';

interface Props {
  active: boolean;
  name: string;
}

interface Data {
  isActive: boolean;
}
export default Vue.extend<Data, unknown, unknown, Props>({
  props: {
    /**
     * Set active tab
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Set name of tab
     */
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.isActive = this.active;
  },
});
