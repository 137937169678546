import { Plugin, Context } from '@nuxt/types';
import { GoogleAnalytics, GoogleAnalyticsLibra } from '@analytics/trackers';
import { Data as ArticleData } from '@root/modules/article/types/article';
import events from './../config/googleAnalytics';
import interpolate from '@root/common/utils/interpolate';
import { GaEventGroup } from '@root/modules/analytics/types/analytics';
import getEventByKey from '../utils/getEventByKey';

interface GaDimensions {
  dimension1?: string;
  dimension2?: string;
  dimension3?: string;
  dimension4?: string;
  dimension5?: string;
  dimension6?: string;
  dimension7?: string;
  dimension8?: string;
  dimension9?: string;
  dimension10?: string;
  dimension11?: string;
  dimension12?: string;
  dimension13?: string;
  dimension14?: string;
  dimension15?: string;
  dimension16?: string;
  dimension17?: string;
  dimension18?: string;
  dimension19?: string;
  dimension20?: string;
}

const initialDimensions: GaDimensions = {
  dimension1: '',
  dimension2: '',
  dimension3: '',
  dimension4: '',
  dimension5: '',
  dimension6: '',
  dimension7: '',
  dimension8: '',
  dimension9: '',
  dimension10: '',
  dimension11: '',
  dimension12: '',
  dimension13: '',
  dimension14: '',
  dimension15: '',
  dimension16: '',
  dimension17: '',
  dimension18: '',
  dimension19: '',
  dimension20: '',
};

interface AnalyticsWindow extends Window {
  dataLayer: any;
}

declare const window: AnalyticsWindow;

function setArticleDimensions(activeArticle: ArticleData) {
  const dimensions: GaDimensions = {};

  dimensions.dimension1 =
    activeArticle.authors && activeArticle.authors.items.length > 0
      ? Object.values(activeArticle.authors.items)
          .map((author) => author.name)
          .join(',')
      : '';

  let userAgent = 'web';
  if (/(DelfiLTrudelfi|DelfiLTwww|Delfi|DELFI|Ba1\/[1-3] CFNetwork)\/[0-9]/.test(navigator.userAgent)) {
    if (/(iPhone|iPad|iPod|Ba1\/)/.test(navigator.userAgent)) {
      userAgent = 'iosapp';
    } else {
      userAgent = 'androidapp';
    }

    if (!/DeviceUID:/.test(navigator.userAgent)) {
      userAgent += '-notrack';
    }
  } else if (/FBAV\//.test(navigator.userAgent)) {
    userAgent = 'fbwv';
  }
  dimensions.dimension2 = userAgent;

  // TODO: dimensions.dimension3 = isAdblock

  dimensions.dimension4 = String(activeArticle.id);
  dimensions.dimension6 = activeArticle.content.paywall.enabled && activeArticle.content.paywall.access ? 'true' : 'false';
  dimensions.dimension7 = activeArticle.content.paywall.enabled ? 'true' : 'false';

  return dimensions;
}

const googleAnalytics: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');
  const { locale } = app.$channelConfig('settings');
  let firstEventSent = false;

  if (!analytics?.google?.uid) {
    return;
  }

  const { google } = analytics;

  const setBaseDimensions = () => {
    const dimensions = { ...initialDimensions };
    const isLoggedIn = store.state.piano.isLoggedIn;
    if (isLoggedIn) {
      dimensions.dimension5 = store.state.piano.profile?.id;
    }

    return dimensions;
  };

  /*
   * setup ga tracker
   */
  const ga = new GoogleAnalytics('Analytics' as GoogleAnalyticsLibra);
  await ga.init();
  ga.create(google.uid);

  let pageDimensions: GaDimensions = {};

  const pageView = (route: Context['route']) => {
    ga.setVariable('path', route.fullPath);
    // Send page view of all pages except article
    const isArticle = route.name === 'article';
    if (!isArticle) {
      // setup ga dimensions
      const dimensions: GaDimensions = {};
      const baseDimensions = setBaseDimensions();
      pageDimensions = { ...baseDimensions, ...dimensions };

      ga.set(pageDimensions);
      ga.pageView(route.fullPath);
      firstEventSent = true;
    }
  };

  const articlePageView = (activeArticle: ArticleData) => {
    const baseDimensions = setBaseDimensions();
    const articleDimensions = setArticleDimensions(activeArticle);
    pageDimensions = { ...baseDimensions, ...articleDimensions };

    ga.set(pageDimensions);
    ga.pageView(document.location.pathname);
  };

  const continuousReadingDepth = (gaArticle: ArticleData) => {
    const id = gaArticle.id;
    const crArticles = store.state.article.crArticles;
    if (crArticles.length > 0 && crArticles.indexOf(id) !== -1) {
      const crDepth = crArticles.indexOf(id) + 1;
      ga.event('ContinuousReadingDepth', crDepth);
      window.dataLayer.push({ event: 'ContinuousReadingDepth', value: crDepth });
    } else {
      ga.event('ContinuousReadingDepth', '0');
    }
  };

  // Initial page view after script loaded
  // If customer not logged in, push pageView event, otherwise listen once paywall state event and send pageView
  if (!route.name?.includes('article')) {
    if (ctx.store.state.piano.isLoggedIn) {
      app.$eventHandler.once('paywall-state', () => pageView(route));
    } else {
      pageView(route);
    }
  }
  app?.router?.afterEach((to) => {
    pageView(to);
  });

  // Watch activeArticle store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        const gaArticle = store.state.article.activeArticle;
        if (ctx.store.state.piano.isLoggedIn && !firstEventSent) {
          app.$eventHandler.once('paywall-state', () => {
            articlePageView(gaArticle);
            continuousReadingDepth(gaArticle);
          });
          firstEventSent = true;
        } else {
          articlePageView(gaArticle);
          continuousReadingDepth(gaArticle);
        }
      }
    },
    {
      immediate: true,
    }
  );
  /*
   * Events tracker
   * Set before separate ga tracker if exists
   */

  let gaEvents = ga;
  if (google.eventsUid) {
    gaEvents = new GoogleAnalytics();
    gaEvents.create(google.eventsUid, 'AnalyticsTests');
  }

  const eventsWatcher = () => {
    store.watch(
      (state) => state.analytics.tracker.googleAnalytics.clickEventName,
      async (clickEventName) => {
        if (!clickEventName) {
          return;
        }

        const event = getEventByKey<GaEventGroup>(events, clickEventName, locale);
        if (event) {
          if (!store.state.analytics.tracker.googleAnalytics.clickEventData) {
            return false;
          }

          const { type, data } = store.state.analytics.tracker.googleAnalytics.clickEventData;
          const eventData = event.types[type];
          const { category, action, label } = eventData;
          const eventLabel = label ? interpolate(label, { data, app }) : '';

          if (event.tracker) {
            const gaEventsTracker = new GoogleAnalytics();
            gaEventsTracker.create(event.tracker.uid, event.tracker.name || '');
            gaEventsTracker.event(category, action, eventLabel, pageDimensions);
          } else {
            gaEvents.event(category, action, eventLabel, pageDimensions);
          }
        }
        store.commit('analytics/setClickEvent', { googleAnalytics: { eventName: null, eventData: null } });
      }
    );
  };

  // Watch analytics store eventName change if user allowed it
  eventsWatcher();
};

export default googleAnalytics;
