import { Plugin } from '@nuxt/types';
import { Gtm } from '@analytics/trackers';

const GtmPlugin: Plugin = async (ctx) => {
  const { app } = ctx;
  const analytics = app.$channelConfig('analytics');

  // Check if tracker can be enabled
  if (!analytics?.gtm?.containerId) {
    return;
  }

  // Setup tracker
  const gtm = new Gtm();
  gtm.init({ containerId: analytics.gtm.containerId });
};

export default GtmPlugin;
