
import Vue from 'vue';
import BaseButton from '@core/components/UI/BaseButton.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import ContentImage from '@core/components/UI/ContentImage.vue';
import { formatCustomDate, isDateBetween } from '@core/filters/dateFormater';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import { Stream, StreamFragment } from './types/blockType23';

interface Data {
  showStream: boolean;
}

interface Props {
  texts: {
    read_more: string;
    today: string;
    view_stream: string;
    watch_stream: string;
    on_air: string;
  };
  mid: number;
  stream: Stream;
  getDate: string;
  getTime: string;
  isLive: boolean;
  activateStream: boolean;
}

interface Methods {
  openStream(): void;
  getStreamFragment(): Pick<StreamFragment, 'attrs'>;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    BaseButton,
    BaseIcon,
    AdFormBanner,
    LinkHandler,
    ContentImage,
  },
  filters: {
    formatCustomDate,
    isDateBetween,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    texts: {
      type: Object,
      required: true,
    },
    getDate: {
      type: String,
      required: true,
    },
    getTime: {
      type: String,
      required: true,
    },
    isLive: {
      type: Boolean,
      required: true,
    },
    activateStream: {
      type: Boolean,
      required: false,
      default: false,
    },
    mid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showStream: false,
    };
  },
  methods: {
    openStream() {
      this.showStream = true;
    },
    getStreamFragment() {
      return {
        attrs: {
          id: this.stream.media_api_stream_id,
          type: 'VIDEO',
          metadata: {
            code: null,
            title: null,
            credit: null,
            authors: null,
            description: null,
            adsDisabled: null,
            playerLogoDisabled: null,
            embeddingDisabled: null,
            videoPreviewImage: {
              id: null,
            },
          },
          autostart: true,
          videoData: {
            videoPlaylistLocation: '',
          },
        },
      };
    },
  },
});
