
interface Data {
  isOpened: boolean;
}

interface Methods {
  closeMenu: () => void;
}

interface Props {
  menuType: string;
}

import Vue from 'vue';
export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    menuType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isOpened: true,
    };
  },
  methods: {
    closeMenu() {
      if (this.menuType) {
        this.$store.commit('navigation/setHeaderAction', { type: this.menuType, value: false });
      } else {
        this.isOpened = false;
      }
    },
  },
});
