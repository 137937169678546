
import Vue from 'vue';
import BaseButton from '@core/components/UI/BaseButton.vue';

interface Data {
  loaded: boolean;
}

interface Props {
  enableCloseButton: boolean;
}

interface Methods {
  close: () => void;
  onEscapeKeyUp: (event: KeyboardEvent) => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    BaseButton,
  },
  props: {
    /**
     * Display close button on the top right corner
     */
    enableCloseButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.loaded = true;
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  destroyed() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
  },
  methods: {
    close() {
      this.$emit('close', true);
    },
    onEscapeKeyUp(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
  },
});
