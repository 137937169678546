export default {
  twitter: 'https://platform.twitter.com/widgets.js',
  instagram: '//www.instagram.com/embed.js',
  imgur: '//s.imgur.com/min/embed.js',
  playbuzz: 'https://embed.ex.co/sdk.js',
  quiz: '//q.delfi.lt/quiz.php',
  liveblog: 'https://lb.delfi.lt/js/liveupdater.js',
  rembi: '//g.delfi.ee/misc/rembi/embed.php',
  ogEmbed: '//g.dcdn.lt/og-embed',
  podcast: 'https://www.delfi.lt/klausyk/embed.php',
} as Record<string, string>;
