import BlockType9Component from '@headlines/components/block/1-10/BlockType9.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType9: AdminBlockType = {
  component: BlockType9Component,
  attributes: AdminBlockTypeAttributes({
    iframe: false,
    className: '',
    embedName: '',
  }),
  articlesCount: 0,
};

export default AdminBlockType9;
