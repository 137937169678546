import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const excludeIssueOnly: AdminBlockTypeAttribute = {
  label: 'Exclude issue only',
  key: 'excludeIssueOnly',
  inputType: AdminBlockAttributeInputType.CHECKBOX,
  defaultValue: false,
};

export default excludeIssueOnly;
