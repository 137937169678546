import apolloClient from '../apollo/apolloClient';
import getGalleriesQuery from '../apollo/graphql/getGalleries.graphql';
import { GetGalleries } from '../types/Gallery';

const getArticles = async (variables: { id: string; limit: number; offset: number }) => {
  const queryOptions = { variables };
  const options = Object.assign({ query: getGalleriesQuery }, queryOptions);

  const { data } = await apolloClient.query<GetGalleries['data']>(options);

  return data;
};

export default getArticles;
